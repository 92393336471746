import { useNavigate, useSearchParams } from 'react-router-dom';
import { Operation } from '../model/Operation';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import { useContext } from 'react';
import { Context } from '../utils/context';
import { getInteger, getDecimal } from '../utils/number-utils';

interface Props {
    operation: Operation
}

export default function OperationItem(props: Props): JSX.Element {
    const operation = props.operation;
    const { financialElements, operationDetails } = useContext(Context);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getSign } = useCurrencyByCode();

    function onClick() {
        if (operation.grouping_id === '') {
            navigate(`/operations/${operation.id}?${searchParams}`);
        } else {
            navigate(`/transactions/${operation.grouping_id}?${searchParams}`);
        }
    }

    return (
        <div className="flex justify-between py-2 cursor-pointer hover:bg-sf-gray-extra-light" onClick={onClick}>
            <div className='max-w-[62%]'>
                <div className='flex space-x-1'>
                    <h3 className='whitespace-nowrap'>{operationDetails.find(operationDetail => operationDetail.id === operation.operation_detail_id)?.title}</h3>
                    {operation.description && <p className="secondary font-bold">({operation.description})</p>}
                </div>
                <small className='truncate'>{financialElements.find(financialElement => financialElement.id === operation.financial_element_id)?.title}</small>
            </div>
            <div className='flex flex-col'>
                <div className={`flex self-end ${operation.amount?.includes('-') ? 'expenditure' : 'income'}`}>
                    <p>{operation.amount?.includes('-') ? '-' : '+'}</p>
                    <p className="mx-1">{getSign(operation.currency_code)}</p>
                    <p>{getInteger(operation.amount ?? '')}</p>
                    <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(operation.amount ?? '')}</p>
                </div>
                <small className="self-end mt-2">{new Date(parseInt(operation.timestamp ?? '')).toLocaleDateString('es', { timeZone: 'UTC' })}</small>
            </div>
        </div>
    )
}