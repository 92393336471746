import { SyntheticEvent, useContext, useEffect, useState } from "react"
import { Budget, Item, defaultBudget, defaultItem } from "../model/Budget";
import { createBudget, deleteBudget, listBudgets, updateBudget } from "../utils/api";
import { Context } from "../utils/context";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import Select from "../components/Select";
import EmptyList from "../components/EmptyList";
import { processDecimalInput } from "../utils/process-decimal-input";
import { OperationTypes } from "../consts/OperationTypes";
import EditBudgetItem from "../components/EditBudgetItem";
import { addDaysToTimestamp, ifDateIsValidThen } from "../utils/date-utils";
import { areItemsEqual } from "../utils/budget-utils";

export default function BudgetFull() {
    const { client, operationDetails, setConfirmDialog } = useContext(Context);
    const { timestamp_to } = useParams();
    const [loading, setLoading] = useState(true);
    const [isFirstBudget, setIsFirstBudget] = useState(false);
    const [isLastBudget, setIsLastBudget] = useState(false);
    const [isDangerousTimestampTo, setIsDangerousTimestampTo] = useState(false);
    const [budget, setBudget] = useState<Budget>({ ...defaultBudget });
    const [newItem, setNewItem] = useState<Item>({ ...defaultItem });
    const [newItemType, setNewItemType] = useState<OperationTypes>(OperationTypes.EXPENDITURE);

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { getSign } = useCurrencyByCode();

    useEffect(() => {
        async function setUp() {
            try {
                setLoading(true);
                const result = await listBudgets(client.id ?? '');
                const currentBudgetIndex = result.findIndex(budget => budget.timestamp_to === timestamp_to);
                setBudget(result[currentBudgetIndex]);
                setIsFirstBudget(currentBudgetIndex === 0);
                setIsLastBudget(currentBudgetIndex === result.length - 1);
                setNewItem({ ...defaultItem });
                setNewItemType(OperationTypes.EXPENDITURE);
            } finally {
                setLoading(false);
            }
        }
        setUp();
    }, [timestamp_to, client.id]);

    useEffect(() => {
        if (!budget.timestamp_to) {
            return;
        }

        const timestampToReal = new Date(parseInt(budget.timestamp_to));
        const day = timestampToReal.toISOString().substring(8, 10);

        setIsDangerousTimestampTo(day > '28');
    }, [budget.timestamp_to]);

    if (loading) return <Loading />

    function handleBudgetChange(event: SyntheticEvent): void {
        const target = event.target as HTMLInputElement;
        setBudget(oldBudget => ({
            ...oldBudget,
            [target.name]: target.value
        }));
    }

    function handleFromDateChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setBudget(oldBudget => ({
            ...oldBudget,
            timestamp_from: new Date(target.value).getTime().toString(),
        }));
    }

    function handleToDateChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setBudget(oldBudget => ({
            ...oldBudget,
            timestamp_to: addDaysToTimestamp(new Date(target.value).getTime().toString(), 1),
        }));
    }

    function handleItemChange(event: SyntheticEvent): void {
        const target = event.target as HTMLInputElement;
        setNewItem(oldItem => ({
            ...oldItem,
            [target.name]: target.value
        }));
    }

    function addItem(): void {
        setBudget(oldBudget => ({
            ...oldBudget,
            items: [...oldBudget.items ?? [], {
                ...newItem,
                amount: newItemType === OperationTypes.EXPENDITURE ? `-${newItem.amount}` : newItem.amount
            }]
        }));
        setNewItem({ ...defaultItem });
    }

    function removeFromBudget(item: Item): void {
        setBudget(oldBudget => ({
            ...oldBudget,
            items: (oldBudget.items ?? []).filter(oldItem => !(oldItem.operation_detail_id === item.operation_detail_id))
        }));
    }

    function saveItem(item: Item): void {
        setBudget(oldBudget => ({
            ...oldBudget,
            items: (oldBudget.items ?? []).map(oldItem => (oldItem.operation_detail_id === item.operation_detail_id) ? item : oldItem)
        }));
    }

    const returnToBudgets = () => navigate(`/budgets?${searchParams}`);

    async function confirmDeleteBudget(): Promise<void> {
        setSearchParams(oldSearchParams => {
            oldSearchParams.set('budget_timestamp', budget.timestamp_from ?? '');
            return oldSearchParams;
        });
        await deleteBudget(client.id ?? '', budget.timestamp_to ?? '');
        returnToBudgets();
    }

    async function confirmCancelBudget(): Promise<void> {
        returnToBudgets();
    }

    async function saveBudget() {
        setLoading(true);
        setSearchParams(oldSearchParams => {
            oldSearchParams.set('budget_timestamp', budget.timestamp_to ?? '');
            return oldSearchParams;
        });
        if (budget.timestamp_to === timestamp_to) {
            await updateBudget(client.id ?? '', budget.timestamp_to ?? '', budget)
        } else {
            await deleteBudget(client.id ?? '', timestamp_to ?? '');
            await createBudget(client.id ?? '', budget);
        }
        returnToBudgets();
    }

    function swap(item1: Item, item2: Item): void {
        setBudget(oldBudget => {
            const index1 = oldBudget.items?.findIndex(oldItem => areItemsEqual(oldItem, item1));
            const index2 = oldBudget.items?.findIndex(oldItem => areItemsEqual(oldItem, item2));

            if (index1 === undefined || index2 === undefined) return oldBudget;

            const rearrangedItems = [...oldBudget.items ?? []];
            rearrangedItems[index1] = (oldBudget.items ?? [])[index2];
            rearrangedItems[index2] = (oldBudget.items ?? [])[index1];

            return { ...oldBudget, items: rearrangedItems };
        });
    }

    const incomeItems = budget?.items?.filter(item =>
        !item.amount?.includes('-') &&
        (item.operation_detail_id ?? '') !== ''
    ) ?? [];
    const expenditureItems = budget?.items?.filter(item =>
        !!item.amount?.includes('-') &&
        (item.operation_detail_id ?? '') !== ''
    ) ?? [];

    const usedExpenditureIds = expenditureItems.map(item => item.operation_detail_id) ?? [];
    const usedIncomeIds = incomeItems.map(item => item.operation_detail_id) ?? [];

    const availableExpenditureOperationDetails = operationDetails?.filter(operationDetail =>
        !usedExpenditureIds.includes(operationDetail.id) &&
        operationDetail.status === 'ENABLED' &&
        operationDetail.type === OperationTypes.EXPENDITURE.key
    );
    const availableIncomeOperationDetails = operationDetails?.filter(operationDetail =>
        !usedIncomeIds.includes(operationDetail.id) &&
        operationDetail.status === 'ENABLED' &&
        operationDetail.type === OperationTypes.INCOME.key
    );

    const availableOperationDetails = newItemType === OperationTypes.EXPENDITURE ? availableExpenditureOperationDetails : availableIncomeOperationDetails;

    const isoTimestampFrom = new Date(parseInt(budget?.timestamp_from ?? '')).toISOString().substring(0, 10);
    const isoTimestampTo = new Date(parseInt(addDaysToTimestamp(budget?.timestamp_to ?? '', -1))).toISOString().substring(0, 10);

    const updateCurrencyCodes = [budget.currency].concat(client.currencies?.map(currency => currency.code).filter(currencyCode => currencyCode !== budget.currency));

    const confirmCancelDialogParams = {
        callback: confirmCancelBudget,
        title: '¿Estás seguro que deseas salir? Se perderán los cambios realizados',
        confirmButton: 'Salir (perder cambios)',
        cancelButton: 'Seguir editando'
    };

    const confirmDeleteDialogParams = {
        callback: confirmDeleteBudget,
        title: '¿Estás seguro que deseas eliminar este presupuesto?',
        confirmButton: 'Eliminar',
        cancelButton: 'Cancelar'
    };

    return (
        <main>
            <article>
                <h2>Editar presupuesto</h2>
                {isDangerousTimestampTo && <p className="w-full flex justify-center bg-sf-red-light text-sf-red-dark p-3 rounded-lg font-bold"> No es recomendable usar esta Fecha de finalización.</p>}
                <div className="flex min-w-full justify-between items-center pb-2 space-x-3">
                    <div className="w-1/2">
                        <p className={`pb-1 ${!isFirstBudget && 'text-sf-gray-dark'}`}>Fecha de inicio:</p>
                        <input name='timestamp_from' type='date' disabled={!isFirstBudget} max={isoTimestampTo} value={isoTimestampFrom} onChange={ifDateIsValidThen(handleFromDateChange)} className={`field ring-1 ${!isFirstBudget ? 'ring-sf-gray-dark text-sf-gray-dark' : 'ring-sf-violet-dark'}`} />
                    </div>
                    <div className="w-1/2">
                        <p className={`pb-1 ${!isLastBudget && 'text-sf-gray-dark'}`}>Fecha de finalización:</p>
                        <input name='timestamp_to' type='date' disabled={!isLastBudget} min={isoTimestampFrom} value={isoTimestampTo} onChange={ifDateIsValidThen(handleToDateChange)} className={`field ring-1 ${!isLastBudget ? 'ring-sf-gray-dark text-sf-gray-dark' : 'ring-sf-violet-dark'}`} />
                    </div>
                </div>
                {isFirstBudget && isLastBudget && <article className="bg-sf-violet-light">
                    <h2>Moneda del tablero</h2>
                    <Select placeholder='Moneda' name='currency' value={budget.currency} onChange={handleBudgetChange} options={updateCurrencyCodes.map(currencyCode => ({ value: currencyCode, alias: getSign(currencyCode) }))} />
                </article>}
                <article className="bg-sf-violet-light">
                    <div className="flex justify-between items-center">
                        <h2>Nuevo concepto</h2>
                        <div className='flex'>
                            {[OperationTypes.EXPENDITURE, OperationTypes.INCOME].map(type => (
                                <div key={type.key} className='flex items-center space-x-1.5 cursor-pointer rounded-lg p-1 px-3' onClick={() => { setNewItem({ ...defaultItem }); setNewItemType(type); }}>
                                    <p className={`rounded-full h-4 w-4 ${type === newItemType ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></p>
                                    <p>{type.label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    {availableOperationDetails?.length === 0 ?
                        <small className="text-sf-violet-dark p-2 rounded-lg border border-sf-violet-dark">No quedan categorías de {newItemType.label} disponibles para presupuestar.</small>
                        :
                        <div className="space-y-3 flex flex-col">
                            <Select placeholder='Selecciona una categoría de movimiento *' name='operation_detail_id' value={newItem.operation_detail_id} onChange={handleItemChange} options={availableOperationDetails.map(availableOperationDetail => ({ value: availableOperationDetail.id, alias: availableOperationDetail.title }))} />
                            <div className='flex'>
                                <div className='field px-6 mr-3 bg-sf-white text-center w-fit'>
                                    {getSign(budget.currency)}
                                </div>
                                <input type='text' inputMode='decimal' name='amount' placeholder='Ingrese el monto *' value={newItem.amount} onChange={e => processDecimalInput(e) && handleItemChange(e)} className='field' />
                            </div>
                            <button disabled={!newItem.operation_detail_id || !budget.currency || !newItem.amount} className="self-center button-primary" onClick={addItem}>
                                Agregar
                            </button>
                        </div>
                    }
                </article>

                {incomeItems.length > 0 && <div>
                    <h2 className='py-2'>Ingresos:</h2>
                    <div className="divide-y divide-sf-black border-y border-sf-black">
                        {incomeItems.map((item, index) =>
                            <EditBudgetItem
                                key={index}
                                item={item}
                                currency={budget.currency ?? ''}
                                removeFromBudget={() => removeFromBudget(item)}
                                saveItem={saveItem}
                                moveUp={() => index > 0 ? swap(item, incomeItems[index - 1]) : null}
                                moveDown={() => index < (incomeItems.length - 1) ? swap(item, incomeItems[index + 1]) : null}
                            />
                        )}
                    </div>
                </div>}
                {expenditureItems.length > 0 && <div>
                    <h2 className='py-2'>Egresos:</h2>
                    <div className="divide-y divide-sf-black border-y border-sf-black">
                        {expenditureItems.map((item, index) =>
                            <EditBudgetItem
                                key={index}
                                item={item}
                                currency={budget.currency ?? ''}
                                removeFromBudget={() => removeFromBudget(item)}
                                saveItem={saveItem}
                                moveUp={() => index > 0 ? swap(item, expenditureItems[index - 1]) : null}
                                moveDown={() => index < (expenditureItems.length - 1) ? swap(item, expenditureItems[index + 1]) : null}
                            />
                        )}
                    </div>
                </div>}
                {budget?.items?.length === 0 && <EmptyList message="Aún no hay conceptos cargados." />}
                <div className='flex w-full justify-end'>
                    <div className="flex w-full">
                        {isLastBudget && <button className="button-secondary-red px-4" onClick={() => setConfirmDialog(confirmDeleteDialogParams)}>Eliminar</button>}
                    </div>
                    <button className="button-secondary px-4 mr-4" onClick={() => setConfirmDialog(confirmCancelDialogParams)}>Volver</button>
                    <button className="button-primary" onClick={saveBudget}>Guardar</button>
                </div>
            </article>
        </main>
    )
}
