import { defaultOperation } from '../model/Operation';
import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../utils/context';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getOperation } from '../utils/api';
import Loading from '../components/Loading';
import Select from '../components/Select';
import { OperationTypes } from '../consts/OperationTypes';
import { processDecimalInput } from '../utils/process-decimal-input';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import { ifDateIsValidThen } from '../utils/date-utils';
import OperationsService from '../utils/OperationsService';

export default function OperationFull(): JSX.Element {
    const { client, addFeedback, setConfirmDialog, financialElements, operationDetails } = useContext(Context);
    const [selectedType, setSelectedType] = useState(OperationTypes.EXPENDITURE);
    const [loading, setLoading] = useState(true);
    const { operation_id } = useParams();
    const [operation, setOperation] = useState({ ...defaultOperation });
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();
    const [searchParams] = useSearchParams();

    const setUp = useCallback(async () => {
        try {
            setLoading(true);
            if (!operation_id) {
                navigate(`/operations?${searchParams}`);
                return;
            }

            const result = await getOperation(client.id ?? '', operation_id);

            setSelectedType(parseFloat(result.amount ?? '') > 0 ? OperationTypes.INCOME : OperationTypes.EXPENDITURE);
            setOperation({
                ...result,
                amount: result.amount?.replace('-', ''),
                timestamp: new Date(parseInt(result.timestamp ?? '')).toISOString().substring(0, 10),
            });
        } finally {
            setLoading(false);
        }
    }, [searchParams, operation_id, navigate, client.id]);

    useEffect(() => { setUp() }, [setUp]);

    function handleInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setOperation(oldOperation => ({
            ...oldOperation,
            [target.name]: target.value
        }));
    }

    async function saveChanges() {
        try {
            setLoading(true);
            const operationToUpdate = {
                ...operation,
                amount: selectedType === OperationTypes.EXPENDITURE ? `-${operation.amount}` : operation.amount,
                timestamp: new Date(operation.timestamp ?? '').getTime().toString()
            };
            await OperationsService.updateOperation(client.id ?? '', operation.id ?? '', operationToUpdate);
            addFeedback({ message: '¡Movimiento actualizado con éxito!', level: 'success' });
            navigate(`/operations?${searchParams}`);
        } finally {
            setLoading(false);
        }
    }

    const expenditureOperationDetails = operationDetails
        .filter(operationDetail => operationDetail.id === operation.operation_detail_id || (operationDetail.status === 'ENABLED' && operationDetail.type === OperationTypes.EXPENDITURE.key))
        .map(operationDetail => ({ value: operationDetail.id, alias: operationDetail.title }));

    const incomeOperationDetails = operationDetails
        .filter(operationDetail => operationDetail.id === operation.operation_detail_id || (operationDetail.status === 'ENABLED' && operationDetail.type === OperationTypes.INCOME.key))
        .map(operationDetail => ({ value: operationDetail.id, alias: operationDetail.title }));

    const financialElementsOptions = financialElements
        .filter(financialElement => financialElement.id === operation.financial_element_id || (financialElement.status === 'ENABLED' && financialElement.transactional))
        .map(financialElement => ({ value: financialElement.id, alias: financialElement.title }));

    async function confirmDeleteOperation() {
        setLoading(true);
        try {
            await OperationsService.deleteOperation(client.id ?? '', operation.id ?? '');
        } finally {
            navigate(`/operations?${searchParams}`);
        }
    }

    const confirmDeleteOperationDialogParams = {
        callback: confirmDeleteOperation,
        title: '¿Estás seguro que deseas eliminar este movimiento?',
        confirmButton: 'Eliminar',
        cancelButton: 'Cancelar'
    };

    if (loading) return <Loading />

    const updateCurrencyCodes = [operation.currency_code].concat(client.currencies?.map(currency => currency.code).filter(currencyCode => currencyCode !== operation.currency_code));

    return (
        <main>
            <button className='button-aux w-fit p-1 col-span-full' onClick={() => navigate(`/operations?${searchParams}`)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 mt-0.5 h-2.5 w-2.5" viewBox="0 0 24 24"><path fill="currentColor" d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875q-.375.375-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12q0-.375.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388q.375.375.375.875t-.375.875L3.55 12Z" /></svg>
                <div>Volver al listado</div>
            </button>
            <article className='bg-sf-violet-light'>
                <h2>Editar movimiento</h2>
                <div className='border-t border-black w-full h-min pb-1'></div>
                <div className='flex space-x-3'>
                    <div className='field p-2 w-min space-x-0.5'>
                        {[OperationTypes.EXPENDITURE, OperationTypes.INCOME].map(type => (
                            <div key={type.key} className='flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1' onClick={() => { setSelectedType(type); setOperation(oldOperation => ({ ...oldOperation, operation_detail_id: undefined })); }}>
                                <div className={`rounded-full h-3.5 w-3.5 ${type.key === selectedType?.key ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></div>
                                <div>{type.label}</div>
                            </div>
                        ))}
                    </div>
                    <input name='timestamp' type='date' value={operation.timestamp} onChange={ifDateIsValidThen(handleInputChange)} className='field' />
                </div>
                <Select placeholder='Selecciona una categoría de movimiento *' name='operation_detail_id' value={operation.operation_detail_id} onChange={handleInputChange} options={selectedType === OperationTypes.EXPENDITURE ? expenditureOperationDetails : incomeOperationDetails} />
                <Select placeholder='Selecciona una cuenta *' name='financial_element_id' value={operation.financial_element_id} onChange={handleInputChange} options={financialElementsOptions} />
                <div className='flex space-x-3'>
                    <div className='w-48'>
                        <Select placeholder='Moneda' name='currency_code' value={operation.currency_code} onChange={handleInputChange} options={updateCurrencyCodes.map(currencyCode => ({ value: currencyCode, alias: getSign(currencyCode) }))} />
                    </div>
                    <input type='text' inputMode='decimal' name='amount' placeholder='Ingrese el monto *' value={operation.amount} onChange={e => processDecimalInput(e) && handleInputChange(e)} className='field' />
                </div>
                <input name='description' type='text' placeholder='Descripción (opcional)' value={operation.description} onChange={handleInputChange} className='field' />
                <div className='flex justify-between w-full'>
                    <button className='button-secondary-red' onClick={() => setConfirmDialog(confirmDeleteOperationDialogParams)}>Eliminar</button>
                    <div className='flex w-fit space-x-2 justify-between'>
                        <button className="button-secondary" onClick={() => { navigate(`/operations?${searchParams}`) }}>
                            Cancelar
                        </button>
                        <button className="button-primary" disabled={!operation.financial_element_id || !operation.operation_detail_id || !operation.currency_code || !operation.amount || !operation.timestamp || parseFloat(operation.amount) === 0} onClick={saveChanges}>
                            Guardar
                        </button>
                    </div>
                </div>
            </article>
        </main>
    )
}