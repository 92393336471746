import XLSX from 'xlsx';

export async function readSpreadsheet(file: File): Promise<any[]> {
    const data = await file?.arrayBuffer();
    const workbook = file.type === 'text/csv' ? XLSX.read((new TextDecoder('utf-8')).decode(data), { type: 'string' }) : XLSX.read(data);
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    return XLSX.utils.sheet_to_json<any>(sheet, { raw: false });
}

export function writeSpreadsheet(tables: { data: any[][], name: string }[], fileName: string): void {
    const workbook = XLSX.utils.book_new();
    tables.forEach(tableAndName => {
        const worksheet = XLSX.utils.aoa_to_sheet(tableAndName.data);
        const range = XLSX.utils.decode_range(worksheet['!ref']!);
        const columnWidths = new Array(range.e.c - range.s.c + 1).fill(0);
        for (let C = range.s.c; C <= range.e.c; ++C) {
            for (let R = range.s.r; R <= range.e.r; ++R) {
                const cellAddress = XLSX.utils.encode_cell({ c: C, r: R });
                const cell = worksheet[cellAddress];
                if (cell && cell.v != null) {
                    const cellValue = String(cell.v);
                    columnWidths[C] = Math.max(columnWidths[C], cellValue.length);
                }
            }
        }
        const formattedColumnWidths = columnWidths.map(width => ({ wch: Math.max(width, 10) }));
        worksheet['!cols'] = formattedColumnWidths;
        XLSX.utils.book_append_sheet(workbook, worksheet, tableAndName.name);
    });
    XLSX.writeFile(workbook, fileName);
}
