import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../utils/context';
import { useNavigate } from 'react-router-dom';
import { createFinancialElement, createValuation } from '../utils/api';
import Loading from '../components/Loading';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import Select from '../components/Select';
import { processDecimalInput } from '../utils/process-decimal-input';
import { defaultFinancialElement } from '../model/FinancialElement';
import { defaultValuation } from '../model/Valuation';
import { getFormattedLocaleDate, getHourInMilis, ifDateIsValidThen } from '../utils/date-utils';

export default function NewDebt(): JSX.Element {
    const { client, financialElements } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [financialElement, setFinancialElement] = useState({ ...defaultFinancialElement });
    const [valuation, setValuation] = useState({ ...defaultValuation });
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();
    const [deferEnable, setDeferEnable] = useState(false);
    const [createDeferAccount, setCreateDeferAccount] = useState<boolean>(true);
    const [date, setDate] = useState<Date>();

    const setUp = useCallback(async () => {
        try {
            setLoading(true);
            const now = new Date();
            setDate(now);
            setFinancialElement({
                ...defaultFinancialElement,
                type: 'DEBT',
                status: 'ENABLED'
            });
            setDeferEnable(false);
            setValuation({
                ...defaultValuation,
                timestamp: getFormattedLocaleDate(now),
                currency_code: client.currencies ? client.currencies[0]?.code ?? '' : ''
            });
        } finally {
            setLoading(false);
        }
    }, [client.currencies]);

    useEffect(() => { setUp() }, [setUp]);

    function handleFinancialElementInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setFinancialElement(oldFinancialElement => ({
            ...oldFinancialElement,
            [target.name]: target.value
        }));
    }

    function handleValuationInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setValuation(oldValuation => ({
            ...oldValuation,
            [target.name]: target.value
        }));
    }

    async function saveChanges() {
        setLoading(true);
        try {
            let result;
            if (deferEnable) {
                if (createDeferAccount) {
                    const deferAccount = await createFinancialElement(client.id ?? '', {
                        ...defaultFinancialElement,
                        type: 'DEBT',
                        status: 'ENABLED',
                        title: `${financialElement.title} (cuotas pendientes)`
                    });
                    result = await createFinancialElement(client.id ?? '', {
                        ...financialElement,
                        deferred: deferAccount.id
                    });
                } else {
                    result = await createFinancialElement(client.id ?? '', financialElement);
                }
            } else {
                result = await createFinancialElement(client.id ?? '', {
                    ...financialElement,
                    deferred: ''
                });
            }
            await createValuation(client.id ?? '', {
                ...valuation,
                financial_element_id: result.id,
                amount: `-${valuation.amount}`,
                timestamp: new Date(valuation.timestamp ?? '').getTime().toString(),
                hour: getHourInMilis(date).toString(),
            });
            navigate('/debts');
        } finally {
            setLoading(false);
        }
    }

    if (loading) return <Loading />

    const financialElementsOptions = financialElements
        .filter(fe => fe.status === 'ENABLED' && !fe.transactional)
        .map(fe => ({ value: fe.id, alias: fe.title }));

    return (
        <main>
            <article className='bg-sf-red-light'>
                <h2>Nueva deuda</h2>
                <input name='title' type='text' placeholder='Título *' value={financialElement.title} onChange={handleFinancialElementInputChange} className='field' />
                <input name='description' type='text' placeholder='Descripción (opcional)' value={financialElement.description} onChange={handleFinancialElementInputChange} className='field' />
                <input name='timestamp' type='date' value={valuation.timestamp} onChange={ifDateIsValidThen(handleValuationInputChange)} className='field' />
                <div className='flex space-x-3'>
                    <div className='w-48'>
                        <Select placeholder='Moneda' name='currency_code' value={valuation.currency_code} onChange={handleValuationInputChange} options={client.currencies?.map(currency => ({ value: currency.code, alias: getSign(currency.code) }))} />
                    </div>
                    <input type='text' inputMode='decimal' name='amount' placeholder='Saldo *' value={valuation.amount} onChange={e => processDecimalInput(e) && handleValuationInputChange(e)} className='field' />
                </div>
                <div className="pr-1 w-fit flex items-center space-x-2 m-2 cursor-pointer" onClick={() => setFinancialElement(oldFinancialElement => ({ ...oldFinancialElement, transactional: !oldFinancialElement.transactional }))}>
                    <p>¿Es de uso cotidiano?</p>
                    <div className="h-4 w-4 rounded-sm bg-sf-white flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className={`w-full text-sf-violet-dark ${financialElement.transactional ? '' : 'hidden'}`} viewBox="0 0 24 24"><path fill="currentColor" d="m10 13.6l5.9-5.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-6.6 6.6q-.3.3-.7.3t-.7-.3l-2.6-2.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l1.9 1.9Z" /></svg>
                    </div>
                </div>
                <div className="pr-1 w-fit flex items-center space-x-2 m-2 cursor-pointer" onClick={() => setDeferEnable(oldDeferEnable => !oldDeferEnable)}>
                    <p>¿Permite realizar operaciones en cuotas?</p>
                    <div className="h-4 w-4 rounded-sm bg-sf-white flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className={`w-full text-sf-violet-dark ${deferEnable ? '' : 'hidden'}`} viewBox="0 0 24 24"><path fill="currentColor" d="m10 13.6l5.9-5.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-6.6 6.6q-.3.3-.7.3t-.7-.3l-2.6-2.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l1.9 1.9Z" /></svg>
                    </div>
                </div>
                {deferEnable &&
                    <div className='flex flex-col space-y-3 px-3'>
                        <div className='field p-2 w-full space-x-0.5 flex justify-evenly'>
                            <div className='flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1' onClick={() => setCreateDeferAccount(true)}>
                                <div className={`rounded-full h-3.5 w-3.5 ${createDeferAccount ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></div>
                                <div className='whitespace-nowrap'>Crear cuenta</div>
                            </div>
                            <div className='flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1' onClick={() => setCreateDeferAccount(false)}>
                                <div className={`rounded-full h-3.5 w-3.5 ${!createDeferAccount ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></div>
                                <div className='whitespace-nowrap'>Seleccionar cuenta</div>
                            </div>
                        </div>
                        {createDeferAccount ?
                            <p className='field'>{financialElement.title} (cuotas pendientes)</p>
                            :
                            <Select placeholder='Cuenta para cuotas pendientes' name='deferred' value={financialElement.deferred} onChange={handleFinancialElementInputChange} options={financialElementsOptions} />
                        }
                    </div>
                }
                <div className='border-t border-sf-black w-full h-min'></div>

                <p>Prioridad:</p>
                <input type='text' inputMode='decimal' name='priority' value={financialElement.priority} onChange={e => e.target.value.match(/^[0-9]{0,2}$/) && handleFinancialElementInputChange(e)} className='field' />
                <p>Tasa de interés:</p>
                <input type='text' inputMode='decimal' name='interest_rate' value={financialElement.interest_rate} onChange={e => processDecimalInput(e) && handleFinancialElementInputChange(e)} className='field' />
                <p>Pago mínimo:</p>
                <input type='text' inputMode='decimal' name='minimum_payment' value={financialElement.minimum_payment} onChange={e => processDecimalInput(e) && handleFinancialElementInputChange(e)} className='field' />

                <div className='self-end flex space-x-2 w-full justify-end'>
                    <button className="button-secondary" onClick={() => { navigate('/debts') }}>
                        Cancelar
                    </button>
                    <button className="button-primary" disabled={!financialElement.title || !financialElement.status || financialElement.transactional === undefined || (deferEnable && (financialElement.deferred ?? '') === '' && !createDeferAccount) || !valuation.timestamp || !valuation.currency_code || !valuation.amount} onClick={saveChanges}>
                        Guardar
                    </button>
                </div>
            </article>
        </main>
    )
}