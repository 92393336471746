import { useRef } from "react";
import { useContainerDimensions } from "../hooks/UseContainerDimensions";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import { getInteger, getDecimal } from '../utils/number-utils';

interface BalanceProps {
    currency: string
    totalSaving: number
    plannedSaving: number
}

export default function Balance(props: BalanceProps): JSX.Element {
    const { getSign } = useCurrencyByCode();

    const savingsRef = useRef<HTMLDivElement>(null);
    const plannedSavingsRef = useRef<HTMLDivElement>(null);
    const totalSavingsRef = useRef<HTMLDivElement>(null);

    const { width: savingsRefWidth } = useContainerDimensions(savingsRef);
    const { width: plannedSavingsRefWidth } = useContainerDimensions(plannedSavingsRef);
    const { width: totalSavingsRefWidth } = useContainerDimensions(totalSavingsRef);

    const totalSavingNumeric = props.totalSaving;
    const plannedSavingNumeric = props.plannedSaving;

    const totalSaving = totalSavingNumeric.toString() ?? '';
    const plannedSaving = plannedSavingNumeric.toString() ?? '';

    const absoluteTotalSaving = Math.abs(totalSavingNumeric);
    const absolutePlannedSaving = Math.abs(plannedSavingNumeric);

    const negativeTotalSavingProgress = (totalSavingNumeric < 0) ? (absoluteTotalSaving < absolutePlannedSaving) ? (absoluteTotalSaving / absolutePlannedSaving) : 1 : 0;
    const positiveTotalSavingProgress = (0 < totalSavingNumeric) ? (absoluteTotalSaving < absolutePlannedSaving) ? (absoluteTotalSaving / absolutePlannedSaving) : 1 : 0;
    const negativePlannedSavingProgress = (plannedSavingNumeric < 0) ? (absolutePlannedSaving < absoluteTotalSaving) ? (absolutePlannedSaving / absoluteTotalSaving) : 1 : 0;
    const positivePlannedSavingProgress = (0 < plannedSavingNumeric) ? (absolutePlannedSaving < absoluteTotalSaving) ? (absolutePlannedSaving / absoluteTotalSaving) : 1 : 0;

    const totalSavingProgress = (1 + positiveTotalSavingProgress - negativeTotalSavingProgress) / 2;
    const plannedSavingProgress = (1 + positivePlannedSavingProgress - negativePlannedSavingProgress) / 2;

    return (
        <article className="h-min">
            <h2>Balance actual</h2>
            <div className='w-full h-min'></div>
            <div>
                <div className='w-full flex'>
                    <div style={{ width: `${(savingsRefWidth * totalSavingProgress) - (totalSavingsRefWidth / 2)}px` }}></div>
                    <div ref={totalSavingsRef} className={`w-fit flex ${totalSavingNumeric < plannedSavingNumeric ? 'text-sf-red-dark' : totalSavingNumeric > plannedSavingNumeric ? 'text-sf-green-dark' : 'text-sf-yellow-dark'}`}>
                        <p className='text-2xl font-bold'>{totalSavingNumeric < 0 ? '-' : totalSavingNumeric > 0 ? '+' : ''}</p>
                        <p className={`text-2xl ${Math.abs(totalSavingNumeric) > 0 && 'ml-1'} mr-1 font-bold`}>{getSign(props.currency)}</p>
                        <p className="text-2xl font-bold">{getInteger(totalSaving)}</p>
                        <p className="font-bold text-[16px]/[24px] ml-[1px]">{getDecimal(totalSaving)}</p>
                    </div>
                </div>
                <div className='w-full h-4'>
                    <div style={{ width: `${savingsRefWidth * totalSavingProgress}px` }} className='border-r w-min h-full border-sf-black'></div>
                </div>
                <div ref={savingsRef} className='flex w-full my-0.5 h-4 justify-center items-center'>
                    <div className='h-full w-1/2 flex items-center'>
                        <div className='h-full w-full rounded-l-full bg-sf-gray-light flex justify-end'>
                            <div style={{ width: `${savingsRefWidth * negativeTotalSavingProgress / 2}px` }} className={`flex justify-end items-center rounded-l-full min-w-[8px] ${totalSavingNumeric < plannedSavingNumeric ? 'bg-sf-red-dark' : totalSavingNumeric > plannedSavingNumeric ? 'bg-sf-green-dark' : 'bg-sf-yellow-dark'}`}>
                                <div className="w-1 h-2 rounded-l-full bg-sf-black"></div>
                            </div>
                        </div>
                    </div>
                    <div className='h-full w-1/2 flex items-center'>
                        <div className='h-full w-full rounded-r-full bg-sf-gray-light flex justify-start'>
                            <div style={{ width: `${savingsRefWidth * positiveTotalSavingProgress / 2}px` }} className={`flex justify-start items-center rounded-r-full min-w-[8px] ${totalSavingNumeric < plannedSavingNumeric ? 'bg-sf-red-dark' : totalSavingNumeric > plannedSavingNumeric ? 'bg-sf-green-dark' : 'bg-sf-yellow-dark'}`}>
                                <div className="w-1 h-2 rounded-r-full bg-sf-black"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full h-4'>
                    <div style={{ width: `${savingsRefWidth * plannedSavingProgress}px` }} className='border-r w-min h-full border-sf-black'></div>
                </div>
                <div className='w-full flex'>
                    <div style={{ width: `${(savingsRefWidth * plannedSavingProgress) - (plannedSavingsRefWidth / 2)}px` }}></div>
                    <div ref={plannedSavingsRef} className='w-fit flex flex-col items-center justify-center'>
                        <div className='flex'>
                            <p className='font-bold text-base'>{plannedSavingNumeric < 0 ? '-' : plannedSavingNumeric > 0 ? '+' : ''}</p>
                            <p className={`${Math.abs(plannedSavingNumeric) > 0 && 'ml-0.5'} mr-0.5 font-bold text-base`}>{getSign(props.currency)}</p>
                            <p className="font-bold text-base">{getInteger(plannedSaving)}</p>
                            <p className="font-bold text-[12px]/[18px] ml-[0.5px]">{getDecimal(plannedSaving)}</p>
                        </div>
                        <p>(plan)</p>
                    </div>
                </div>
            </div>
        </article>
    );
}