import { SyntheticEvent, useContext, useEffect, useState } from "react"
import { Budget, defaultBudget } from "../model/Budget";
import { createBudget, deleteBudget, listBudgets, updateBudget } from "../utils/api";
import { Context } from "../utils/context";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import Select from "../components/Select";
import { addDaysToTimestamp, ifDateIsValidThen } from "../utils/date-utils";

export default function PlanFull() {
    const { timestamp_to } = useParams();
    const { client, setConfirmDialog } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [isFirstBudget, setIsFirstBudget] = useState(false);
    const [isLastBudget, setIsLastBudget] = useState(false);
    const [isDangerousTimestampTo, setIsDangerousTimestampTo] = useState(false);
    const [budget, setBudget] = useState<Budget>({ ...defaultBudget });
    const { getSign } = useCurrencyByCode();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        async function setUp() {
            try {
                setLoading(true);
                const result = await listBudgets(client.id ?? '');
                const currentBudgetIndex = result.findIndex(budget => budget.timestamp_to === timestamp_to);
                setBudget(result[currentBudgetIndex]);
                setIsFirstBudget(currentBudgetIndex === 0);
                setIsLastBudget(currentBudgetIndex === result.length - 1);
            } finally {
                setLoading(false);
            }
        }
        setUp();
    }, [timestamp_to, client.id]);

    useEffect(() => {
        if (!budget.timestamp_to) {
            return;
        }

        const timestampToReal = new Date(parseInt(budget.timestamp_to));
        const day = timestampToReal.toISOString().substring(8, 10);

        setIsDangerousTimestampTo(day > '28');
    }, [budget.timestamp_to]);

    if (loading) return <Loading />

    function handleFromDateChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setBudget(oldBudget => ({
            ...oldBudget,
            timestamp_from: new Date(target.value).getTime().toString(),
        }));
    }

    function handleToDateChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setBudget(oldBudget => ({
            ...oldBudget,
            timestamp_to: addDaysToTimestamp(new Date(target.value).getTime().toString(), 1),
        }));
    }

    function handleBudgetChange(event: SyntheticEvent): void {
        const target = event.target as HTMLInputElement;
        setBudget(oldBudget => ({
            ...oldBudget,
            [target.name]: target.value
        }));
    }

    const returnToPlan = () => navigate(`/plan?${searchParams}`);

    async function confirmCancelDialog(): Promise<void> {
        returnToPlan();
    }

    async function saveBudget() {
        setLoading(true);

        setSearchParams(oldSearchParams => {
            oldSearchParams.set('budget_timestamp', budget.timestamp_to ?? '');
            return oldSearchParams;
        });

        if (budget.timestamp_to === timestamp_to) {
            await updateBudget(client.id ?? '', budget.timestamp_to ?? '', budget)
        } else {
            await deleteBudget(client.id ?? '', timestamp_to ?? '');
            await createBudget(client.id ?? '', budget);
        }
        returnToPlan();
    }

    async function confirmDeleteBudget(): Promise<void> {
        setSearchParams(oldSearchParams => {
            oldSearchParams.set('budget_timestamp', budget.timestamp_from ?? '');
            return oldSearchParams;
        });

        await deleteBudget(client.id ?? '', budget.timestamp_to ?? '');
        returnToPlan();
    }

    const isoTimestampFrom = new Date(parseInt(budget?.timestamp_from ?? '')).toISOString().substring(0, 10);
    const isoTimestampTo = new Date(parseInt(addDaysToTimestamp(budget?.timestamp_to ?? '', -1))).toISOString().substring(0, 10);

    const updateCurrencyCodes = [budget.currency].concat(client.currencies?.map(currency => currency.code).filter(currencyCode => currencyCode !== budget.currency));

    const confirmCancelDialogParams = {
        callback: confirmCancelDialog,
        title: '¿Estás seguro que deseas salir? Se perderán los cambios realizados',
        confirmButton: 'Salir (perder cambios)',
        cancelButton: 'Seguir editando'
    };

    const confirmDeleteDialogParams = {
        callback: confirmDeleteBudget,
        title: '¿Estás seguro que deseas eliminar este plan?',
        confirmButton: 'Eliminar',
        cancelButton: 'Cancelar'
    };

    return (
        <main>
            <article>
                <h2>Editar plan</h2>
                {isDangerousTimestampTo && <p className="w-full flex justify-center bg-sf-red-light text-sf-red-dark p-3 rounded-lg font-bold"> No es recomendable usar esta Fecha de finalización.</p>}
                <div className="flex min-w-full justify-between items-center pb-2 space-x-3">
                    <div className="w-1/2">
                        <p className={`pb-1 ${!isFirstBudget && 'text-sf-gray-dark'}`}>Fecha de inicio:</p>
                        <input name='timestamp_from' type='date' disabled={!isFirstBudget} max={isoTimestampTo} value={isoTimestampFrom} onChange={ifDateIsValidThen(handleFromDateChange)} className={`field ring-1 ${!isFirstBudget ? 'ring-sf-gray-dark text-sf-gray-dark' : 'ring-sf-violet-dark'}`} />
                    </div>
                    <div className="w-1/2">
                        <p className={`pb-1 ${!isLastBudget && 'text-sf-gray-dark'}`}>Fecha de finalización:</p>
                        <input name='timestamp_to' type='date' disabled={!isLastBudget} min={isoTimestampFrom} value={isoTimestampTo} onChange={ifDateIsValidThen(handleToDateChange)} className={`field ring-1 ${!isLastBudget ? 'ring-sf-gray-dark text-sf-gray-dark' : 'ring-sf-violet-dark'}`} />
                    </div>
                </div>
                {isFirstBudget && isLastBudget && <article className="bg-sf-violet-light">
                    <h2>Moneda del plan</h2>
                    <Select placeholder='Moneda' name='currency' value={budget.currency} onChange={handleBudgetChange} options={updateCurrencyCodes.map(currencyCode => ({ value: currencyCode, alias: getSign(currencyCode) }))} />
                </article>}

                <div className='flex w-full justify-end'>
                    <div className="flex w-full">
                        {isLastBudget && <button className="button-secondary-red px-4" onClick={() => setConfirmDialog(confirmDeleteDialogParams)}>Eliminar</button>}
                    </div>
                    <button className="button-secondary px-4 mr-4" onClick={() => setConfirmDialog(confirmCancelDialogParams)}>Volver</button>
                    <button className="button-primary" onClick={saveBudget}>Guardar</button>
                </div>
            </article>
        </main>
    )
}
