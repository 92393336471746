import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import { useContext } from 'react';
import { Context } from '../utils/context';
import { Transaction } from '../model/Transaction';
import { sortElements } from '../utils/sort-elements';
import { getInteger, getDecimal } from '../utils/number-utils';

interface TransactionItemProps {
    transaction: Transaction
}

export default function TransactionItem(props: TransactionItemProps): JSX.Element {
    const transaction = props.transaction;
    const { operationDetails, financialElements } = useContext(Context);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getSign } = useCurrencyByCode();

    const operations = transaction.operations;
    sortElements(operations, [
        { field: 'timestamp', asc: true },
        { field: 'update_timestamp', asc: false },
    ])
    return (
        <div className="py-2 space-y-2 cursor-pointer hover:bg-sf-gray-extra-light" onClick={() => navigate(`/transactions/${transaction.grouping_id}?${searchParams}`)}>
            {transaction.operations.map(operation => (
                <div key={operation.id} className='flex justify-between'>
                    <div className='max-w-[62%]'>
                        {operation.operation_detail_id === '' ?
                            <p>{operation.description}</p>
                            :
                            <div className='flex space-x-1'>
                                <h3 className='whitespace-nowrap'>{operationDetails.find(operationDetail => operationDetail.id === operation.operation_detail_id)?.title}</h3>
                                {operation.description && <p className="secondary font-bold">({operation.description})</p>}
                            </div>
                        }
                        <p className='text-xs truncate'>{financialElements.find(financialElement => financialElement.id === operation.financial_element_id)?.title}</p>
                    </div>
                    <div className='flex flex-col'>
                        <div className={`flex self-end ${operation.amount?.includes('-') ? 'expenditure' : 'income'}`}>
                            <p>{operation.amount?.includes('-') ? '-' : '+'}</p>
                            <p className="mx-1">{getSign(operation.currency_code)}</p>
                            <p>{getInteger(operation.amount ?? '')}</p>
                            <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(operation.amount ?? '')}</p>
                        </div>
                        <small className="self-end">{new Date(parseInt(operation.timestamp ?? '')).toLocaleDateString('es', { timeZone: 'UTC' })}</small>
                    </div>
                </div>
            ))}
        </div>
    )
}