import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Operation } from "../model/Operation";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import Select from "./Select";
import { OperationTypes } from "../consts/OperationTypes";
import { Context } from "../utils/context";
import { addDaysToTimestamp, addMonthsToTimestamp, ifDateIsValidThen } from "../utils/date-utils";

const possibleFilters = ['type', 'operation_detail_id', 'currency_code', 'financial_element_id'];

interface OperationsFilterProps {
    operations: Operation[];
}

export default function OperationsFilter(props: OperationsFilterProps) {
    const { financialElements, operationDetails } = useContext(Context);
    const [searchParams, setSearchParams] = useSearchParams();
    const [openFilter, setOpenFilter] = useState(false);
    const { getSign } = useCurrencyByCode();

    useEffect(() => {
        setSearchParams(oldSearchParams => {
            const today = new Date();
            const UTCtoday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()).toString();

            if (oldSearchParams.get('timestamp_to') === null) {
                oldSearchParams.set('timestamp_to', addDaysToTimestamp(UTCtoday, 1));
            }

            if (oldSearchParams.get('timestamp_from') === null) {
                oldSearchParams.set('timestamp_from', addMonthsToTimestamp(UTCtoday, -1));
            }

            return oldSearchParams;
        });
    }, [searchParams, setSearchParams]);

    function handleSearchParamsChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setSearchParams(oldSearchParams => {
            if (target.value !== '') {
                oldSearchParams.set(target.name, target.value);
            } else {
                oldSearchParams.delete(target.name);
            }
            return oldSearchParams;
        });
    }

    function handleDateFromChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        const newTimestamp = new Date(target.value ?? '').getTime().toString();

        setSearchParams(oldSearchParams => {
            oldSearchParams.set('timestamp_from', newTimestamp);
            return oldSearchParams;
        });
    }

    function handleDateToChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        const newTimestamp = new Date(target.value ?? '').getTime().toString();

        setSearchParams(oldSearchParams => {
            oldSearchParams.set('timestamp_to', addDaysToTimestamp(newTimestamp, 1));
            return oldSearchParams;
        });
    }

    function handleDeleteAllFilters() {
        setSearchParams(oldSearchParams => {
            possibleFilters.forEach(a => oldSearchParams.delete(a));
            return oldSearchParams;
        })
    }

    const timestampTo = searchParams.get('timestamp_to');
    const timestampFrom = searchParams.get('timestamp_from');
    const dateTo = timestampTo === null ? '' : new Date(parseInt(addDaysToTimestamp(timestampTo, -1))).toISOString().substring(0, 10);
    const dateFrom = timestampFrom === null ? '' : new Date(parseInt(timestampFrom)).toISOString().substring(0, 10);

    const types = Array.from(new Set(props.operations.map(operation => operation.amount?.includes('-') ? OperationTypes.EXPENDITURE : OperationTypes.INCOME)));
    const currencie_codes = Array.from(new Set(props.operations.map(operation => operation.currency_code)));

    const operationDetailIds = Array.from(new Set(props.operations.filter(operation => searchParams.get('type') ? ((searchParams.get('type') === OperationTypes.EXPENDITURE.key && operation.amount?.includes('-')) || (searchParams.get('type') === OperationTypes.INCOME.key && !operation.amount?.includes('-'))) : operation).map(operation => operation.operation_detail_id)));
    const selectOperationDetail = operationDetails.filter(operationDetail => operationDetailIds.includes(operationDetail.id)).map(operationDetail => ({ value: operationDetail.id, alias: operationDetail.title }));

    const financialElementIds = Array.from(new Set(props.operations.map(operation => operation.financial_element_id)));
    const selectFinancialElements = financialElements.filter(financialElement => financialElementIds.includes(financialElement.id)).map(financialElement => ({ value: financialElement.id, alias: financialElement.title }));

    const filtersApplied = possibleFilters.filter(a => searchParams.get(a) !== null).length;

    const filtersFull = (
        <div className="rounded-lg p-3 my-1 flex flex-col space-y-3 bg-sf-violet-light items-center justify-between">
            <Select placeholder='Selecciona un tipo' cleanable name='type' value={searchParams.get('type') ?? ''} onChange={event => {
                setSearchParams(oldSearchParams => {
                    oldSearchParams.delete('operation_detail_id');
                    return oldSearchParams;
                });
                handleSearchParamsChange(event);
            }} options={types.map(type => ({ value: type.key, alias: type.label }))} />
            {searchParams.get('type') && <Select placeholder='Selecciona una categoría de movimiento' cleanable name='operation_detail_id' value={searchParams.get('operation_detail_id') ?? ''} onChange={handleSearchParamsChange} options={selectOperationDetail} />}
            <Select placeholder='Selecciona una cuenta' cleanable name='financial_element_id' value={searchParams.get('financial_element_id') ?? ''} onChange={handleSearchParamsChange} options={selectFinancialElements} />
            <Select placeholder='Selecciona una moneda' cleanable name='currency_code' value={searchParams.get('currency_code') ?? ''} onChange={handleSearchParamsChange} options={currencie_codes.map(currency_code => ({ value: currency_code, alias: getSign(currency_code) }))} />
            <button className="button-primary" onClick={() => setOpenFilter(false)}>Ocultar</button>
        </div>
    );

    const filtersSummary = (
        <div className="flex items-center justify-between my-1">
            <div onClick={() => setOpenFilter(true)} className="rounded-lg bg-sf-gray-extra-light cursor-pointer hover:bg-sf-gray-light w-fit h-fit p-2 flex items-center space-x-2">
                <h3>Más filtros</h3>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24"><g fill="currentColor"><path d="M9.25 14a3 3 0 1 1 0 6a3 3 0 0 1 0-6Zm5-10a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z" /><path d="M17.166 7.708a3.008 3.008 0 0 0-.021-1.5h4.605a.75.75 0 0 1 0 1.5h-4.584Zm-5.811-1.5a3.003 3.003 0 0 0-.02 1.5H1.75a.75.75 0 0 1 0-1.5h9.605ZM6.356 16.209H1.75a.75.75 0 0 0 0 1.5h4.584a3.007 3.007 0 0 1 .022-1.5Zm5.81 1.5h9.584a.75.75 0 0 0 0-1.5h-9.605a3.003 3.003 0 0 1 .02 1.5Z" opacity=".5" /></g></svg>
            </div>
            {filtersApplied === 0
                ? <p className="text-sf-gray-dark italic whitespace-nowrap">No hay más filtros aplicados</p>
                : <p className="bg-sf-violet-dark pl-2.5 pr-1.5 py-0.5 rounded-full text-sm flex justify-center items-center text-sf-white font-bold whitespace-nowrap">
                    Hay {filtersApplied} filtro{filtersApplied > 1 && 's'} aplicado{filtersApplied > 1 && 's'}
                    <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer ml-1.5 h-5 w-5" viewBox="0 0 24 24" onClick={handleDeleteAllFilters}><path fill="currentColor" d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 13.4Z" /></svg>
                </p>
            }
        </div>
    );

    return (
        <div className="mx-3 pb-2">
            <div className="w-full flex border-t border-sf-black space-x-3 py-2">
                <div className="w-1/2">
                    <p className="pb-1">Desde</p>
                    <input onKeyDown={e => e.preventDefault()} name='timestamp_from' type='date' max={dateTo} value={dateFrom} className='field ring-1 ring-sf-violet-dark' onChange={ifDateIsValidThen(handleDateFromChange)} />
                </div>
                <div className="w-1/2">
                    <p className="pb-1">Hasta</p>
                    <input onKeyDown={e => e.preventDefault()} name='timestamp_to' type='date' min={dateFrom} value={dateTo} className='field ring-1 ring-sf-violet-dark' onChange={ifDateIsValidThen(handleDateToChange)} />
                </div>
            </div>
            {openFilter ? filtersFull : filtersSummary}
        </div>
    );
}