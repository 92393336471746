import { SyntheticEvent } from "react";

export function processDecimalInput(event: SyntheticEvent): boolean {
    const target = event.target as HTMLInputElement;

    if (!target.value.match(/^[0-9]*[^0-9]?[0-9]*$/)) return false;

    target.value = target.value.replace(/[^0-9]/, '.');

    return true;
}