import { useEffect, useState } from "react";

export const useContainerDimensions = (ref: React.RefObject<HTMLElement>) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateDimensions = () => setDimensions({ width: ref.current?.offsetWidth ?? 0, height: ref.current?.offsetHeight ?? 0 });
        const observer = new ResizeObserver(updateDimensions);
        if (ref.current) {
            updateDimensions();
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, [ref]);

    return dimensions;
};
