import { useContext } from "react";
import { OperationRow } from "../model/OperationRow";
import { Context } from "../utils/context";
import { FinancialElement } from "../model/FinancialElement";
import { OperationDetail } from "../model/OperationDetail";
import { Currency } from "../model/Currency";
import { OperationTypes } from "../consts/OperationTypes";
import { isValidTimestamp, parseDMY } from "../utils/date-utils";

export function useOperationRowErrors() {
    const { client, operationDetails, financialElements } = useContext(Context);

    function getOperationTypeByLabel(label: string): OperationTypes | undefined {
        return [OperationTypes.INCOME, OperationTypes.EXPENDITURE].find(operationType => operationType.label.toLowerCase() === label.toLowerCase());
    }

    function getOperationDetailByOperationTypeAndTitle(operationType: OperationTypes, title: string): OperationDetail | undefined {
        return operationDetails.find(operationDetail => operationDetail.title?.toLowerCase() === title.toLowerCase() && operationDetail.type === operationType.key);
    }

    function getFinancialElementByTitle(title: string): FinancialElement | undefined {
        return financialElements.find(financialElement => financialElement.title?.toLowerCase() === title.toLowerCase());
    }

    function getCurrencyByCodeOrSign(value: string): Currency | undefined {
        return client.currencies?.find(currency => currency.code?.toLowerCase() === value.toLowerCase() || currency.sign?.toLowerCase() === value.toLowerCase());
    }

    function getErrors(operationRow: OperationRow): string[] {
        const errors: string[] = [];

        try {
            if (operationRow['Fecha'] === '') {
                errors.push('Fecha vacía');
            } else if (!isValidTimestamp(parseDMY(operationRow['Fecha']))) {
                errors.push('Fecha no válida: ' + operationRow['Fecha']);
            }

            const operationType = getOperationTypeByLabel(operationRow['Tipo']);

            if (operationRow['Tipo'] === '') {
                errors.push('Tipo vacío');
            } else if (!operationType) {
                errors.push('Tipo no válido: ' + operationRow['Tipo']);
            }

            if (operationRow['Moneda'] === '') {
                errors.push('Moneda vacía');
            } else if (!getCurrencyByCodeOrSign(operationRow['Moneda'])) {
                errors.push('Moneda no válida: ' + operationRow['Moneda']);
            }

            if (operationRow['Monto'] === '') {
                errors.push('Monto vacío');
            } else if (!(parseFloat(operationRow['Monto']) > 0)) {
                errors.push('Monto no válido, o es menor o igual a 0: ' + operationRow['Monto']);
            } else if (operationRow['Monto'].length > 20) {
                errors.push('Monto demasiado grande: ' + operationRow['Monto']);
            }

            if (operationRow['Cuenta'] === '') {
                errors.push('Cuenta vacía');
            } else if (!getFinancialElementByTitle(operationRow['Cuenta'])) {
                errors.push('Cuenta no válida: ' + operationRow['Cuenta']);
            }

            const operationDetail = operationType ? getOperationDetailByOperationTypeAndTitle(operationType, operationRow['Categoría de movimiento']) : undefined;

            if (operationRow['Categoría de movimiento'] === '') {
                if (operationRow['Descripción'] === '') {
                    errors.push('Descripción y Categoría de movimiento vacíos al mismo tiempo');
                }
            } else if (!operationDetail) {
                errors.push('Categoría de movimiento no válida: ' + operationRow['Categoría de movimiento']);
            }

            if (operationRow['Descripción'].length > 100) {
                errors.push('Descripción demasiado grande: ' + operationRow['Descripción']);
            }
        } catch (error) {
            errors.push('Error desconocido: ' + JSON.stringify(error));
        }

        return errors;
    }

    return { getErrors };
}