export function getInteger(amount: string): string {
    return Math.floor(Math.abs(parseFloat(amount))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export function getDecimal(amount: string) {
    return (parseFloat(amount) % 1).toFixed(2).split('.')[1];
}

export function getProportion(num: string, den: string) {
    return parseFloat(den) === 0 ? '-' : Math.floor(100 * parseFloat(num) / parseFloat(den)).toString();
}
