export interface Operation {
    readonly 'id'?: string;
    readonly 'financial_element_id'?: string;
    readonly 'operation_detail_id'?: string;
    readonly 'grouping_id'?: string;
    readonly 'timestamp'?: string;
    readonly 'hour'?: string;
    readonly 'currency_code'?: string;
    readonly 'amount'?: string;
    readonly 'description'?: string;
    readonly 'creation_timestamp'?: string;
    readonly 'update_timestamp'?: string;
};

export const defaultOperation: Operation = {
    'financial_element_id': '',
    'operation_detail_id': '',
    'grouping_id': '',
    'timestamp': '',
    'hour': '',
    'currency_code': '',
    'amount': '',
    'description': '',
    'id': '',
    'update_timestamp': '',
};
