import { useEffect, useRef, useState } from "react";
import { Item } from "../../model/Budget";
import { getDecimal, getInteger } from "../../utils/number-utils";
import { processDecimalInput } from "../../utils/process-decimal-input";
import ProgressBarViolet from "../ProgressBarViolet";

interface UnbudgetedItemMovementRowProps {
    currency: string,
    currentAmount: string,
    item: Item,
    title: string,
    goTo: () => void,
    addItem: (item: Item) => void
}

export default function UnbudgetedItemMovementRow(props: UnbudgetedItemMovementRowProps): JSX.Element {
    const [editAmount, setEditAmount] = useState(false);
    const [newAmount, setNewAmount] = useState('');
    const [isExpenditure, setIsExpenditure] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (editAmount) {
            setNewAmount('');
            setIsExpenditure(props.item.amount?.includes('-') ?? false);
            inputRef.current?.focus();
        }
    }, [editAmount, inputRef, props.item.amount]);

    function handleOnClick() {
        if (newAmount !== '') {
            props.addItem({ ...props.item, amount: isExpenditure ? `-${newAmount}` : newAmount });
        }
        setEditAmount(oldEditAmount => !oldEditAmount);
    }

    const currentAmount = props.currentAmount.replace('-', '');

    return (
        <div className="py-1.5 w-full flex">
            <div className='w-full flex'>
                <p className="w-1/4 truncate">{props.title}</p>
                <div className='w-1/4 flex justify-start items-center'>
                    <div className='flex'>
                        <p className='mr-1'>{props.currency}</p>
                        <p>{getInteger(currentAmount)}</p>
                        <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(currentAmount)}</p>
                    </div>
                    <button className="ml-1 p-0.5 w-5 h-5 cursor-pointer rounded-lg text-sf-violet-dark hover:bg-sf-violet-medium" onClick={props.goTo}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M13.3 20.275q-.3-.3-.3-.7t.3-.7L16.175 16H7q-.825 0-1.413-.588T5 14V5q0-.425.288-.713T6 4q.425 0 .713.288T7 5v9h9.175l-2.9-2.9q-.3-.3-.288-.7t.288-.7q.3-.3.7-.312t.7.287L19.3 14.3q.15.15.212.325t.063.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.3t-.713-.3Z" /></svg>
                    </button>
                </div>
                {editAmount ?
                    <div className='w-1/4 flex justify-start items-center space-x-1.5 pr-1.5'>
                        <input type='text' ref={inputRef} inputMode='decimal' name='amount' value={newAmount} onChange={e => processDecimalInput(e) && setNewAmount(e.target.value)} className='field py-0' />
                        <button className="cursor-pointer" onClick={handleOnClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 text-sf-violet-dark" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10" /><path strokeLinecap="round" strokeLinejoin="round" d="m8.5 12.5l2 2l5-5" /></g></svg>
                        </button>
                        <button className="cursor-pointer" onClick={() => setEditAmount(oldEditAmount => !oldEditAmount)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 text-sf-red-dark" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10" /><path strokeLinecap="round" d="m14.5 9.5l-5 5m0-5l5 5" /></g></svg>
                        </button>
                    </div>
                    :
                    <div className='w-1/4 flex justify-start items-center'>
                        <p className='italic'>No planeado</p>
                        <button className="ml-1 p-0.5 w-5 h-5 cursor-pointer rounded-lg text-sf-violet-dark hover:bg-sf-violet-medium" onClick={() => setEditAmount(oldEditAmount => !oldEditAmount)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M5 19h1.4l8.625-8.625l-1.4-1.4L5 17.6V19ZM19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM4 21q-.425 0-.713-.288T3 20v-2.825q0-.2.075-.388t.225-.337l10.3-10.3l4.25 4.25l-10.3 10.3q-.15.15-.337.225T6.825 21H4ZM14.325 9.675l-.7-.7l1.4 1.4l-.7-.7Z" /></svg>
                        </button>
                    </div>
                }
                <div className="w-1/4">
                    <ProgressBarViolet current={props.currentAmount} planned={'0'} />
                </div>
            </div>
            <div className='w-16'></div>
        </div>
    );
}
