export class FinancialElementTypes {
    private static readonly instances = new Map<string, FinancialElementTypes>();
    private constructor(public readonly key: string, public readonly label: string) {
        FinancialElementTypes.instances.set(key, this);
    }
    static get(key?: string): FinancialElementTypes | undefined {
        return FinancialElementTypes.instances.get(key ?? '');
    }

    static readonly ASSET = new FinancialElementTypes('ASSET', 'Ahorro o inversión');
    static readonly DEBT = new FinancialElementTypes('DEBT', 'Deuda');
}
