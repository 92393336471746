interface SortStatement {
    field: string | number,
    asc: boolean
}

export function sortElements(array: any[], sortStatements: SortStatement[]): any[] {
    return array.sort((a, b) => {
        for (let i = 0; i < sortStatements.length; i++) {
            if (a[sortStatements[i].field] === undefined || b[sortStatements[i].field] === undefined) return 0;
            if (a[sortStatements[i].field] !== b[sortStatements[i].field]) return (sortStatements[i].asc ? 1 : -1) * (a[sortStatements[i].field] > b[sortStatements[i].field] ? 1 : -1);
        }
        return 0;
    });
}
