import { useRef } from "react";
import { useContainerDimensions } from "../hooks/UseContainerDimensions";

interface WidthWarningProps {
    minWidth: number
}

export function WidthWarning(props: WidthWarningProps): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);
    const { width } = useContainerDimensions(ref);

    return (
        <div ref={ref} className='w-full'>
            {width > 0 && width < props.minWidth &&
                <article className="w-[340px] bg-sf-red-light text-sf-red-dark">
                    <h2>Advertencia</h2>
                    <h3>El dispositivo actual no es adecuado para visualizar esta sección cómodamente</h3>
                    <h3 className='underline'>Utiliza un dispositivo con pantalla más grande</h3>
                </article>
            }
        </div>
    );
}
