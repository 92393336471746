export interface OperationDetail {
    readonly 'title'?: string;
    readonly 'description'?: string;
    readonly 'goal_id'?: string;
    readonly 'type'?: string;
    readonly 'status'?: string;
    readonly 'id'?: string;
    readonly 'creation_timestamp'?: string;
    readonly 'update_timestamp'?: string;
};

export const defaultOperationDetail: OperationDetail = {
    'title': '',
    'description': '',
    'goal_id': '',
    'type': '',
    'status': '',
    'id': '',
    'update_timestamp': '',
};
