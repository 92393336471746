import { useNavigate, useSearchParams } from "react-router-dom";
import OperationForm from "../components/OperationForm";
import LastOperations from "../components/LastOperations";

export default function NewOperation() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    return (
        <main>
            <button className='button-aux w-fit p-1 col-span-full' onClick={() => navigate(`/operations?${searchParams}`)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-2.5 w-2.5" viewBox="0 0 24 24"><path fill="currentColor" d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875q-.375.375-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12q0-.375.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388q.375.375.375.875t-.375.875L3.55 12Z" /></svg>
                <div>Ir al listado</div>
            </button>
            <OperationForm />
            <LastOperations />
        </main>
    )
}