import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useContainerDimensions } from "../hooks/UseContainerDimensions";
import { useVerticalScrollWithShadow } from "../hooks/VerticalScrollWithShadow";
import { useCallbackWhenClickOutside } from "../hooks/UseCallbackWhenClickOutside";

type OptionWithAlias = {
    value?: string,
    alias?: string
}

interface SelectBlackProps {
    placeholder?: string
    options?: (OptionWithAlias | string)[]
    value?: string
    name?: string
    cleanable?: boolean
    onChange?: (event: SyntheticEvent) => void
}

export default function SelectBlack(props: SelectBlackProps) {
    const [inputValue, setInputValue] = useState("");
    const [valueToFilter, setValueToFilter] = useState("");
    const [open, setOpen] = useState(false);
    const [openMenuUpside, setOpenMenuUpside] = useState<boolean | undefined>(undefined);
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const menuRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const { width } = useContainerDimensions(headerRef);
    useCallbackWhenClickOutside(listRef, () => setOpen(false));

    useEffect(() => {
        if (open) {
            setOpenMenuUpside(window.scrollY + window.innerHeight < (menuRef.current?.offsetTop ?? 0) + (menuRef.current?.offsetHeight ?? 0));
        } else {
            setOpenMenuUpside(undefined);
        }
    }, [open]);

    function handleClick(value?: string) {
        setOpen(oldOpen => !oldOpen);
        setInputValue(value ?? '');
        setValueToFilter("");
    }

    function handleNewSelection(event: SyntheticEvent, option?: OptionWithAlias) {
        const target = event.target as HTMLInputElement;
        target.name = props.name ?? '';
        target.value = option?.value ?? '';
        props.onChange !== undefined && props.onChange(event);
        handleClick(option?.alias);
    }

    let options: OptionWithAlias[] = [];
    if (props.options !== undefined && props.options.length !== 0) {
        options = props.options.map(option => typeof option === 'string' ? { value: option, alias: option } : option);
    }

    const valueToDisplay = options?.find(option => option.value === props.value)?.alias;

    if (open) {
        return (
            <div ref={listRef} className="h-fit w-full relative">
                <div ref={headerRef} className="bg-sf-white px-3 flex items-center rounded-lg justify-between ring-1 ring-sf-black h-fit w-full">
                    <div className="py-0.5 w-5/6">
                        <div className="text-xs text-sf-black">{props.placeholder}</div>
                        <input id={props.placeholder} className=" focus:ring-0 p-0 ring-0 outline-none border-0 text-sm bg-sf-white w-full" type="text" name={props.name} value={inputValue} onChange={e => { setInputValue(e.target.value); setValueToFilter(e.target.value); }} />
                    </div>
                    <div className="flex w-min items-center space-x-1">
                        {valueToDisplay && props.cleanable &&
                            <div className="h-8 w-8 p-1 rounded-full bg-sf-gray-extra-light flex items-center justify-center cursor-pointer" onClick={event => { handleNewSelection(event, undefined); }} >
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 24 24"><path fill="currentColor" d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 13.4Z" /></svg>
                            </div>
                        }
                        <div className="h-8 w-8 p-2 rounded-full bg-sf-gray-extra-light flex items-center justify-center" onClick={() => handleClick(props.value)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-8 h-8' viewBox="183.868 136.755 14 8"><path fill="currentColor" d="M 184.176 144.449 C 184.374 144.645 184.641 144.755 184.92 144.755 C 185.2 144.755 185.467 144.645 185.665 144.449 L 190.875 139.277 L 196.084 144.449 C 196.283 144.64 196.549 144.745 196.825 144.742 C 197.101 144.74 197.365 144.63 197.56 144.436 C 197.755 144.243 197.866 143.981 197.868 143.707 C 197.871 143.433 197.764 143.169 197.573 142.972 L 191.619 137.061 C 191.421 136.865 191.154 136.755 190.875 136.755 C 190.595 136.755 190.328 136.865 190.13 137.061 L 184.176 142.972 C 183.979 143.168 183.868 143.433 183.868 143.711 C 183.868 143.988 183.979 144.253 184.176 144.449 Z" transform="matrix(1, 0, 0, 1, 0, 1.4210854715202004e-14)" /></svg>
                        </div>
                    </div>
                </div>
                <div ref={menuRef} className={`absolute z-10 ${openMenuUpside === undefined && 'opacity-0'} ${openMenuUpside && '-translate-y-12 h-0 flex items-end'}`}>
                    <div onScroll={onScrollHandler} ref={ref} style={{ width }} className={`mt-1 p-2 max-h-64 bg-sf-white rounded-lg overflow-auto ring-1 ring-sf-black ring-inset space-y-1 ${boxShadow}`}>
                        {options?.map((option, index) => (
                            <div key={index} className={`flex w-full ${option.alias?.toLowerCase().includes(valueToFilter.toLowerCase()) ? "block" : "hidden"}`}>
                                <div className={`w-1 my-0.5 mr-1.5 rounded-full ${option.value === props.value && 'bg-sf-black'}`}></div>
                                <div key={index} onClick={event => { handleNewSelection(event, option); }} className={`p-2 text-sm w-full rounded-lg mr-2.5 ${option.value === props.value ? "bg-sf-gray-medium hover:bg-sf-gray-medium" : "hover:bg-sf-gray-extra-light cursor-pointer"}`}>{option.alias}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div ref={headerRef} className="h-fit w-full">
            <div onClick={() => handleClick(valueToDisplay)} className='bg-sf-white px-3 flex items-center rounded-lg justify-between mt-0' >
                {valueToDisplay ?
                    <div className="py-0.5 w-5/6">
                        <div className="text-xs text-sf-black">{props.placeholder}</div>
                        <input id={props.placeholder} readOnly className=" focus:ring-0 p-0 ring-0 outline-none border-0 text-sm bg-sf-white w-full" type="text" name={props.name} value={valueToDisplay} />
                    </div>
                    :
                    <div className="truncate text-sm w-5/6 my-3">{props.placeholder}</div>
                }

                <div className="flex w-min items-center space-x-1">
                    {valueToDisplay && props.cleanable &&
                        <div className="h-8 w-8 p-1 rounded-full bg-sf-gray-extra-light flex items-center justify-center cursor-pointer" onClick={event => { handleNewSelection(event, undefined); }} >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 24 24"><path fill="currentColor" d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 13.4Z" /></svg>
                        </div>
                    }
                    <div className="h-8 w-8 p-2 rounded-full flex items-center justify-center hover:bg-sf-gray-extra-light cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 14 8"><path fill="currentColor" d="M0.308162 0.305919C0.505537 0.110039 0.773199 0 1.05229 0C1.33138 0 1.59904 0.110039 1.79641 0.305919L7.00635 5.47795L12.2163 0.305919C12.4148 0.115591 12.6807 0.0102748 12.9566 0.0126554C13.2326 0.015036 13.4966 0.124922 13.6917 0.318647C13.8869 0.512371 13.9976 0.774433 14 1.04839C14.0024 1.32235 13.8963 1.58628 13.7045 1.78334L7.75048 7.69408C7.55311 7.88996 7.28544 8 7.00635 8C6.72727 8 6.4596 7.88996 6.26223 7.69408L0.308162 1.78334C0.110846 1.5874 0 1.32169 0 1.04463C0 0.767573 0.110846 0.501858 0.308162 0.305919Z" /></svg>
                    </div>
                </div>
            </div>
        </div>
    );
}
