import { signOut } from 'aws-amplify/auth';
import OperationsService from './OperationsService';

export async function handleSignOut() {
    try {
        await signOut();
        OperationsService.clearCacheAndNotifySubscribers();
        localStorage.clear();
        window.location.reload();
    } catch (error) {
        console.error('error signing out: ', error);
    }
}
