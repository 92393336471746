import { useContext } from "react";
import { Context } from "../../utils/context";
import { OperationDetail } from "../../model/OperationDetail";
import { FinancialElement } from "../../model/FinancialElement";

interface FirstColumnProps {
    incomeIds: string[],
    spendingIds: string[],
    goalIds: string[],
    borrowingIds: string[],
    debtPaymentIds: string[],
    profitIds: string[],
    investmentIds: string[],
    displayIncome: boolean,
    displaySpending: boolean,
    displayGoal: boolean,
    displayBorrowing: boolean,
    displayDebtPayment: boolean,
    displayProfit: boolean,
    displayInvestment: boolean,
    setDisplayIncome: () => void,
    setDisplaySpending: () => void,
    setDisplayGoal: () => void,
    setDisplayBorrowing: () => void,
    setDisplayDebtPayment: () => void,
    setDisplayProfit: () => void,
    setDisplayInvestment: () => void,
}

export default function FirstColumn(props: FirstColumnProps): JSX.Element {
    const { operationDetails, financialElements } = useContext(Context);

    const generateSectionComponent = (title: string, display: boolean, setDisplay: () => void, ids: string[], elements: (OperationDetail | FinancialElement)[]) => {
        return (
            <div className='pb-1.5'>
                <div className='flex hover:bg-sf-gray-extra-light cursor-pointer p-1.5 mr-3 rounded-lg justify-between items-center' onClick={setDisplay}>
                    <h3>{title}</h3>
                    <div className="h-5 w-5 p-1 rounded-full flex items-center" >
                        {display ?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="183.868 136.755 14 8"><path fill="currentColor" d="M 184.176 144.449 C 184.374 144.645 184.641 144.755 184.92 144.755 C 185.2 144.755 185.467 144.645 185.665 144.449 L 190.875 139.277 L 196.084 144.449 C 196.283 144.64 196.549 144.745 196.825 144.742 C 197.101 144.74 197.365 144.63 197.56 144.436 C 197.755 144.243 197.866 143.981 197.868 143.707 C 197.871 143.433 197.764 143.169 197.573 142.972 L 191.619 137.061 C 191.421 136.865 191.154 136.755 190.875 136.755 C 190.595 136.755 190.328 136.865 190.13 137.061 L 184.176 142.972 C 183.979 143.168 183.868 143.433 183.868 143.711 C 183.868 143.988 183.979 144.253 184.176 144.449 Z" transform="matrix(1, 0, 0, 1, 0, 1.4210854715202004e-14)" /></svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8"><path fill="currentColor" d="M0.308162 0.305919C0.505537 0.110039 0.773199 0 1.05229 0C1.33138 0 1.59904 0.110039 1.79641 0.305919L7.00635 5.47795L12.2163 0.305919C12.4148 0.115591 12.6807 0.0102748 12.9566 0.0126554C13.2326 0.015036 13.4966 0.124922 13.6917 0.318647C13.8869 0.512371 13.9976 0.774433 14 1.04839C14.0024 1.32235 13.8963 1.58628 13.7045 1.78334L7.75048 7.69408C7.55311 7.88996 7.28544 8 7.00635 8C6.72727 8 6.4596 7.88996 6.26223 7.69408L0.308162 1.78334C0.110846 1.5874 0 1.32169 0 1.04463C0 0.767573 0.110846 0.501858 0.308162 0.305919Z" /></svg>
                        }
                    </div>
                </div>
                {display && ids.map((id, index) =>
                    <p className='ml-1.5 mb-1.5' key={index}>{elements.find(element => element.id === id)?.title}</p>
                )}
            </div>
        );
    };

    const subtotalComponent = (title: string) => (
        <div className='py-1.5'>
            <div className='p-1.5'>
                <h3>{title}</h3>
            </div>
        </div>
    );

    return (
        <ul className='min-w-[320px] max-w-[320px] divide-y divide-sf-black truncate'>
            <div className='h-12 flex flex-col pt-1.5' />
            <div className='pl-1.5'>
                <h2 className='my-1'>Economía cotidiana</h2>
                {generateSectionComponent('Movimientos de ingreso', props.displayIncome, props.setDisplayIncome, props.incomeIds, operationDetails)}
                {generateSectionComponent('Movimientos de egreso', props.displaySpending, props.setDisplaySpending, props.spendingIds, operationDetails)}
            </div>
            <div className='pl-1.5'>
                {subtotalComponent('Capacidad de ahorro estructural')}
            </div>
            <div className='pl-1.5'>
                <h2 className='my-1'>Deudas</h2>
                {generateSectionComponent('Entradas de dinero por asumir deudas', props.displayBorrowing, props.setDisplayBorrowing, props.borrowingIds, financialElements)}
                {generateSectionComponent('Salidas de dinero para pagar deudas', props.displayDebtPayment, props.setDisplayDebtPayment, props.debtPaymentIds, financialElements)}
            </div>
            <div className='pl-1.5'>
                {subtotalComponent('Disponible para objetivos')}
            </div>
            <div className='pl-1.5'>
                <h2 className='my-1'>Cumpliendo objetivos</h2>
                {generateSectionComponent('Movimientos de objetivos', props.displayGoal, props.setDisplayGoal, props.goalIds, operationDetails)}
            </div>
            <div className='pl-1.5'>
                {subtotalComponent('Disponible para asignar')}
            </div>
            <div className='pl-1.5'>
                <h2 className='my-1'>Inversiones</h2>
                {generateSectionComponent('Entradas de dinero desde inversiones', props.displayProfit, props.setDisplayProfit, props.profitIds, financialElements)}
                {generateSectionComponent('Salidas de dinero para inversiones', props.displayInvestment, props.setDisplayInvestment, props.investmentIds, financialElements)}
            </div>
            <div className='pl-1.5'>
                {subtotalComponent('Resultado general')}
            </div>
        </ul>
    );
}
