import { useEffect, useRef } from "react";

export function useHorizontalOverscrollingPrevention() {
    const nodeRef = useRef<HTMLElement | null>(null);

    function preventHorizontalOverscrolling(wheelEvent: WheelEvent) {
        if (!nodeRef.current) return;

        const { deltaX } = wheelEvent;
        const { clientWidth, scrollWidth, scrollLeft } = nodeRef.current;

        const isLeftOverscrolling = scrollLeft <= 1 && deltaX < 0;
        const isRightOverscrolling = scrollWidth - scrollLeft - clientWidth <= 1 && deltaX > 0;

        if (isLeftOverscrolling || isRightOverscrolling) {
            wheelEvent.preventDefault();
        }
    }

    const ref = (newNode: HTMLElement | null) => {
        nodeRef.current?.removeEventListener('wheel', preventHorizontalOverscrolling);
        nodeRef.current = newNode;
        nodeRef.current?.addEventListener('wheel', preventHorizontalOverscrolling, { passive: false });
    };

    useEffect(() => {
        return () => nodeRef.current?.removeEventListener('wheel', preventHorizontalOverscrolling);
    }, []);

    return ref;
}
