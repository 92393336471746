interface GoalStatusProps {
    status: string
}

export default function GoalStatus(props: GoalStatusProps): JSX.Element {
    switch (props.status) {
        case 'done':
            return <p className="p-4 rounded-full text-xs text-center h-4 w-fit flex flex-col justify-center font-bold bg-sf-violet-dark text-sf-white">Cumplido</p>
        case 'pending':
            return <p className="p-4 rounded-full text-xs text-center h-4 w-fit flex flex-col justify-center font-bold ring-1 ring-inset ring-sf-violet-dark text-sf-violet-dark">Pendiente</p>
        default:
            return <p className="p-4"></p>
    }
}