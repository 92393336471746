import { useNavigate } from 'react-router-dom';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import { getInteger, getDecimal, getProportion } from '../utils/number-utils';

const DELTA = 0.2;

function calculateColor(num: string, den: string, invert: boolean) {
    const proportion = parseFloat(num) / parseFloat(den);

    if (Number.isNaN(proportion)) return 'yellow';

    if (!Number.isFinite(proportion)) return invert ? 'red' : 'green';

    return invert ? (
        proportion <= 1 - DELTA ? 'green' : proportion >= 1 + DELTA ? 'red' : 'yellow'
    ) : (
        proportion <= 1 - DELTA ? 'red' : proportion >= 1 + DELTA ? 'green' : 'yellow'
    );
}

interface BudgetItemProps {
    currentAmount: string
    plannedAmount: string
    title: string | JSX.Element
    queryParams: string
    currency: string
}
export default function BudgetItem(props: BudgetItemProps): JSX.Element {
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();

    const color = calculateColor(props.currentAmount, props.plannedAmount, props.plannedAmount.includes('-'));
    const proportion = getProportion(props.currentAmount, props.plannedAmount);

    return (
        <div className="py-2 space-y-2 hover:bg-sf-gray-extra-light cursor-pointer" onClick={() => navigate(`/operations?${props.queryParams}`)}>
            <div className='flex w-full justify-between'>
                <div className="text-sm font-bold min-w-fit pr-4 self-center">{props.title}</div>
                {color === 'red' ?
                    <div className={`h-5 flex w-[100px] font-bold items-center justify-between rounded-full bg-sf-red-medium`}>
                        <div style={{ width: `${proportion}%` }} className={`h-5 rounded-full min-w-[20px] bg-sf-red-dark`} />
                        <small className='absolute z-0 w-[100px] flex justify-end pr-2'>{proportion.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} %</small>
                    </div>
                    :
                    color === 'green' ? <div className={`h-5 flex w-[100px] font-bold items-center justify-between rounded-full bg-sf-green-medium`}>
                        <div style={{ width: `${proportion}%` }} className={`h-5 rounded-full min-w-[20px] bg-sf-green-dark`} />
                        <small className='absolute z-0 w-[100px] flex justify-end pr-2'>{proportion.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} %</small>
                    </div>
                        :
                        <div className={`h-5 flex w-[100px] font-bold items-center justify-between rounded-full bg-sf-yellow-medium`}>
                            <div style={{ width: `${proportion}%` }} className={`h-5 rounded-full min-w-[20px] bg-sf-yellow-dark`} />
                            <small className='absolute z-0 w-[100px] flex justify-end pr-2'>{proportion.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} %</small>
                        </div>
                }
            </div>
            <div className='flex w-full justify-between'>
                <div className="flex">
                    <small>Plan:</small>
                    <small className="mx-1">{getSign(props.currency)}</small>
                    <small>{getInteger(props.plannedAmount)}</small>
                    <small className="text-[9px]/[12px] ml-[1px]">{getDecimal(props.plannedAmount)}</small>
                </div>
                <div className="flex">
                    <small>Actual:</small>
                    <small className="mx-1">{getSign(props.currency)}</small>
                    <small>{getInteger(props.currentAmount)}</small>
                    <small className="text-[9px]/[12px] ml-[0.5px]">{getDecimal(props.currentAmount)}</small>
                </div>
            </div>
        </div>
    );
}
