export class OperationTypes {
    private static readonly instances = new Map<string, OperationTypes>();
    private constructor(public readonly key: string, public readonly label: string) {
        OperationTypes.instances.set(key, this);
    }
    static get(key?: string): OperationTypes | undefined {
        return OperationTypes.instances.get(key ?? '');
    }

    static readonly EXPENDITURE = new OperationTypes('EXPENDITURE', 'Egreso');
    static readonly INCOME = new OperationTypes('INCOME', 'Ingreso');

    static readonly OUTFLOW = new OperationTypes('OUTFLOW', 'Reducir saldo');
    static readonly INFLOW = new OperationTypes('INFLOW', 'Aumentar saldo');
    }
