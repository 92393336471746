import { RefObject, useEffect } from "react";

export function useCallbackWhenClickOutside(ref: RefObject<HTMLElement>, callback: () => void) {
    useEffect(() => {
        function handleMouseDown(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
                callback();
            }
        }

        document.addEventListener("mousedown", handleMouseDown);

        return () => document.removeEventListener("mousedown", handleMouseDown);
    }, [ref, callback]);
}
