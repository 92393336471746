import { useEffect, useRef, useState } from "react";
import { useCallbackWhenClickOutside } from "../../hooks/UseCallbackWhenClickOutside";
import { processDecimalInput } from "../../utils/process-decimal-input";
import { getDecimal, getInteger } from "../../utils/number-utils";

interface EntryProps {
    currency: string,
    amount: string | undefined
    update: (newAmount: string) => void
    add: (newAmount: string) => void
    remove: () => void
}

export default function Entry(props: EntryProps): JSX.Element {
    const [editAmount, setEditAmount] = useState(false);
    const [newAmount, setNewAmount] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const editAmountRef = useRef<HTMLInputElement>(null);
    useCallbackWhenClickOutside(editAmountRef, () => setEditAmount(false));

    useEffect(() => {
        if (editAmount) {
            setNewAmount(props.amount?.replace('-', '') ?? '');
            inputRef.current?.focus();
        }
    }, [editAmount, inputRef, props.amount]);

    function handleOnClick() {
        if (props.amount?.replace('-', '') ?? '' !== newAmount) {
            if (newAmount !== undefined && newAmount !== '') {
                if (props.amount !== undefined && props.amount !== '') {
                    props.update(newAmount);
                } else {
                    props.add(newAmount);
                }
            } else {
                props.remove();
            }
        }
        setEditAmount(oldEditAmount => !oldEditAmount);
    }

    if (editAmount) {
        return (
            <div ref={editAmountRef} className='flex justify-start items-center space-x-1.5 mb-1.5'>
                <input type='text' ref={inputRef} inputMode='decimal' name='amount' value={newAmount} className='field py-0' onChange={e => processDecimalInput(e) && setNewAmount(e.target.value)} />
                <button className="cursor-pointer" onClick={handleOnClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 text-sf-violet-dark" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10" /><path strokeLinecap="round" strokeLinejoin="round" d="m8.5 12.5l2 2l5-5" /></g></svg>
                </button>
            </div>
        );
    }

    if (props.amount !== undefined) {
        return (
            <div className='flex cursor-text w-fit mb-1.5' onClick={() => setEditAmount(oldEditAmount => !oldEditAmount)}>
                <p className='mr-1'>{props.currency}</p>
                <p>{getInteger(props.amount)}</p>
                <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(props.amount)}</p>
            </div>
        );
    }

    return (
        <p className='italic cursor-text w-fit mb-1.5' onClick={() => setEditAmount(oldEditAmount => !oldEditAmount)}>No planeado</p>
    );
}
