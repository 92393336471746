import { SyntheticEvent, useCallback, useState } from 'react';

export function useVerticalScrollWithShadow() {
    const [boxShadow, setBoxShadow] = useState('');

    const updateShadow = useCallback((node: HTMLElement | null) => node && setBoxShadow(
        node.clientHeight === node.scrollHeight ? 'shadow-none'
            : node.scrollTop <= 1 ? 'shadow-[inset_rgba(0,0,0,0.5)_0px_-16px_16px_-16px]'
                : node.scrollHeight - node.scrollTop - node.clientHeight <= 1 ? 'shadow-[inset_rgba(0,0,0,0.5)_0px_16px_16px_-16px]'
                    : 'shadow-[inset_rgba(0,0,0,0.5)_0px_-16px_16px_-16px,inset_rgba(0,0,0,0.5)_0px_16px_16px_-16px]'
    ), []);

    /**
     * Update shadow when the component is resized.
     * By doing this, every time a component is mounted, its shadow is calculated.
     * This is so because all components start having an initial height of 0 for a very short period of time,
     * and then immediatly after they are set to have its real height.
     * If we were to directly update the component shadow,
     * then it would be calculated based on a current height of 0,
     * and since this call would be memoized in a useCallback hook,
     * the final effect is that the component has no shadow until a scroll event happens,
     * even if after the initial calculation the component height is set to its normal size.
     * Also, there is an even earlier step in the process of rendering the component in which the node is null.
     * This is handled checking if node exists before setting the resize observer.
    */
    const ref = useCallback((node: HTMLElement | null) => node && new ResizeObserver(() => updateShadow(node)).observe(node), [updateShadow]);

    const onScrollHandler = (event: SyntheticEvent) => updateShadow(event.target as HTMLElement);

    return { ref, boxShadow, onScrollHandler };
}
