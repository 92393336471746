export interface Item {
    readonly 'operation_detail_id'?: string;
    readonly 'financial_element_id'?: string;  // One of these fields should remain empty
    readonly 'amount'?: string;
}

export const defaultItem: Item = {
    'operation_detail_id': '',
    'financial_element_id': '',
    'amount': '',
}

export interface Budget {
    readonly 'title'?: string;
    readonly 'timestamp_from'?: string;
    readonly 'timestamp_to'?: string;
    readonly 'currency'?: string;
    readonly 'items'?: Item[];
    readonly 'creation_timestamp'?: string;
    readonly 'update_timestamp'?: string;
};

export const defaultBudget: Budget = {
    'title': '',
    'timestamp_from': '',
    'timestamp_to': '',
    'currency': '',
    'items': [],
    'creation_timestamp': '',
    'update_timestamp': '',
};
