import OperationForm from '../components/OperationForm';
import LastOperations from '../components/LastOperations';
import Title from '../components/Title';
import BudgetSummary from '../components/BudgetSummary';

export default function Home(): JSX.Element {
    return (
        <main>
            <Title />
            <OperationForm />
            <LastOperations />
            <BudgetSummary />
        </main>
    );
}
