import { getDecimal, getInteger } from "../../utils/number-utils";

interface CashflowItemProps {
    title: string,
    setKey: (key: string) => void,
    currency: string,
    amount: string
}

export default function CashflowItem(props: CashflowItemProps): JSX.Element {
    return (
        <div className='flex w-full justify-between items-center'>
            <p className='max-w-[62%] hover:underline cursor-pointer' onClick={() => props.setKey(props.title)}>{props.title}</p>
            <div className="flex w-fit">
                <p className="font-mono">{props.amount.includes('-') ? '-' : '+'}</p>
                <p className='mx-1'>{props.currency}</p>
                <p>{getInteger(props.amount)}</p>
                <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(props.amount)}</p>
            </div>
        </div>
    );
}
