import { useContext, useEffect, useRef, useState } from "react"
import { Item } from "../model/Budget"
import { Context } from "../utils/context"
import { useCurrencyByCode } from "../hooks/CurrencyFromCode"
import { processDecimalInput } from "../utils/process-decimal-input";
import { getInteger, getDecimal } from '../utils/number-utils';

interface EditBudgetItemProps {
    item: Item
    currency: string
    removeFromBudget: () => void
    saveItem: (item: Item) => void,
    moveUp: () => void
    moveDown: () => void
}

export default function EditBudgetItem(props: EditBudgetItemProps): JSX.Element {
    const { operationDetails, financialElements } = useContext(Context);
    const [editAmount, setEditAmount] = useState(false);
    const [newAmount, setNewAmount] = useState(props.item.amount ?? '');
    const [isExpenditure, setIsExpenditure] = useState(false);
    const { getSign } = useCurrencyByCode();
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (editAmount) {
            setNewAmount(props.item.amount?.replace('-', '') ?? '');
            setIsExpenditure(props.item.amount?.includes('-') ?? false);
            inputRef.current?.focus();
        }
    }, [editAmount, inputRef, props.item.amount]);

    return (
        <div className="py-2 flex">
            <div className="flex flex-col justify-center items-center ml-2 mr-6">
                <button className="cursor-pointer bg-sf-gray-extra-light rounded-t-lg pb-1 hover:bg-sf-gray-light" onClick={props.moveUp}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6" viewBox="0 0 24 24"><path fill="currentColor" d="m12 10.8l-3.9 3.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.6-4.6q.3-.3.7-.3t.7.3l4.6 4.6q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 10.8Z" /></svg>
                </button>
                <button className="cursor-pointer bg-sf-gray-extra-light rounded-b-lg pt-1 hover:bg-sf-gray-light" onClick={props.moveDown}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6" viewBox="0 0 24 24"><path fill="currentColor" d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062Z" /></svg>
                </button>
            </div>
            <div className="flex w-full flex-col space-y-2">
                {props.item.operation_detail_id ?
                    <div className="truncate">{operationDetails.find(operationDetail => operationDetail.id === props.item.operation_detail_id)?.title}</div>
                    :
                    <div className="truncate">{financialElements.find(financialElement => financialElement.id === props.item.financial_element_id)?.title}</div>
                }
                {editAmount ?
                    <div className="flex">
                        <input type='text' ref={inputRef} inputMode='decimal' name='amount' value={newAmount} onChange={e => processDecimalInput(e) && setNewAmount(e.target.value)} className='field px-2 py-1 mr-4 w-full' />
                        <div className="flex h-fit self-center space-x-4">
                            <button className="cursor-pointer" onClick={() => { props.saveItem({ ...props.item, amount: isExpenditure ? `-${newAmount}` : newAmount }); setEditAmount(oldEditAmount => !oldEditAmount); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 text-sf-violet-dark" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10" /><path strokeLinecap="round" strokeLinejoin="round" d="m8.5 12.5l2 2l5-5" /></g></svg>
                            </button>
                            <button className="cursor-pointer" onClick={() => setEditAmount(oldEditAmount => !oldEditAmount)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 text-sf-red-dark" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10" /><path strokeLinecap="round" d="m14.5 9.5l-5 5m0-5l5 5" /></g></svg>
                            </button>
                        </div>
                    </div>
                    :
                    <div className="flex justify-between items-center space-x-4">
                        <div className="flex py-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg" onClick={() => setEditAmount(oldEditAmount => !oldEditAmount)}>
                            <p className="font-bold mr-1">{getSign(props.currency)}</p>
                            <p className="font-bold">{getInteger(props.item.amount ?? '')}</p>
                            <p className="font-bold text-[11px]/[16px] ml-[1px] mr-4">{getDecimal(props.item.amount ?? '')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 text-sf-violet-dark" viewBox="0 0 24 24"><g fill="currentColor"><path d="M20.849 8.713a3.932 3.932 0 0 0-5.562-5.561l-.887.887l.038.111a8.754 8.754 0 0 0 2.093 3.32a8.754 8.754 0 0 0 3.43 2.13l.888-.887Z" opacity=".5" /><path d="m14.439 4l-.039.038l.038.112a8.754 8.754 0 0 0 2.093 3.32a8.753 8.753 0 0 0 3.43 2.13l-8.56 8.56c-.578.577-.867.866-1.185 1.114a6.554 6.554 0 0 1-1.211.748c-.364.174-.751.303-1.526.561l-4.083 1.361a1.06 1.06 0 0 1-1.342-1.341l1.362-4.084c.258-.774.387-1.161.56-1.525c.205-.43.456-.836.749-1.212c.248-.318.537-.606 1.114-1.183L14.439 4Z" /></g></svg>
                        </div>
                        <div className="flex h-fit self-center space-x-4">
                            <button className="cursor-pointer" onClick={props.removeFromBudget}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 text-sf-black" viewBox="0 0 24 24"><g fill="currentColor"><path d="M3 6.386c0-.484.345-.877.771-.877h2.665c.529-.016.996-.399 1.176-.965l.03-.1l.115-.391c.07-.24.131-.45.217-.637c.338-.739.964-1.252 1.687-1.383c.184-.033.378-.033.6-.033h3.478c.223 0 .417 0 .6.033c.723.131 1.35.644 1.687 1.383c.086.187.147.396.218.637l.114.391l.03.1c.18.566.74.95 1.27.965h2.57c.427 0 .772.393.772.877s-.345.877-.771.877H3.77c-.425 0-.77-.393-.77-.877Z" /><path fillRule="evenodd" d="M9.425 11.482c.413-.044.78.273.821.707l.5 5.263c.041.433-.26.82-.671.864c-.412.043-.78-.273-.821-.707l-.5-5.263c-.041-.434.26-.821.671-.864Zm5.15 0c.412.043.713.43.671.864l-.5 5.263c-.04.434-.408.75-.82.707c-.413-.044-.713-.43-.672-.864l.5-5.264c.041-.433.409-.75.82-.707Z" clipRule="evenodd" /><path d="M11.596 22h.808c2.783 0 4.174 0 5.08-.886c.904-.886.996-2.339 1.181-5.245l.267-4.188c.1-1.577.15-2.366-.303-2.865c-.454-.5-1.22-.5-2.753-.5H8.124c-1.533 0-2.3 0-2.753.5c-.454.5-.404 1.288-.303 2.865l.267 4.188c.185 2.906.277 4.36 1.182 5.245c.905.886 2.296.886 5.079.886Z" opacity=".5" /></g></svg>
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}