import { Valuation } from '../model/Valuation';
import OperationsService from './OperationsService';
import { addMonthsToTimestamp } from './date-utils';
import { createValuation, listOperations, listValuations } from './api';
import AsyncLock from 'async-lock';
import { FinancialElement } from '../model/FinancialElement';
import { FinancialElementTypes } from '../consts/FinancialElementTypes';

const MAX_ITERATIONS = 11;
const ASYNC_LOCK_KEY = 'CurrentValuesService.getCurrentValuesByCurrency';

class CurrentValuesService {

    private asyncLock: AsyncLock = new AsyncLock();

    getValues = (clientId: string, financialElements: FinancialElement[], timestamps: string[]): Promise<Valuation[]> => this.asyncLock.acquire(ASYNC_LOCK_KEY, async () => {
        const currentValues: Valuation[] = [];
        const allValuations = await listValuations(clientId);
        for (const financialElement of financialElements) {
            for (const timestamp of timestamps) {
                const pastValuations = allValuations.filter(valuation => (valuation.timestamp ?? '') <= timestamp && valuation.financial_element_id === financialElement.id);
                const recentOperations = (await OperationsService.listOperationsByRange(clientId, addMonthsToTimestamp(timestamp, -3), timestamp)).filter(operation => operation.financial_element_id === financialElement.id);
                const currencyCodes = Array.from(new Set([...pastValuations, ...recentOperations].map(el => el.currency_code ?? '')));
                for (const currencyCode of currencyCodes) {
                    const currencyValuations = allValuations.filter(valuation => (valuation.timestamp ?? '') <= timestamp && valuation.financial_element_id === financialElement.id && valuation.currency_code === currencyCode);
                    let lastValuation: Valuation;
                    if (currencyValuations.length > 0) {
                        lastValuation = currencyValuations.reduce((acc, valuation) => (valuation.timestamp ?? '') > (acc.timestamp ?? '') || ((valuation.timestamp ?? '') === (acc.timestamp ?? '') && (valuation.hour ?? '') > (acc.hour ?? '')) ? valuation : acc);
                    } else {
                        const alloperations = await listOperations(clientId, { currency_code: currencyCode, financial_element_id: financialElement.id });
                        if (alloperations.length === 0) {
                            continue;
                        }
                        const firstOperation = alloperations.reduce((acc, operation) => (operation.timestamp ?? '') < (acc.timestamp ?? '') || ((operation.timestamp ?? '') === (acc.timestamp ?? '') && (operation.hour ?? '') < (acc.hour ?? '')) ? operation : acc,);
                        if ((firstOperation.timestamp ?? '') > timestamp) {
                            continue;
                        }
                        console.warn(`CREATE VALUATION\n    cuenta: ${financialElement.title}\n    fecha: ${new Date(parseInt(firstOperation.timestamp ?? '')).toISOString().substring(0, 10)}\n    moneda: ${currencyCode}\n    monto: ${financialElement.type === FinancialElementTypes.DEBT.key ? '-0' : '0'}`);
                        lastValuation = await createValuation(clientId, {
                            financial_element_id: financialElement.id,
                            currency_code: currencyCode,
                            amount: financialElement.type === FinancialElementTypes.DEBT.key ? '-0' : '0',
                            timestamp: firstOperation.timestamp,
                            hour: (parseFloat(firstOperation.hour ?? '') - 1).toString()
                        });
                        allValuations.push(lastValuation);
                    }
                    const pastOperations = (await OperationsService.listOperationsByRange(clientId, lastValuation.timestamp ?? '', timestamp)).filter(operation => operation.financial_element_id === financialElement.id && operation.currency_code === currencyCode && ((operation.timestamp ?? '') > (lastValuation.timestamp ?? '') || ((operation.timestamp ?? '') === (lastValuation.timestamp ?? '') && (operation.hour ?? '') > (lastValuation.hour ?? ''))));
                    const amount = pastOperations.reduce((acc, operation) => acc + parseFloat(operation.amount ?? '0'), parseFloat(lastValuation.amount ?? '0'));
                    for (let t = timestamp, i = 0; t > addMonthsToTimestamp(lastValuation.timestamp ?? '', 3) && i <= MAX_ITERATIONS; i++) {
                        if (i === MAX_ITERATIONS) {
                            console.error('MAX_ITERATIONS reached in getCurrentValuesByCurrency');
                        }
                        t = addMonthsToTimestamp(t, -2);
                        const pastOperationsSubset = pastOperations.filter(operation => (operation.timestamp ?? '') <= t && ((operation.timestamp ?? '') > (lastValuation.timestamp ?? '') || ((operation.timestamp ?? '') === (lastValuation.timestamp ?? '') && (operation.hour ?? '') > (lastValuation.hour ?? ''))));
                        const subsetAmount = pastOperationsSubset.reduce((acc, operation) => acc + parseFloat(operation.amount ?? '0'), parseFloat(lastValuation.amount ?? '0'));
                        let lastOperationHour: string;
                        if (pastOperationsSubset.length > 0) {
                            lastOperationHour = pastOperationsSubset.reduce((acc, operation) => (operation.timestamp ?? '') > (acc.timestamp ?? '') || ((operation.timestamp ?? '') === (acc.timestamp ?? '') && (operation.hour ?? '') > (acc.hour ?? '')) ? operation : acc).hour ?? '';
                        } else {
                            lastOperationHour = '0';
                        }
                        console.warn(`CREATE VALUATION\n    cuenta: ${financialElement.title}\n    fecha: ${new Date(parseInt(t)).toISOString().substring(0, 10)}\n    moneda: ${currencyCode}\n    monto: ${subsetAmount.toString()}`);
                        const newValuation = await createValuation(clientId, {
                            financial_element_id: financialElement.id,
                            currency_code: currencyCode,
                            amount: subsetAmount.toString(),
                            timestamp: t,
                            hour: (parseFloat(lastOperationHour) + 1).toString()
                        });
                        allValuations.push(newValuation);
                    }
                    currentValues.push({
                        financial_element_id: financialElement.id,
                        timestamp: timestamp,
                        currency_code: currencyCode,
                        amount: amount.toString()
                    });
                }
            }
        }
        return currentValues;
    });
}

const currentValuesService = new CurrentValuesService();
export default currentValuesService;
