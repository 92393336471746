import { useContext, useEffect, useState } from 'react';
import OperationItem from './OperationItem';
import { Context } from '../utils/context';
import { Operation } from '../model/Operation';
import { useNavigate } from 'react-router-dom';
import EmptyList from './EmptyList';
import { sortElements } from '../utils/sort-elements';
import { addDaysToTimestamp, addMonthsToTimestamp } from '../utils/date-utils';
import OperationsService from '../utils/OperationsService';

export default function LastOperations(): JSX.Element {
    const { client } = useContext(Context);
    const navigate = useNavigate();
    const [operations, setOperations] = useState<Operation[]>([]);

    useEffect(() => {
        const fetchOperations = () => {
            if (!client.id) return;

            const today = new Date();
            const UTCtoday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()).toString();
            const from = addMonthsToTimestamp(UTCtoday, -3);
            const to = addDaysToTimestamp(UTCtoday, 1);

            OperationsService.listOperationsByRange(client.id, from, to).then(setOperations);
        };

        fetchOperations();

        return OperationsService.subscribe(fetchOperations);
    }, [client.id]);

    const operationsToDisplay = operations.filter(operation => (operation?.operation_detail_id ?? '') !== '');
    sortElements(operationsToDisplay, [
        { field: 'timestamp', asc: false },
        { field: 'update_timestamp', asc: false },
    ]);

    return (
        <article className='pb-0 h-fit'>
            <div className='flex justify-between items-center'>
                <h2>Últimos movimientos</h2>
                <button className='button-aux' onClick={() => { navigate('/operations') }}>
                    <div>Ver todos</div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 mt-0.5 h-2.5 w-2.5" viewBox="0 0 24 24"><path fill="currentColor" d="m14.475 12l-7.35-7.35q-.375-.375-.363-.888t.388-.887q.375-.375.888-.375t.887.375l7.675 7.7q.3.3.45.675t.15.75q0 .375-.15.75t-.45.675l-7.7 7.7q-.375.375-.875.363T7.15 21.1q-.375-.375-.375-.888t.375-.887L14.475 12Z" /></svg>
                </button>
            </div>
            <ul className="divide-y divide-black border-t border-black">{
                operationsToDisplay.length ? operationsToDisplay.slice(0, 6).map((operation: Operation) => <OperationItem key={operation.id} operation={operation} />)
                    : <EmptyList message="Aún no se cargaron movimientos." />}</ul>
        </article>
    );
}
