export interface OperationRow {
    readonly 'index': number;
    readonly 'Fecha': string;
    readonly 'Tipo': string;
    readonly 'Monto': string;
    readonly 'Moneda': string;
    readonly 'Categoría de movimiento': string;
    readonly 'Cuenta': string;
    readonly 'Descripción': string;
    readonly 'Referencia de transacción': string;
}

export const defaultOperationRow: OperationRow = {
    'index': 0,
    'Fecha': '',
    'Tipo': '',
    'Monto': '',
    'Moneda': '',
    'Categoría de movimiento': '',
    'Cuenta': '',
    'Descripción': '',
    'Referencia de transacción': ''
};