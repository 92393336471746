import { SyntheticEvent, useContext, useEffect, useState } from "react"
import { Budget, Item, defaultBudget } from "../model/Budget";
import { createBudget, listBudgets } from "../utils/api";
import { Context } from "../utils/context";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import Select from "../components/Select";
import { addDaysToTimestamp, addMonthsToTimestamp, ifDateIsValidThen } from "../utils/date-utils";

export default function NewPlan(): JSX.Element {
    const { client, setConfirmDialog } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [isFirstBudget, setIsFirstBudget] = useState(false);
    const [isDangerousTimestampTo, setIsDangerousTimestampTo] = useState(false);
    const [budget, setBudget] = useState<Budget>({ ...defaultBudget });
    const { getSign } = useCurrencyByCode();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        async function setUp() {
            try {
                setLoading(true);
                const result = await listBudgets(client.id ?? '');
                const today = new Date();
                let timestamp_from = Date.UTC(today.getFullYear(), today.getMonth()).toString();
                let items: Item[] = [];
                let budgetCurrency = client.currencies ? client.currencies[0]?.code ?? '' : '';
                if (result.length > 0) {
                    const lastBudget = result[result.length - 1];
                    items = lastBudget.items ?? items;
                    timestamp_from = lastBudget.timestamp_to ?? timestamp_from;
                    budgetCurrency = lastBudget.currency ?? budgetCurrency;
                }
                const timestamp_to = addDaysToTimestamp(addMonthsToTimestamp(timestamp_from, 1), -1);

                setIsFirstBudget(result.length === 0);
                setBudget({
                    ...defaultBudget,
                    items: items,
                    currency: budgetCurrency,
                    timestamp_from: new Date(parseInt(timestamp_from)).toISOString().substring(0, 10),
                    timestamp_to: new Date(parseInt(timestamp_to)).toISOString().substring(0, 10),
                });
            } finally {
                setLoading(false);
            }
        }
        setUp();
    }, [client.currencies, client.id]);

    useEffect(() => {
        if (!budget.timestamp_to) {
            return;
        }

        const timestampToDisplayed = new Date(budget.timestamp_to).getTime().toString();
        const timestampToReal = new Date(parseInt(addDaysToTimestamp(timestampToDisplayed, 1)));
        const day = timestampToReal.toISOString().substring(8, 10);

        setIsDangerousTimestampTo(day > '28');
    }, [budget.timestamp_to]);

    if (loading) return <Loading />

    function handleBudgetChange(event: SyntheticEvent): void {
        const target = event.target as HTMLInputElement;
        setBudget(oldBudget => ({
            ...oldBudget,
            [target.name]: target.value
        }));
    }

    const returnToPlan = () => navigate(`/plan?${searchParams}`);

    async function confirmCancelDialog(): Promise<void> {
        returnToPlan();
    }

    async function saveBudget() {
        setLoading(true);

        const timestampFrom = new Date(budget.timestamp_from ?? '').getTime().toString();
        const timestampTo = addDaysToTimestamp(new Date(budget.timestamp_to ?? '').getTime().toString(), 1);

        setSearchParams(oldSearchParams => {
            oldSearchParams.set('budget_timestamp', timestampTo);
            return oldSearchParams;
        });

        await createBudget(client.id ?? '', {
            ...budget,
            timestamp_from: timestampFrom,
            timestamp_to: timestampTo,
        });
        returnToPlan();
    }

    const confirmCancelDialogParams = {
        callback: confirmCancelDialog,
        title: '¿Estás seguro que deseas salir?',
        confirmButton: 'Salir',
        cancelButton: 'Seguir editando'
    };

    return (
        <main>
            <article>
                <div className="flex justify-between">
                    <h2>Nuevo plan</h2>
                </div>
                {isDangerousTimestampTo && <p className="w-full flex justify-center bg-sf-red-light text-sf-red-dark p-3 rounded-lg font-bold"> No es recomendable usar esta Fecha de finalización.</p>}
                <div className="flex min-w-full justify-between items-center pb-2 space-x-4">
                    <div className="w-full">
                        <p className={`pb-1 ${!isFirstBudget && 'text-sf-gray-dark'}`}>Fecha de inicio:</p>
                        <input name='timestamp_from' type='date' disabled={!isFirstBudget} max={budget.timestamp_to} value={budget.timestamp_from} onChange={ifDateIsValidThen(handleBudgetChange)} className={`field ring-1 ${!isFirstBudget ? 'ring-sf-gray-dark text-sf-gray-dark' : 'ring-sf-violet-dark'}`} />
                    </div>
                    <div className="w-full">
                        <p className='pb-1'>Fecha de finalización:</p>
                        <input name='timestamp_to' type='date' min={budget.timestamp_from} value={budget.timestamp_to} onChange={ifDateIsValidThen(handleBudgetChange)} className='field ring-1 ring-sf-violet-dark' />
                    </div>
                </div>
                {isFirstBudget && <article className="bg-sf-violet-light">
                    <h2>Moneda del plan</h2>
                    <Select placeholder='Moneda' name='currency' value={budget.currency} onChange={handleBudgetChange} options={client.currencies?.map(currency => ({ value: currency.code, alias: getSign(currency.code) }))} />
                </article>}
                <div className='self-end flex w-full justify-between'>
                    <button className="button-secondary-red px-4" onClick={() => setConfirmDialog(confirmCancelDialogParams)}>Cancelar</button>
                    <button className="button-primary" disabled={budget.currency === ''} onClick={saveBudget}>Guardar</button>
                </div>
            </article>
        </main>
    )
}
