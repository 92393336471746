export interface FinancialElement {
    readonly 'title'?: string;
    readonly 'transactional'?: boolean;
    readonly 'description'?: string;
    readonly 'minimum_payment'?: string;
    readonly 'priority'?: string;
    readonly 'interest_rate'?: string;
    readonly 'status'?: string;
    readonly 'type'?: string;
    readonly 'deferred'?: string;
    readonly 'id'?: string;
    readonly 'creation_timestamp'?: string;
    readonly 'update_timestamp'?: string;
};

export const defaultFinancialElement: FinancialElement = {
    'title': '',
    'transactional': false,
    'description': '',
    'minimum_payment': '',
    'priority': '',
    'interest_rate': '',
    'status': '',
    'type': '',
    'deferred': '',
    'id': '',
    'update_timestamp': '',
};
