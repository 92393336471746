import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { Amplify } from "aws-amplify";
import amplifyConfig from './amplify-config';

const isLocalhost = Boolean(window.location.hostname === "localhost" || window.location.hostname === "[::1]" || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
const [localRedirectSignIn, productionRedirectSignIn] = amplifyConfig.oauth.redirectSignIn.split(",");
const [localRedirectSignOut, productionRedirectSignOut] = amplifyConfig.oauth.redirectSignOut.split(",");

const finalConfig = {
  ...amplifyConfig,
  oauth: {
    ...amplifyConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

console.log(finalConfig);

Amplify.configure(finalConfig);

const root: ReactDOM.Root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
