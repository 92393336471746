import { getDecimal, getInteger } from "../../utils/number-utils";

interface SubtotalProps {
    title: string,
    currency: string,
    operationsAmounts: string[],
    itemsAmounts: string[]
}

export default function Subtotal(props: SubtotalProps): JSX.Element {
    const currentAmount = props.operationsAmounts.map(parseFloat).reduce((p, c) => p + c, 0).toString();
    const plannedAmount = props.itemsAmounts.map(parseFloat).reduce((p, c) => p + c, 0).toString();

    return (
        <div className='font-bold flex w-full items-center'>
            <p className='pl-1.5 w-[40%]'>{props.title}</p>
            <div className='w-[27%] flex'>
                <p>Actual:</p>
                <p className="ml-1 font-mono">{currentAmount.includes('-') ? '-' : '+'}</p>
                <p className='mx-1'>{props.currency}</p>
                <p>{getInteger(currentAmount)}</p>
                <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(currentAmount)}</p>
            </div>
            <div className='w-[27%] flex'>
                <p>Plan:</p>
                <p className="ml-1 font-mono">{plannedAmount.includes('-') ? '-' : '+'}</p>
                <p className='mx-1'>{props.currency}</p>
                <p>{getInteger(plannedAmount)}</p>
                <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(plannedAmount)}</p>
            </div>
            <div className="h-8 w-[6%]" />
        </div>
    );
}
