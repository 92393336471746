export interface ExchangeRate {
    readonly 'code'?: string;
    readonly 'timestamp'?: string;
    readonly 'rate'?: string;
    readonly 'creation_timestamp'?: string;
    readonly 'update_timestamp'?: string;
};

export const defaultExchangeRate: ExchangeRate = {
    'code': '',
    'timestamp': '',
    'rate': '',
    'creation_timestamp': '',
    'update_timestamp': '',
};
