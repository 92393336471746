import { Goal, defaultGoal } from '../model/Goal';
import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../utils/context';
import { useNavigate, useParams } from 'react-router-dom';
import { getGoal, updateGoal, updateOperationDetail } from '../utils/api';
import Loading from '../components/Loading';
import GoalStatus from '../components/GoalStatus';
import { Currency } from '../model/Currency';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import Select from '../components/Select';
import { processDecimalInput } from '../utils/process-decimal-input';

export default function GoalFull(): JSX.Element {
    const { client, operationDetails, setOperationDetails, setConfirmDialog } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const { goal_id } = useParams();
    const [goal, setGoal] = useState({ ...defaultGoal });
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();

    const setUp = useCallback(async () => {
        try {
            setLoading(true);
            if (!goal_id) {
                navigate(-1);
                return;
            }
            const results = await getGoal(client.id ?? '', goal_id);

            setGoal({ ...results });
        } finally {
            setLoading(false);
        }
    }, [goal_id, navigate, client.id]);

    useEffect(() => { setUp() }, [setUp]);

    const currencies = [goal.currency].concat(
        client.currencies?.map((currency: Currency) => currency.code ?? '').filter((currency: string) => currency !== goal.currency)
    );

    function handleInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setGoal(oldGoal => ({
            ...oldGoal,
            [target.name]: target.value
        }));
    }

    async function saveChanges(goal: Goal) {
        try {
            setLoading(true);
            const operationDetail = operationDetails.find(od => od.goal_id === goal.goal_id);
            let newOperationDetailPromise;
            if (operationDetail === undefined) {
                // newOperationDetailPromise = createOperationDetail(client.id ?? '', {
                //     ...defaultOperationDetail,
                //     title: goal.title,
                //     description: goal.description,
                //     goal_id: goal.goal_id,
                //     type: OperationTypes.EXPENDITURE.key,
                //     status: goal.status === 'pending' ? 'ENABLED' : 'DISABLED'
                // }).then(newOperationDetail =>
                //     setOperationDetails(oldOperationDetails => [...oldOperationDetails, newOperationDetail]));
                newOperationDetailPromise = Promise.resolve();
            } else {
                const newOperationDetail = {
                    ...operationDetail,
                    title: goal.title,
                    description: goal.description,
                    status: goal.status === 'pending' ? 'ENABLED' : 'DISABLED'
                }
                newOperationDetailPromise = updateOperationDetail(client.id ?? '', operationDetail.id ?? '', newOperationDetail).then(() =>
                    setOperationDetails(oldOperationDetails => oldOperationDetails.map(od => od.id === newOperationDetail.id ? newOperationDetail : od)));
            }
            await Promise.all([
                updateGoal(client.id ?? '', goal.goal_id ?? '', goal),
                newOperationDetailPromise
            ]);
        } finally {
            navigate(-1);
            setLoading(false);
        }
    }

    async function confirmDisableGoal() {
        await saveChanges({ ...goal, status: 'cancelled' });
    }

    async function confirmEnableGoal() {
        await saveChanges({ ...goal, status: 'pending' });
    }

    const confirmDisableDialogParams = {
        callback: confirmDisableGoal,
        title: '¿Estás seguro que deseas deshabilitar este objetivo?',
        confirmButton: 'Deshabilitar',
        cancelButton: 'Cancelar'
    };

    const confirmEnableDialogParams = {
        callback: confirmEnableGoal,
        title: '¿Estás seguro que deseas habiltiar este objetivo?',
        confirmButton: 'Habilitar',
        cancelButton: 'Cancelar'
    };

    if (loading) return <Loading />

    return (
        <main>
            <article className='bg-sf-green-light'>
                <h2>Editar objetivo</h2>
                <div className='border-t border-sf-black w-full h-min'></div>
                <div className='flex justify-between items-center w-full'>
                    <button className=" text-xs button-secondary border border-sf-violet-dark text-sf-violet-dark min-w-fit" onClick={() => { setGoal(oldGoal => ({ ...oldGoal, status: oldGoal.status === 'done' ? 'pending' : 'done' })) }}>{goal.status === 'done' ? 'Volver a Pendiente' : 'Pasar a Cumplido'}</button>
                    <GoalStatus status={goal.status ?? ''} />
                </div>
                <div className='flex space-x-4'>
                    <input name='title' type='text' placeholder='Objetivo *' value={goal.title} onChange={handleInputChange} className='field' />
                </div>
                <div className='flex space-x-3'>
                    <div className='w-48'>
                        <Select placeholder='Moneda' name='currency' value={goal.currency} onChange={handleInputChange} options={currencies.map(currency => ({ value: currency, alias: getSign(currency) }))} />
                    </div>
                    <input type='text' inputMode='decimal' name='amount' placeholder='Monto *' value={goal.amount} onChange={e => processDecimalInput(e) && handleInputChange(e)} className='field' />
                </div>
                <input name='term' type='text' placeholder='Plazo *' value={goal.term} onChange={handleInputChange} className='field' />
                <input name='description' type='text' placeholder='Descripción (opcional)' value={goal.description} onChange={handleInputChange} className='field' />
                <div className='self-end flex space-x-2 w-full'>
                    <div className='w-full min-h-full'>
                        {(goal.status === 'pending' || goal.status === 'done') ?
                            <button className="h-full button-secondary-red" onClick={() => setConfirmDialog(confirmDisableDialogParams)}>
                                Deshabilitar
                            </button>
                            :
                            <button className="h-full button-secondary" onClick={() => setConfirmDialog(confirmEnableDialogParams)}>
                                Habilitar
                            </button>
                        }
                    </div>
                    <button className="button-secondary" onClick={() => navigate(-1)}>
                        Cancelar
                    </button>
                    <button className="button-primary" disabled={!goal.title || !goal.currency || !goal.amount || !goal.status || !goal.term} onClick={() => saveChanges(goal)}>
                        Guardar
                    </button>
                </div>
            </article>
        </main >
    )
}
