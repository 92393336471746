import { ChangeEvent, ChangeEventHandler } from "react";

const MILLISECONDS_IN_A_SECOND = 1000;
const MILLISECONDS_IN_A_MINUTE = 60 * MILLISECONDS_IN_A_SECOND;
const MILLISECONDS_IN_AN_HOUR = 60 * MILLISECONDS_IN_A_MINUTE;
const MILLISECONDS_IN_A_DAY = 24 * MILLISECONDS_IN_AN_HOUR;

export function ifDateIsValidThen(changeEventHandler: ChangeEventHandler<HTMLInputElement>): ChangeEventHandler<HTMLInputElement> {
    return (event: ChangeEvent<HTMLInputElement>) => {
        const date = new Date(event.target.value);
        if (date instanceof Date && !isNaN(date.getTime())) {
            changeEventHandler(event);
        }
    };
}

export function getHourInMilis(date?: Date): number {
    const dateOrDefault = date ?? new Date();

    return dateOrDefault.getTime() % MILLISECONDS_IN_A_DAY;
}

export function getFormattedLocaleDate(date?: Date): string {
    const dateOrDefault = date ?? new Date();

    return new Date(dateOrDefault.getTime() - (dateOrDefault.getTimezoneOffset() * MILLISECONDS_IN_A_MINUTE)).toISOString().split('T')[0];
}

export function addDaysToTimestamp(date: string, n: number): string {
    const parseDate = new Date(parseInt(date));

    return Date.UTC(parseDate.getUTCFullYear(), parseDate.getUTCMonth(), parseDate.getUTCDate() + n).toString();
}

export function addMonthsToTimestamp(date: string, n: number): string {
    // Suppose the provided string is the timestamp of 2023-10-01T00:00:00.000Z (which is 1696118400000)

    // Parse provided date.
    // 1696118400000 -> Sat Sep 30 2023 21:00:00 GMT-0300 (Argentina Standard Time)
    const parseDate = new Date(parseInt(date));

    // Trick the date to have the correct date at midnight, no matter the timezone.
    // Sat Sep 30 2023 21:00:00 GMT-0300 (Argentina Standard Time) -> Sun Oct 01 2023 00:00:00 GMT-0300 (Argentina Standard Time)
    const shiftedDate = new Date(parseDate.toUTCString().slice(0, -4));

    // Go to next month.
    // Sun Oct 01 2023 00:00:00 GMT-0300 (Argentina Standard Time) -> Wed Nov 01 2023 00:00:00 GMT-0300 (Argentina Standard Time)
    shiftedDate.setMonth(shiftedDate.getMonth() + n);

    // Apply the inverse trick to restore the original hour and timezone.
    // Wed Nov 01 2023 00:00:00 GMT-0300 (Argentina Standard Time) -> Wed Nov 01 2023 00:00:00 GMT
    const recomposeDateTimestamp = Date.UTC(shiftedDate.getFullYear(), shiftedDate.getMonth(), shiftedDate.getDate());

    // console.log('Input date: ' + new Date(parseInt(date)).toISOString());
    // console.log('Output date: ' + new Date(recomposeDateTimestamp).toISOString());

    // Convert to string and return.
    // Wed Nov 01 2023 00:00:00 GMT -> 1698807600000 (this is the timestamp of 2023-11-01T00:00:00.000Z)
    return recomposeDateTimestamp.toString();
}

export function parseDMY(dateString: string): string {
    const [d, m, y] = dateString.split(/\D/).map(num => parseInt(num));

    return Date.UTC(y, m - 1, d).toString();
}

export function isValidTimestamp(timestamp: string): boolean {
    if (!/^\d{13}$/.test(timestamp)) return false;
    const timestampNumber = Number(timestamp);
    return 1000000000000 < timestampNumber && timestampNumber < 4000000000000;
}
