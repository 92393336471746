import { OperationTypes } from "../consts/OperationTypes";
import { useOperationRowErrors } from "../hooks/UseOperationRowErrors";
import { OperationRow } from "../model/OperationRow";
import { getDecimal, getInteger } from "../utils/number-utils";

interface ConfirmOperationRowProps {
    operationRow: OperationRow;
}

export default function ConfirmOperationRow(props: ConfirmOperationRowProps) {
    const { getErrors } = useOperationRowErrors();
    const operationRow = props.operationRow;
    const errors = getErrors(operationRow);

    return (
        <div>
            <div className='flex justify-between'>
                <div className='max-w-[62%]'>
                    {operationRow['Categoría de movimiento'] === '' ?
                        <p>{operationRow['Descripción']}</p>
                        :
                        <div className='flex space-x-1'>
                            <h3 className='whitespace-nowrap'>{operationRow['Categoría de movimiento']}</h3>
                            {operationRow['Descripción'] && <p className="secondary font-bold">({operationRow['Descripción']})</p>}
                        </div>
                    }
                    <p className='text-xs truncate'>{operationRow['Cuenta']}</p>
                </div>
                <div className='flex flex-col'>
                    <div className={`flex self-end ${operationRow['Tipo'].toLowerCase() === OperationTypes.EXPENDITURE.label.toLowerCase() ? 'expenditure' : 'income'}`}>
                        <p>{operationRow['Tipo'].toLowerCase() === OperationTypes.EXPENDITURE.label.toLowerCase() ? '-' : '+'}</p>
                        <p className="mx-1">{operationRow['Moneda']}</p>
                        <p>{getInteger(operationRow['Monto'])}</p>
                        <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(operationRow['Monto'])}</p>
                    </div>
                    <small className="self-end">{operationRow['Fecha']}</small>
                </div>
            </div>
            {errors.length > 0 &&
                <div className="p-2 my-1 bg-sf-red-light rounded-md flex flex-col">
                    <h3>Errores encontrados</h3>
                    {errors.map((error, index) => <small key={index}>{`\u2022 ${error}`}</small>)}
                </div>
            }
        </div>
    );
}