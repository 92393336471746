import { useContext, useEffect, useState } from 'react';
import { listBudgets, listExchangeRates } from '../utils/api';
import { Context } from '../utils/context';
import { Budget, Item } from '../model/Budget';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import { ExchangeRate, defaultExchangeRate } from '../model/ExchangeRate';
import Balance from '../components/Balance';
import BudgetItem from '../components/BudgetItem';
import { OperationTypes } from '../consts/OperationTypes';
import SelectBlack from '../components/SelectBlack';
import { addDaysToTimestamp } from '../utils/date-utils';
import { getInteger, getDecimal } from '../utils/number-utils';
import { Operation } from '../model/Operation';
import OperationsService from '../utils/OperationsService';

interface GroupedOperations {
    name: string;
    sum: number;
}

function groupBy(items: Item[], key: 'operation_detail_id' | 'financial_element_id'): GroupedOperations[] {
    const map = items.reduce(
        (acc, item) => acc.set(item[key] ?? '', (acc.get(item[key] ?? '') ?? 0) + parseFloat(item.amount ?? '0')),
        new Map<string, number>()
    );

    return Array.from(map, ([name, sum]) => ({ name, sum }));
}

export default function Budgets(): JSX.Element {
    const { client, financialElements, operationDetails } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [loadingExchangeRates, setLoadingExchangeRates] = useState(true);
    const [budgets, setBudgets] = useState<Budget[]>([]);
    const [exchangeRates, setExchangeRates] = useState<ExchangeRate[]>([]);
    const [selectedBudgetIndex, setSelectedBudgetIndex] = useState<number>(0);
    const [budgetsRetrieved, setBudgetsRetrieved] = useState<boolean>(false);
    const [hideSpendingDetails, setHideSpendingDetails] = useState<boolean>(false);
    const [hideIncomeDetails, setHideIncomeDetails] = useState<boolean>(false);
    const [operations, setOperations] = useState<Operation[]>([]);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getSign } = useCurrencyByCode();

    useEffect(() => {
        async function setUp() {
            try {
                setLoading(true);
                const result = await listBudgets(client.id ?? '');
                setBudgets(result);
                const budgetTimestamp = searchParams.get('budget_timestamp') ?? (new Date()).getTime().toString();
                let currentBudgetIndex = result.findIndex(budget => (budget.timestamp_from ?? '') < budgetTimestamp && budgetTimestamp <= (budget.timestamp_to ?? ''));
                if (currentBudgetIndex < 0) {
                    currentBudgetIndex = result.length - 1;
                }
                setSelectedBudgetIndex(currentBudgetIndex);
            } finally {
                setLoading(false);
                setBudgetsRetrieved(true);
            }
        }
        setUp();
    }, [client.id, searchParams]);

    useEffect(() => {
        const fetchOperations = () => {
            if (!client.id || budgets.length === 0) return;

            const budget = budgets[selectedBudgetIndex];

            if (!budget.timestamp_from || !budget.timestamp_to) return;

            const from = budget.timestamp_from;
            const to = budget.timestamp_to;

            OperationsService.listOperationsByRange(client.id, from, to).then(setOperations);
        };

        fetchOperations();

        return OperationsService.subscribe(fetchOperations);
    }, [client.id, budgets, budgetsRetrieved, selectedBudgetIndex]);

    useEffect(() => {
        if (budgets.length === 0) {
            if (budgetsRetrieved) {
                setLoadingExchangeRates(false);
            }
            return;
        }

        const budget = budgets[selectedBudgetIndex];

        async function getExchangeRates() {
            try {
                setLoadingExchangeRates(true);
                const usedCurrencies = operations?.filter(operation => (operation?.operation_detail_id ?? '') !== '').filter(operation => {
                    if (budget?.timestamp_from === undefined || budget?.timestamp_to === undefined || operation.timestamp === undefined) return 0;

                    return operation.timestamp >= budget.timestamp_from && operation.timestamp < budget.timestamp_to;
                }).map(operation => operation.currency_code ?? '');
                usedCurrencies.push(budget.currency ?? '');
                const codes = Array.from(new Set(usedCurrencies));
                if (codes.length === 0) return;
                const exchangeRates = await listExchangeRates(codes, {
                    from: budget.timestamp_from,
                    to: budget.timestamp_to,
                });
                setExchangeRates(oldExchangeRates => [...oldExchangeRates, ...exchangeRates]);
            } finally {
                setLoadingExchangeRates(false);
            }
        }

        getExchangeRates();
    }, [budgets, budgetsRetrieved, selectedBudgetIndex, operations]);

    if (loading || loadingExchangeRates) return <Loading />

    if (budgets.length === 0) return (
        <main>
            <article>
                <div className="items-center text-center min-w-full text-sf-gray-dark p-4">No se cargó ningún presupuesto</div>
                <div className='min-w-full flex justify-center'>
                    <button className='button-primary' onClick={() => navigate('/budgets/new')}>Crear presupuesto</button>
                </div>
            </article>
        </main>
    );

    const missingExchangeRates: ExchangeRate[] = [];

    function getExchangeRate(code: string, timestamp: string): number {
        let selectedExchangeRate = { ...defaultExchangeRate };
        exchangeRates.filter(exchangeRate => (exchangeRate.code === code) && ((exchangeRate.timestamp ?? '') <= timestamp))
            .forEach(exchangeRate => {
                if ((exchangeRate.timestamp ?? '') > (selectedExchangeRate.timestamp ?? '')) {
                    selectedExchangeRate = exchangeRate;
                }
            });

        let exchangeRate = selectedExchangeRate.rate;
        if (exchangeRate === undefined || exchangeRate === '') {
            exchangeRate = '1';
            if (code !== 'USD') {
                missingExchangeRates.push({ code, timestamp });
            }
        }

        return parseFloat(exchangeRate);
    }

    const budget = budgets[selectedBudgetIndex];

    const filteredOperations = operations?.filter(operation => (operation?.operation_detail_id ?? '') !== '').filter(operation => {
        if (budget?.timestamp_from === undefined || budget?.timestamp_to === undefined || operation.timestamp === undefined) return 0;

        return (operation?.operation_detail_id ?? '') !== '' && operation.timestamp >= budget.timestamp_from && operation.timestamp < budget.timestamp_to;
    }).map(operation => {
        const parsedAmount = parseFloat(operation.amount ?? '');
        const budgetExchangeRate = getExchangeRate(budget.currency ?? '', operation.timestamp ?? '');
        const operationExchangeRate = getExchangeRate(operation.currency_code ?? '', operation.timestamp ?? '');

        return {
            ...operation,
            currency: budget.currency,
            amount: (parsedAmount * budgetExchangeRate / operationExchangeRate).toString()
        }
    });

    const incomeFilteredOperations = filteredOperations.filter(operation => !operation.amount.includes('-'));
    const spendingFilteredOperations = filteredOperations.filter(operation => !!operation.amount.includes('-'));

    const budgetIncome = budget.items?.filter(operation =>
        !operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') !== ''
    ) ?? [];

    const budgetSpending = budget.items?.filter(operation =>
        !!operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') !== ''
    ) ?? [];

    const expenditureGrouping = groupBy(spendingFilteredOperations, 'financial_element_id');

    const totalSpending = spendingFilteredOperations.map(operation => parseFloat(operation.amount ?? ''))
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    const totalIncome = incomeFilteredOperations.map(operation => parseFloat(operation.amount ?? ''))
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    const plannedSpending = budgetSpending.map(operation => parseFloat(operation.amount ?? ''))
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    const plannedIncome = budgetIncome.map(operation => parseFloat(operation.amount ?? ''))
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    const unbudgetedIncome = groupBy(incomeFilteredOperations.filter(operation => !budgetIncome.map(m => m.operation_detail_id).includes(operation.operation_detail_id)), 'operation_detail_id');
    const unbudgetedSpending = groupBy(spendingFilteredOperations.filter(operation => !budgetSpending.map(m => m.operation_detail_id).includes(operation.operation_detail_id)), 'operation_detail_id');

    function newSearchParams(): URLSearchParams {
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.set('budget_timestamp', budget?.timestamp_to ?? '');

        return newSearchParams;
    }

    return (
        <main>
            <article className="h-fit">
                <div>
                    <div className='flex justify-between'>
                        <h2>Tablero de control</h2>
                        {missingExchangeRates.length > 0 && <div className='bg-sf-yellow-light rounded-lg p-0.5 ring-1 ring-inset ring-sf-red-medium mr-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-5 text-sf-red-medium' viewBox="0 0 256 256"><g fill="currentColor"><path d="M215.46 216H40.54c-12.62 0-20.54-13.21-14.41-23.91l87.46-151.87c6.3-11 22.52-11 28.82 0l87.46 151.87c6.13 10.7-1.79 23.91-14.41 23.91Z" opacity=".2" /><path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72Zm-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72ZM120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0Zm20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12Z" /></g></svg>
                        </div>}
                        <div className='mt-1'>
                            <p>Presupuesto</p>
                            <div className='flex justify-evenly items-center w-full'>
                                <div className='cursor-pointer hover:bg-sf-gray-extra-light rounded-lg' onClick={() => navigate(`/budgets/${budget.timestamp_to}?${newSearchParams()}`)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className='w-6 pr-0.5' viewBox="0 0 24 24"><path fill="currentColor" d="M5 19h1.4l8.625-8.625l-1.4-1.4L5 17.6V19ZM19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM4 21q-.425 0-.713-.288T3 20v-2.825q0-.2.075-.388t.225-.337l10.3-10.3l4.25 4.25l-10.3 10.3q-.15.15-.337.225T6.825 21H4ZM14.325 9.675l-.7-.7l1.4 1.4l-.7-.7Z" /></svg>
                                </div>
                                <div className='cursor-pointer hover:bg-sf-gray-extra-light rounded-lg' onClick={() => navigate(`/budgets/new?${newSearchParams()}`)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className='w-6' viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center w-full'>
                    <div className='w-10 flex justify-end items-center'>
                        {selectedBudgetIndex > 0 && <div className='cursor-pointer hover:bg-sf-gray-extra-light rounded-full w-8 h-8 flex justify-center items-center mr-1 pr-0.5' onClick={() => setSelectedBudgetIndex(o => o - 1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-5 text-sf-black' viewBox="0 0 24 24"><path fill="currentColor" d="m9.55 12l7.35 7.35q.375.375.363.875t-.388.875q-.375.375-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675t-.15-.75q0-.375.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388q.375.375.375.875t-.375.875L9.55 12Z" /></svg>
                        </div>}
                    </div>
                    <div className='w-60 ring-1 ring-sf-black rounded-lg'>
                        <SelectBlack placeholder='Periodo' value={selectedBudgetIndex.toString()} onChange={e => setSelectedBudgetIndex(parseInt((e.target as HTMLInputElement).value))} options={budgets.map((budget, index) => ({ value: index.toString(), alias: `${new Date(parseInt(budget?.timestamp_from ?? '')).toLocaleDateString('en-GB', { timeZone: 'UTC' })} - ${new Date(parseInt(addDaysToTimestamp(budget?.timestamp_to ?? '', -1))).toLocaleDateString('en-GB', { timeZone: 'UTC' })}` }))} />
                    </div>
                    <div className='w-10 flex justify-start items-center'>
                        {selectedBudgetIndex < budgets.length - 1 && <div className='cursor-pointer hover:bg-sf-gray-extra-light rounded-full w-8 h-8 flex justify-center items-center ml-1 pl-0.5' onClick={() => setSelectedBudgetIndex(o => o + 1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-5 text-sf-black' viewBox="0 0 24 24"><path fill="currentColor" d="m14.475 12l-7.35-7.35q-.375-.375-.363-.888t.388-.887q.375-.375.888-.375t.887.375l7.675 7.7q.3.3.45.675t.15.75q0 .375-.15.75t-.45.675l-7.7 7.7q-.375.375-.875.363T7.15 21.1q-.375-.375-.375-.888t.375-.887L14.475 12Z" /></svg>
                        </div>}
                    </div>
                </div>
                <div>
                    <BudgetItem
                        currentAmount={totalSpending.toString()}
                        plannedAmount={plannedSpending.toString()}
                        title={<p className='text-base'>Egreso total</p>}
                        queryParams={createSearchParams({
                            type: OperationTypes.EXPENDITURE.key,
                            timestamp_from: budget.timestamp_from ?? '',
                            timestamp_to: budget.timestamp_to ?? '',
                            budget_timestamp: budget.timestamp_to ?? '',
                        }).toString()}
                        currency={budget.currency ?? ''}
                    />
                    <div className='flex w-full justify-start space-x-1 mb-2 items-center h-fit'>
                        <p className='text-base'>Detalles:</p>
                        {hideSpendingDetails ?
                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 rounded-full hover:bg-sf-gray-extra-light cursor-pointer' onClick={() => setHideSpendingDetails(false)} viewBox="0 0 24 24"><path fill="currentColor" d="m12 13.387l-2.74-2.74q-.136-.141-.34-.141t-.349.14q-.165.146-.156.357q.01.21.156.357l2.864 2.863q.242.242.565.242q.323 0 .565-.242l2.87-2.87q.146-.145.153-.353q.006-.208-.16-.354q-.145-.14-.353-.133q-.208.006-.354.152zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924q-1.216-1.214-1.925-2.856Q3 13.87 3 12.003q0-1.866.708-3.51q.709-1.643 1.924-2.859q1.214-1.216 2.856-1.925Q10.13 3 11.997 3q1.866 0 3.51.708q1.643.709 2.859 1.924q1.216 1.214 1.925 2.856Q21 10.13 21 11.997q0 1.866-.708 3.51q-.709 1.643-1.924 2.859q-1.214 1.216-2.856 1.925Q13.87 21 12.003 21M12 20q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20m0-8" /></svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 rounded-full hover:bg-sf-gray-extra-light cursor-pointer' onClick={() => setHideSpendingDetails(true)} viewBox="0 0 24 24"><path fill="currentColor" d="m12 10.613l2.74 2.74q.136.141.34.141t.349-.14q.165-.146.156-.357q-.01-.21-.156-.357l-2.864-2.863q-.242-.242-.565-.242q-.323 0-.565.242l-2.87 2.87q-.146.145-.152.353q-.007.208.158.354q.146.14.354.134q.208-.007.354-.153zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924q-1.216-1.214-1.925-2.856Q3 13.87 3 12.003q0-1.866.708-3.51q.709-1.643 1.924-2.859q1.214-1.216 2.856-1.925Q10.13 3 11.997 3q1.866 0 3.51.708q1.643.709 2.859 1.924q1.216 1.214 1.925 2.856Q21 10.13 21 11.997q0 1.866-.708 3.51q-.709 1.643-1.924 2.859q-1.214 1.216-2.856 1.925Q13.87 21 12.003 21M12 20q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20m0-8" /></svg>
                        }
                    </div>
                    {!hideSpendingDetails &&
                        <div>
                            <ul className="divide-y divide-sf-black border-y border-sf-black">
                                {budgetSpending.length > 0 ?
                                    budgetSpending.map(item => <BudgetItem key={item.operation_detail_id}
                                        currentAmount={spendingFilteredOperations.filter(operation => operation.operation_detail_id === item.operation_detail_id)
                                            .map(operation => parseFloat(operation.amount ?? '0'))
                                            .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                                            .toString()}
                                        plannedAmount={item.amount ?? '0'}
                                        title={operationDetails.find(operationDetail => operationDetail.id === item.operation_detail_id)?.title ?? ''}
                                        queryParams={createSearchParams({
                                            type: OperationTypes.EXPENDITURE.key,
                                            operation_detail_id: item.operation_detail_id ?? '',
                                            timestamp_from: budget.timestamp_from ?? '',
                                            timestamp_to: budget.timestamp_to ?? '',
                                            budget_timestamp: budget.timestamp_to ?? '',
                                        }).toString()}
                                        currency={budget.currency ?? ''}
                                    />)
                                    : <p className='my-2'>Aún no hay conceptos presupuestados</p>

                                }
                            </ul>
                            {unbudgetedSpending.length ?
                                <div className='my-2'>
                                    <p>Egresos no presupuestados</p>
                                    {unbudgetedSpending.map(group => <div className='px-2 py-1 flex w-fit cursor-pointer hover:bg-sf-gray-extra-light' key={group.name} onClick={() => navigate("/operations?" + createSearchParams({
                                        type: OperationTypes.EXPENDITURE.key,
                                        operation_detail_id: group.name,
                                        timestamp_from: budget.timestamp_from,
                                        timestamp_to: budget.timestamp_to,
                                        budget_timestamp: budget.timestamp_to,
                                    } as Record<string, string | string[]>).toString())}>
                                        <small>{operationDetails.find(operationDetail => operationDetail.id === group.name)?.title}:</small>
                                        <small className="mx-1">{getSign(budget.currency)}</small>
                                        <small>{getInteger(group.sum.toString())}</small>
                                        <small className="text-[9px]/[12px] ml-[1px]">{getDecimal(group.sum.toString())}</small>
                                    </div>)}
                                </div>
                                : <p className='my-2'>No hay ningún egreso no presupuestado</p>
                            }
                        </div>
                    }
                </div>
                <div className='border-t border-sf-black'>
                    <BudgetItem
                        currentAmount={totalIncome.toString()}
                        plannedAmount={plannedIncome.toString()}
                        title={<p className='text-base'>Ingreso total</p>}
                        queryParams={createSearchParams({
                            type: OperationTypes.INCOME.key,
                            timestamp_from: budget.timestamp_from ?? '',
                            timestamp_to: budget.timestamp_to ?? '',
                            budget_timestamp: budget.timestamp_to ?? '',
                        }).toString()}
                        currency={budget.currency ?? ''}
                    />
                    <div className='flex w-full justify-start mb-2 space-x-1 items-center h-fit'>
                        <p className='text-base'>Detalles:</p>
                        {hideIncomeDetails ?
                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 rounded-full hover:bg-sf-gray-extra-light cursor-pointer' onClick={() => setHideIncomeDetails(false)} viewBox="0 0 24 24"><path fill="currentColor" d="m12 13.387l-2.74-2.74q-.136-.141-.34-.141t-.349.14q-.165.146-.156.357q.01.21.156.357l2.864 2.863q.242.242.565.242q.323 0 .565-.242l2.87-2.87q.146-.145.153-.353q.006-.208-.16-.354q-.145-.14-.353-.133q-.208.006-.354.152zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924q-1.216-1.214-1.925-2.856Q3 13.87 3 12.003q0-1.866.708-3.51q.709-1.643 1.924-2.859q1.214-1.216 2.856-1.925Q10.13 3 11.997 3q1.866 0 3.51.708q1.643.709 2.859 1.924q1.216 1.214 1.925 2.856Q21 10.13 21 11.997q0 1.866-.708 3.51q-.709 1.643-1.924 2.859q-1.214 1.216-2.856 1.925Q13.87 21 12.003 21M12 20q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20m0-8" /></svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 rounded-full hover:bg-sf-gray-extra-light cursor-pointer' onClick={() => setHideIncomeDetails(true)} viewBox="0 0 24 24"><path fill="currentColor" d="m12 10.613l2.74 2.74q.136.141.34.141t.349-.14q.165-.146.156-.357q-.01-.21-.156-.357l-2.864-2.863q-.242-.242-.565-.242q-.323 0-.565.242l-2.87 2.87q-.146.145-.152.353q-.007.208.158.354q.146.14.354.134q.208-.007.354-.153zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924q-1.216-1.214-1.925-2.856Q3 13.87 3 12.003q0-1.866.708-3.51q.709-1.643 1.924-2.859q1.214-1.216 2.856-1.925Q10.13 3 11.997 3q1.866 0 3.51.708q1.643.709 2.859 1.924q1.216 1.214 1.925 2.856Q21 10.13 21 11.997q0 1.866-.708 3.51q-.709 1.643-1.924 2.859q-1.214 1.216-2.856 1.925Q13.87 21 12.003 21M12 20q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20m0-8" /></svg>
                        }
                    </div>
                    {!hideIncomeDetails &&
                        <div>
                            <ul className="divide-y divide-sf-black border-y border-sf-black">
                                {budgetIncome.length > 0 ?
                                    budgetIncome.map(item => <BudgetItem key={item.operation_detail_id}
                                        currentAmount={incomeFilteredOperations.filter(operation => operation.operation_detail_id === item.operation_detail_id)
                                            .map(operation => parseFloat(operation.amount ?? '0'))
                                            .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                                            .toString()}
                                        plannedAmount={item.amount ?? '0'}
                                        title={operationDetails.find(operationDetail => operationDetail.id === item.operation_detail_id)?.title ?? ''}
                                        queryParams={createSearchParams({
                                            type: OperationTypes.INCOME.key,
                                            operation_detail_id: item.operation_detail_id ?? '',
                                            timestamp_from: budget.timestamp_from ?? '',
                                            timestamp_to: budget.timestamp_to ?? '',
                                            budget_timestamp: budget.timestamp_to ?? '',
                                        }).toString()}
                                        currency={budget.currency ?? ''}
                                    />)
                                    : <p className='my-2'>Aún no hay conceptos presupuestados</p>
                                }
                            </ul>
                            {unbudgetedIncome.length ?
                                <div className='my-2'>
                                    <p>Ingresos no presupuestados</p>
                                    {unbudgetedIncome.map(group => <div className='px-2 py-1 flex w-fit cursor-pointer hover:bg-sf-gray-extra-light' key={group.name} onClick={() => navigate("/operations?" + createSearchParams({
                                        type: OperationTypes.INCOME.key,
                                        operation_detail_id: group.name,
                                        timestamp_from: budget.timestamp_from,
                                        timestamp_to: budget.timestamp_to,
                                        budget_timestamp: budget.timestamp_to,
                                    } as Record<string, string | string[]>).toString())}>
                                        <small>{operationDetails.find(operationDetail => operationDetail.id === group.name)?.title}:</small>
                                        <small className="mx-1">{getSign(budget.currency)}</small>
                                        <small>{getInteger(group.sum.toString())}</small>
                                        <small className="text-[9px]/[12px] ml-[1px]">{getDecimal(group.sum.toString())}</small>
                                    </div>)}
                                </div>
                                : <p className='my-2'>No hay ningún ingreso no presupuestado</p>
                            }
                        </div>
                    }
                </div>
            </article>
            <Balance currency={budget.currency ?? ''} totalSaving={totalIncome + totalSpending} plannedSaving={plannedIncome + plannedSpending} />
            <article className='h-fit'>
                <h2>Egresos por cada cuenta</h2>
                {expenditureGrouping.length ? expenditureGrouping.map(group => <div className="flex" key={group.name}>
                    <p className='flex items-center'>{financialElements.find(financialElement => financialElement.id === group.name)?.title}:</p>
                    <p className="mx-1 font-bold">{getSign(budget.currency)}</p>
                    <p className="font-bold">{getInteger(group.sum.toString())}</p>
                    <p className="font-bold text-[11px]/[16px] ml-[1px]">{getDecimal(group.sum.toString())}</p>
                </div>) : <div className='text-sf-gray-dark text-sm'>(No se registraron egresos en este período)</div>}
            </article>
            {missingExchangeRates.length > 0 &&
                <article className='h-fit'>
                    <h2 className='text-sf-red-dark font-bold'>Advertencia</h2>
                    <p>Algunos movimietos no pudieron convertise. Faltan los siguientes tipos de cambio:</p>
                    {missingExchangeRates.map((exchangeRate, index) =>
                        <div className="flex" key={index}>
                            <p>USD a</p><p className="mx-1 font-bold">{exchangeRate.code}</p>
                            <p>(</p><p>{new Date(parseInt(exchangeRate.timestamp ?? '')).toLocaleDateString('es', { timeZone: 'UTC' })}</p> <p>)</p>
                        </div>
                    )}
                </article>
            }
        </main>
    )
}
