import { useEffect, useRef, useState } from "react";
import { useContainerDimensions } from "../hooks/UseContainerDimensions";
import { useVerticalScrollWithShadow } from "../hooks/VerticalScrollWithShadow";
import { useCallbackWhenClickOutside } from "../hooks/UseCallbackWhenClickOutside";

interface MenuProps {
    header: JSX.Element
    children: JSX.Element[]
}

export default function Menu(props: MenuProps) {
    const [open, setOpen] = useState(false);
    const [openMenuUpside, setOpenMenuUpside] = useState<boolean | undefined>(undefined);
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const menuRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const { width } = useContainerDimensions(headerRef);
    useCallbackWhenClickOutside(listRef, () => setOpen(false));

    useEffect(() => {
        if (open) {
            setOpenMenuUpside(window.scrollY + window.innerHeight < (menuRef.current?.offsetTop ?? 0) + (menuRef.current?.offsetHeight ?? 0));
        } else {
            setOpenMenuUpside(undefined);
        }
    }, [open]);

    if (open) {
        return (
            <div ref={listRef} className="h-fit w-fit relative">
                <div ref={headerRef} className="rounded-lg hover:bg-sf-violet-light cursor-pointer ring-1 ring-sf-violet-dark" onClick={() => setOpen(false)}>
                    {props.header}
                </div>
                <div ref={menuRef} className={`absolute -translate-x-full z-10 ${openMenuUpside === undefined && 'opacity-0'} ${openMenuUpside && '-translate-y-12 h-0 flex items-end'}`}>
                    <div onScroll={onScrollHandler} ref={ref} style={{ transform: `translate(${width}px)` }} className={`mt-1 p-2 max-h-64 bg-sf-white rounded-lg overflow-auto whitespace-nowrap ring-1 ring-sf-violet-dark space-y-1 ${boxShadow}`}>
                        {props.children.map((option, index) =>
                            <div key={index} className="p-2 w-full rounded-lg hover:bg-sf-violet-light cursor-pointer" onClick={() => setOpen(false)}>
                                {option}
                            </div>
                        )}
                    </div>
                </div>
            </div >
        );
    }

    return (
        <div className="h-fit w-fit">
            <div ref={headerRef} className="rounded-lg hover:bg-sf-violet-light cursor-pointer" onClick={() => setOpen(true)}>
                {props.header}
            </div>
        </div>
    );
}
