import { useEffect, useState } from "react";
import { Visibility } from "../../consts/Visibility";
import { useCurrencyByCode } from "../../hooks/CurrencyFromCode";
import { Budget, defaultItem, Item } from "../../model/Budget";
import { Operation } from "../../model/Operation";
import { getDecimal, getInteger } from "../../utils/number-utils";

interface TotalColumnProps {
    title: string,
    budgets: Budget[],
    visibility: Visibility,
    operations: Operation[],
    incomeIds: string[],
    spendingIds: string[],
    goalIds: string[],
    borrowingIds: string[],
    debtPaymentIds: string[],
    profitIds: string[],
    investmentIds: string[],
    displayIncome: boolean,
    displaySpending: boolean,
    displayGoal: boolean,
    displayBorrowing: boolean,
    displayDebtPayment: boolean,
    displayProfit: boolean,
    displayInvestment: boolean
}

export default function TotalColumn(props: TotalColumnProps): JSX.Element {
    const { getSign } = useCurrencyByCode();
    const [displayCurrent, setDisplayCurrent] = useState(false);

    useEffect(() => setDisplayCurrent(() => {
        switch (props.visibility) {
            case Visibility.AllCurrent:
                return true;
            default:
                return false;
        }
    }), [props.visibility]);

    const currency = getSign(props.budgets[0].currency ?? '') ?? '';

    const calculateTotalAmount = (items: Item[]): string => items.map(item => parseFloat(item.amount ?? '0')).reduce((p, c) => p + c, 0).toString();

    const calculateItem = (ids: string[], isIncome: boolean, key: 'operation_detail_id' | 'financial_element_id'): Item[] => ids.map(id => ({
        ...defaultItem,
        [key]: id,
        amount: calculateTotalAmount(displayCurrent ?
            props.operations.filter(operation => operation[key] === id && isIncome === !operation.amount?.includes('-'))
            :
            props.budgets.map(budget => budget.items ?? []).flat().filter(item => item[key] === id && isIncome === !item.amount?.includes('-')))
    }));

    const generateSectionComponent = (display: boolean, ids: string[], isIncome: boolean, key: 'operation_detail_id' | 'financial_element_id') => {
        const items = calculateItem(ids, isIncome, key);
        const totalAmount = calculateTotalAmount(items);

        return (
            <div className='flex flex-col px-1.5 pb-1.5'>
                <div className='flex my-1.5'>
                    <h3 className='mr-1'>{currency}</h3>
                    <h3>{getInteger(totalAmount)}</h3>
                    <h3 className="text-[11px]/[16px] ml-[1px]">{getDecimal(totalAmount)}</h3>
                </div>
                {display && items.map((item, index) =>
                    <div key={index} className='flex mb-1.5'>
                        <p className='mr-1'>{currency}</p>
                        <p>{getInteger(item.amount ?? '')}</p>
                        <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(item.amount ?? '')}</p>
                    </div>
                )}
            </div>
        );
    };

    const subtotalComponent = (amount: string) => (
        <div className='flex flex-col px-1.5 pb-1.5'>
            <div className='flex my-1.5'>
                <h3 className="font-mono">{amount.includes('-') ? '-' : '+'}</h3>
                <h3 className='mx-1'>{currency}</h3>
                <h3>{getInteger(amount)}</h3>
                <h3 className="text-[11px]/[16px] ml-[1px]">{getDecimal(amount)}</h3>
            </div>
        </div>
    );

    return (
        <ul className={`min-w-[160px] max-w-[160px] flex flex-col divide-y divide-sf-black ${displayCurrent ? 'bg-sf-violet-light-transparent' : ''}`}>
            <div className='p-1.5 pr-0 flex items-end h-12'>
                <h3>{props.title}</h3>
            </div>
            <div className='pt-9'>
                {generateSectionComponent(props.displayIncome, props.incomeIds, true, 'operation_detail_id')}
                {generateSectionComponent(props.displaySpending, props.spendingIds, false, 'operation_detail_id')}
            </div>
            <div className='pt-1.5'>
                {subtotalComponent(calculateTotalAmount([
                    ...calculateItem(props.incomeIds, true, 'operation_detail_id'),
                    ...calculateItem(props.spendingIds, false, 'operation_detail_id')
                ]))}
            </div>
            <div className='pt-9'>
                {generateSectionComponent(props.displayBorrowing, props.borrowingIds, true, 'financial_element_id')}
                {generateSectionComponent(props.displayDebtPayment, props.debtPaymentIds, false, 'financial_element_id')}
            </div>
            <div className='pt-1.5'>
                {subtotalComponent(calculateTotalAmount([
                    ...calculateItem(props.incomeIds, true, 'operation_detail_id'),
                    ...calculateItem(props.spendingIds, false, 'operation_detail_id'),
                    ...calculateItem(props.borrowingIds, true, 'financial_element_id'),
                    ...calculateItem(props.debtPaymentIds, false, 'financial_element_id'),
                ]))}
            </div>
            <div className='pt-9'>
                {generateSectionComponent(props.displayGoal, props.goalIds, false, 'operation_detail_id')}
            </div>
            <div className='pt-1.5'>
                {subtotalComponent(calculateTotalAmount([
                    ...calculateItem(props.incomeIds, true, 'operation_detail_id'),
                    ...calculateItem(props.spendingIds, false, 'operation_detail_id'),
                    ...calculateItem(props.borrowingIds, true, 'financial_element_id'),
                    ...calculateItem(props.debtPaymentIds, false, 'financial_element_id'),
                    ...calculateItem(props.goalIds, false, 'operation_detail_id'),
                ]))}
            </div>
            <div className='pt-9'>
                {generateSectionComponent(props.displayProfit, props.profitIds, true, 'financial_element_id')}
                {generateSectionComponent(props.displayInvestment, props.investmentIds, false, 'financial_element_id')}
            </div>
            <div className='pt-1.5'>
                {subtotalComponent(calculateTotalAmount([
                    ...calculateItem(props.incomeIds, true, 'operation_detail_id'),
                    ...calculateItem(props.spendingIds, false, 'operation_detail_id'),
                    ...calculateItem(props.borrowingIds, true, 'financial_element_id'),
                    ...calculateItem(props.debtPaymentIds, false, 'financial_element_id'),
                    ...calculateItem(props.goalIds, false, 'operation_detail_id'),
                    ...calculateItem(props.profitIds, true, 'financial_element_id'),
                    ...calculateItem(props.investmentIds, false, 'financial_element_id'),
                ]))}
            </div>
        </ul>
    );
}
