import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Context } from '../utils/context';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import Select from '../components/Select';
import { processDecimalInput } from '../utils/process-decimal-input';
import { useVerticalScrollWithShadow } from '../hooks/VerticalScrollWithShadow';
import EmptyList from '../components/EmptyList';
import { Operation, defaultOperation } from '../model/Operation';
import { OperationTypes } from '../consts/OperationTypes';
import { listOperations } from '../utils/api';
import { sortElements } from '../utils/sort-elements';
import { getFormattedLocaleDate, getHourInMilis, ifDateIsValidThen } from '../utils/date-utils';
import { getInteger, getDecimal } from '../utils/number-utils';
import OperationsService from '../utils/OperationsService';

export default function TransactionFull() {
    const { client, addFeedback, operationDetails, setConfirmDialog, financialElements } = useContext(Context);
    const [operations, setOperations] = useState<Operation[]>([]);
    const [loading, setLoading] = useState(true);
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const navigate = useNavigate();
    const { grouping_id } = useParams();
    const { getSign } = useCurrencyByCode();
    const [newOperation, setNewOperation] = useState({ ...defaultOperation });
    const [displayNewOperation, setDisplayNewOperation] = useState(false);
    const [selectedNewType, setSelectedNewType] = useState(OperationTypes.OUTFLOW);
    const [operationToUpdate, setOperationToUpdate] = useState({ ...defaultOperation });
    const [displayOperationUpdate, setDisplayOperationUpdate] = useState(false);
    const [selectedUpdateType, setSelectedUpdateType] = useState(OperationTypes.OUTFLOW);
    const [searchParams] = useSearchParams();
    const [date, setDate] = useState<Date>();

    useEffect(() => {
        const fetchOperations = async () => {
            setLoading(true);
            try {
                const operations = await listOperations(client.id ?? '', { grouping_id: grouping_id });
                setOperations(operations);
            } finally {
                setLoading(false);
            }
        }
        fetchOperations();
    }, [client.id, grouping_id]);

    const operationsToDisplay = operations.filter(operation => operation.grouping_id === grouping_id);
    sortElements(operationsToDisplay, [
        { field: 'timestamp', asc: false },
        { field: 'update_timestamp', asc: false },
    ]);

    function openNewTransaction() {
        const now = new Date();
        setDate(now);
        if (operationsToDisplay.length > 0) {
            setNewOperation({
                ...defaultOperation,
                grouping_id: grouping_id,
                timestamp: new Date(parseFloat(operationsToDisplay[0].timestamp ?? '')).toISOString().substring(0, 10),
                currency_code: operationsToDisplay[0].currency_code,
                amount: operationsToDisplay[0].amount?.replace('-', ''),
                description: operationsToDisplay[0].description
            });
            setSelectedNewType(operationsToDisplay[0].amount?.includes('-') ? OperationTypes.INFLOW : OperationTypes.OUTFLOW);
        } else {
            setNewOperation({
                ...defaultOperation,
                grouping_id: grouping_id,
                timestamp: getFormattedLocaleDate(now),
                currency_code: client.currencies ? client.currencies[0]?.code ?? '' : ''
            });
            setSelectedNewType(OperationTypes.OUTFLOW);
        }
        setDisplayNewOperation(true);
        setDisplayOperationUpdate(false);
    }

    function handleNewOperationInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setNewOperation(oldOperation => ({
            ...oldOperation,
            [target.name]: target.value
        }));
    }

    async function saveCreateNewOperation() {
        setLoading(true);
        try {
            const operation = await OperationsService.createOperation(client.id ?? '', {
                ...newOperation,
                amount: selectedNewType === OperationTypes.OUTFLOW ? `-${newOperation.amount}` : newOperation.amount,
                timestamp: new Date(newOperation.timestamp ?? '').getTime().toString(),
                hour: getHourInMilis(date).toString(),
            });
            setOperations(oldOperations => [operation, ...oldOperations]);
            addFeedback({ message: '¡Operación creada con éxito!', level: 'success' });
        } finally {
            setDisplayNewOperation(false);
            setLoading(false);
        }
    }

    function handleEditOperation(operation: Operation) {
        setOperationToUpdate({
            ...operation,
            timestamp: new Date(parseInt(operation.timestamp ?? '')).toISOString().substring(0, 10),
            amount: operation.amount?.replace('-', '')
        });
        setDisplayOperationUpdate(true);
        setSelectedUpdateType(operation.amount?.includes('-') ? OperationTypes.OUTFLOW : OperationTypes.INFLOW);
        setDisplayNewOperation(false);
    }

    function handleOperationToUpdateInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setOperationToUpdate(oldOperation => ({
            ...oldOperation,
            [target.name]: target.value
        }));
    }

    async function saveUpdateOperation() {
        setLoading(true);
        try {
            const operation = {
                ...operationToUpdate,
                amount: selectedUpdateType === OperationTypes.OUTFLOW ? `-${operationToUpdate.amount}` : operationToUpdate.amount,
                timestamp: new Date(operationToUpdate.timestamp ?? '').getTime().toString()
            }
            await OperationsService.updateOperation(client.id ?? '', operationToUpdate.id ?? '', operation);
            setOperations(oldOperations => [operation, ...oldOperations.filter(oldOperation => oldOperation.id !== operation.id)]);
            addFeedback({ message: '¡Operación actualizada con éxito!', level: 'success' });
        } finally {
            setDisplayOperationUpdate(false);
            setLoading(false);
        }
    }

    async function deleteOperationToUpdate() {
        setLoading(true);
        try {
            await OperationsService.deleteOperation(client.id ?? '', operationToUpdate.id ?? '');
            setOperations(oldOperations => oldOperations.filter(oldOperation => oldOperation.id !== operationToUpdate.id));
        } finally {
            setDisplayOperationUpdate(false);
            setLoading(false);
        }
    }

    async function deleteTransaction() {
        setLoading(true);
        try {
            await OperationsService.deleteOperationBatch(client.id ?? '', operationsToDisplay.map(operation => operation.id ?? ''));
        } finally {
            navigate(`/transactions?${searchParams}`);
            setLoading(false);
        }
    }

    if (loading) return <Loading />

    const expenditureOperationDetails = operationDetails
        .filter(operationDetail => operationDetail.status === 'ENABLED' && operationDetail.type === OperationTypes.EXPENDITURE.key)
        .map(operationDetail => ({ value: operationDetail.id, alias: operationDetail.title }));

    const incomeOperationDetails = operationDetails
        .filter(operationDetail => operationDetail.status === 'ENABLED' && operationDetail.type === OperationTypes.INCOME.key)
        .map(operationDetail => ({ value: operationDetail.id, alias: operationDetail.title }));

    const confirmDeleteOperationDialogParams = {
        callback: deleteOperationToUpdate,
        title: '¿Estás seguro que deseas eliminar esta operación?',
        confirmButton: 'Eliminar',
        cancelButton: 'Cancelar'
    };

    const confirmDeleteTransactionDialogParams = {
        callback: deleteTransaction,
        title: '¿Estás seguro que deseas eliminar esta transacción?',
        confirmButton: 'Eliminar',
        cancelButton: 'Cancelar'
    };

    return (
        <main>
            <button className='button-aux w-fit p-1 col-span-full' onClick={() => navigate(`/transactions?${searchParams}`)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-2.5 w-2.5" viewBox="0 0 24 24"><path fill="currentColor" d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875q-.375.375-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12q0-.375.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388q.375.375.375.875t-.375.875L3.55 12Z" /></svg>
                <div>Volver al listado de transacciones internas</div>
            </button>
            <article className="p-0 space-y-0 h-fit">
                <div className="flex w-full flex-row justify-between items-center p-3">
                    <h2>Transacción</h2>
                    <button className="button-aux flex space-x-1 items-center" onClick={openNewTransaction}>
                        <p>Agregar operación</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-3.5" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                    </button>
                </div>
                {displayNewOperation &&
                    <div className='p-4 pt-0'>
                        <article className='bg-sf-violet-light'>
                            <h2>Nueva operación</h2>
                            <Select placeholder='Selecciona una cuenta *' name='financial_element_id' value={newOperation.financial_element_id} onChange={handleNewOperationInputChange} options={financialElements.filter(financialElement => financialElement.status === 'ENABLED').map(financialElement => ({ value: financialElement.id, alias: financialElement.title }))} />
                            <div className='field p-2 w-full justify-evenly'>
                                {[OperationTypes.OUTFLOW, OperationTypes.INFLOW].map(type => (
                                    <div key={type.key} className='flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1' onClick={() => setSelectedNewType(type)}>
                                        <div className={`rounded-full h-3.5 w-3.5 ${type.key === selectedNewType?.key ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></div>
                                        <div>{type.label}</div>
                                    </div>
                                ))}
                            </div>
                            <div className='flex space-x-3'>
                                <div className='w-48'>
                                    <Select placeholder='Moneda' name='currency_code' value={newOperation.currency_code} onChange={handleNewOperationInputChange} options={client.currencies?.map(currency => ({ value: currency.code, alias: getSign(currency.code) }))} />
                                </div>
                                <input type='text' inputMode='decimal' name='amount' placeholder='Cantidad *' value={newOperation.amount} onChange={e => processDecimalInput(e) && handleNewOperationInputChange(e)} className='field' />
                            </div>
                            <input name='description' type='text' placeholder='Descripción *' value={newOperation.description} onChange={handleNewOperationInputChange} className='field' />
                            <input name='timestamp' type='date' value={newOperation.timestamp} onChange={ifDateIsValidThen(handleNewOperationInputChange)} className='field' />
                            <div className='flex w-full justify-between items-center'>
                                <button className='button-secondary' onClick={() => setDisplayNewOperation(false)}>Cerrar</button>
                                <button className='button-primary my-0' disabled={!newOperation.timestamp || !newOperation.financial_element_id || !newOperation.currency_code || !newOperation.amount || parseFloat(newOperation.amount) === 0 || !newOperation.description} onClick={saveCreateNewOperation}>Agregar</button>
                            </div>
                        </article>
                    </div>
                }
                {displayOperationUpdate &&
                    <div className='p-4 pt-0'>
                        <article className='bg-sf-violet-light'>
                            <h2>Editar operación</h2>
                            <Select placeholder='Selecciona una cuenta *' name='financial_element_id' value={operationToUpdate.financial_element_id} onChange={handleOperationToUpdateInputChange} options={financialElements.filter(financialElement => financialElement.id === operationToUpdate.financial_element_id || financialElement.status === 'ENABLED').map(financialElement => ({ value: financialElement.id, alias: financialElement.title }))} />
                            {(operationToUpdate.operation_detail_id ?? '') === '' ?
                                <div className='field p-2 w-full justify-evenly'>
                                    {[OperationTypes.OUTFLOW, OperationTypes.INFLOW].map(type => (
                                        <div key={type.key} className='flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1' onClick={() => setSelectedUpdateType(type)}>
                                            <div className={`rounded-full h-3.5 w-3.5 ${type.key === selectedUpdateType?.key ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></div>
                                            <div>{type.label}</div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <Select placeholder='Selecciona una categoría de movimiento *' name='operation_detail_id' value={operationToUpdate.operation_detail_id} onChange={handleOperationToUpdateInputChange} options={selectedUpdateType === OperationTypes.OUTFLOW ? expenditureOperationDetails : incomeOperationDetails} />
                            }
                            <div className='flex space-x-3'>
                                <div className='w-48'>
                                    <Select placeholder='Moneda' name='currency_code' value={operationToUpdate.currency_code} onChange={handleOperationToUpdateInputChange} options={client.currencies?.map(currency => ({ value: currency.code, alias: getSign(currency.code) }))} />
                                </div>
                                <input type='text' inputMode='decimal' name='amount' placeholder='Cantidad *' value={operationToUpdate.amount} onChange={e => processDecimalInput(e) && handleOperationToUpdateInputChange(e)} className='field' />
                            </div>
                            <input name='description' type='text' placeholder='Descripción *' value={operationToUpdate.description} onChange={handleOperationToUpdateInputChange} className='field' />
                            <input name='timestamp' type='date' value={operationToUpdate.timestamp} onChange={ifDateIsValidThen(handleOperationToUpdateInputChange)} className='field' />
                            <div className='flex w-full justify-between items-center'>
                                <button className='button-secondary' onClick={() => setDisplayOperationUpdate(false)}>Cerrar</button>
                                <div className='flex w-fit space-x-3'>
                                    <button className='button-secondary-red' onClick={() => setConfirmDialog(confirmDeleteOperationDialogParams)}>Eliminar</button>
                                    <button className='button-primary my-0' disabled={!operationToUpdate.timestamp || !operationToUpdate.financial_element_id || !operationToUpdate.currency_code || !operationToUpdate.amount || parseFloat(operationToUpdate.amount) === 0 || !operationToUpdate.description} onClick={saveUpdateOperation}>Modificar</button>
                                </div>
                            </div>
                        </article>
                    </div>
                }
                <div onScroll={onScrollHandler} ref={ref} className={`px-4 max-h-96 rounded-b-2xl overflow-auto ${boxShadow}`}>
                    <ul className="divide-y divide-sf-black border-t border-sf-black">
                        {operationsToDisplay.length > 0
                            ? operationsToDisplay.map((operation: Operation) =>
                                <div key={operation.id} className="flex justify-between py-2 cursor-pointer hover:bg-sf-gray-extra-light" onClick={() => handleEditOperation(operation)}>
                                    <div className='max-w-[62%]'>
                                        {operation.operation_detail_id === '' ?
                                            <p>{operation.description}</p>
                                            :
                                            <div className='flex space-x-1'>
                                                <h3 className='whitespace-nowrap'>{operationDetails.find(operationDetail => operationDetail.id === operation.operation_detail_id)?.title}</h3>
                                                {operation.description && <p className="secondary font-bold">({operation.description})</p>}
                                            </div>
                                        }
                                        <small className='truncate'>{financialElements.find(financialElement => financialElement.id === operation.financial_element_id)?.title}</small>
                                    </div>
                                    <div className='flex flex-col'>
                                        <div className={`flex self-end ${operation.amount?.includes('-') ? 'expenditure' : 'income'}`}>
                                            <p>{operation.amount?.includes('-') ? '-' : '+'}</p>
                                            <p className="mx-1">{getSign(operation.currency_code)}</p>
                                            <p>{getInteger(operation.amount ?? '')}</p>
                                            <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(operation.amount ?? '')}</p>
                                        </div>
                                        <small className="self-end mt-2">{new Date(parseInt(operation.timestamp ?? '')).toLocaleDateString('es', { timeZone: 'UTC' })}</small>
                                    </div>
                                </div>
                            )
                            : <EmptyList message="Aún no hay operaciones en esta transacción." />
                        }
                    </ul>
                </div>
            </article>
            <div className='col-span-full xl:w-1/2 flex justify-center'>
                <button className='button-secondary-red ring-1 ring-sf-red-dark' onClick={() => setConfirmDialog(confirmDeleteTransactionDialogParams)}>
                    Eliminar transacción
                </button>
            </div>
        </main>
    )
}
