import { Currency } from "./Currency";

export interface Client {
    readonly 'currencies'?: Currency[];
    readonly 'id'?: string;
    readonly 'name'?: string;
    readonly 'status'?: string;
    readonly 'email'?: string;
    readonly 'enable_plan'?: boolean;
    readonly 'identity_id'?: string;
};

export const defaultClient: Client = {
    'currencies': [],
    'id': '',
    'name': '',
    'status': '',
    'email': '',
    'enable_plan': false,
    'identity_id': ''
};
