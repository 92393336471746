import { getDecimal, getInteger } from "../../utils/number-utils";

interface CashflowHeaderProps {
    title: string,
    setKey: (key: string) => void,
    currency: string,
    amount: string,
    hidden: boolean,
    setHidden: (hidden: boolean) => void
}

export default function CashflowHeader(props: CashflowHeaderProps): JSX.Element {
    return (
        <div className='flex w-full justify-between items-center pt-1.5'>
            <div className='flex items-center h-fit space-x-1.5 w-fit max-w-[62%]'>
                <p className='hover:underline cursor-pointer text-base' onClick={() => props.setKey(props.title)}>{props.title}</p>
                {props.hidden ?
                    <svg xmlns="http://www.w3.org/2000/svg" className='h-5 rounded-full cursor-pointer' onClick={() => props.setHidden(false)} viewBox="0 0 24 24"><path fill="currentColor" d="m12 13.387l-2.74-2.74q-.136-.141-.34-.141t-.349.14q-.165.146-.156.357q.01.21.156.357l2.864 2.863q.242.242.565.242q.323 0 .565-.242l2.87-2.87q.146-.145.153-.353q.006-.208-.16-.354q-.145-.14-.353-.133q-.208.006-.354.152zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924q-1.216-1.214-1.925-2.856Q3 13.87 3 12.003q0-1.866.708-3.51q.709-1.643 1.924-2.859q1.214-1.216 2.856-1.925Q10.13 3 11.997 3q1.866 0 3.51.708q1.643.709 2.859 1.924q1.216 1.214 1.925 2.856Q21 10.13 21 11.997q0 1.866-.708 3.51q-.709 1.643-1.924 2.859q-1.214 1.216-2.856 1.925Q13.87 21 12.003 21M12 20q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20m0-8" /></svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" className='h-5 rounded-full cursor-pointer' onClick={() => props.setHidden(true)} viewBox="0 0 24 24"><path fill="currentColor" d="m12 10.613l2.74 2.74q.136.141.34.141t.349-.14q.165-.146.156-.357q-.01-.21-.156-.357l-2.864-2.863q-.242-.242-.565-.242q-.323 0-.565.242l-2.87 2.87q-.146.145-.152.353q-.007.208.158.354q.146.14.354.134q.208-.007.354-.153zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924q-1.216-1.214-1.925-2.856Q3 13.87 3 12.003q0-1.866.708-3.51q.709-1.643 1.924-2.859q1.214-1.216 2.856-1.925Q10.13 3 11.997 3q1.866 0 3.51.708q1.643.709 2.859 1.924q1.216 1.214 1.925 2.856Q21 10.13 21 11.997q0 1.866-.708 3.51q-.709 1.643-1.924 2.859q-1.214 1.216-2.856 1.925Q13.87 21 12.003 21M12 20q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20m0-8" /></svg>
                }
            </div>
            <div className="flex w-fit">
                <p className="font-mono text-base">{props.amount.includes('-') ? '-' : '+'}</p>
                <p className='mx-1 text-base'>{props.currency}</p>
                <p className="text-base">{getInteger(props.amount)}</p>
                <p className="text-[12px]/[18px] ml-[1px]">{getDecimal(props.amount)}</p>
            </div>
        </div>
    );
}
