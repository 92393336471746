import { useContext } from 'react';
import { Context } from '../utils/context';

export function useCurrencyByCode() {
    const { client } = useContext(Context);

    function getSign(code: string | undefined): string {
        const foundCurrency = client.currencies?.find(currency => currency.code === code);

        return (foundCurrency === undefined || foundCurrency.sign === undefined || foundCurrency.sign === '' ? code : foundCurrency.sign) ?? '';
    }

    return { getSign };
}