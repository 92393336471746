import { SyntheticEvent, useContext, useState } from "react";
import { Budget, defaultItem, Item } from "../../model/Budget";
import { FinancialElement } from "../../model/FinancialElement";
import { Operation } from "../../model/Operation";
import { OperationDetail } from "../../model/OperationDetail";
import { Context } from "../../utils/context";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useCurrencyByCode } from "../../hooks/CurrencyFromCode";
import { getDecimal, getInteger } from "../../utils/number-utils";
import { processDecimalInput } from "../../utils/process-decimal-input";
import Select from "../Select";
import ProgressBarViolet from "../ProgressBarViolet";
import BudgetItemMovementRow from "./BudgetItemMovementRow";
import UnbudgetedItemMovementRow from "./UnbudgetedItemMovementRow";

interface GroupedOperations {
    name: string;
    sum: number;
}

function groupBy(items: Item[], key: 'operation_detail_id' | 'financial_element_id'): GroupedOperations[] {
    const map = items.reduce(
        (acc, item) => acc.set(item[key] ?? '', (acc.get(item[key] ?? '') ?? 0) + parseFloat(item.amount ?? '0')),
        new Map<string, number>()
    );

    return Array.from(map, ([name, sum]) => ({ name, sum }));
}

interface PlanSectionProps {
    budget: Budget,
    operations: Operation[],
    title: string,
    isIncome: boolean,
    isMovement: boolean,
    addItem: (budget: Budget, item: Item) => void,
    updateItem: (budget: Budget, item: Item, newAmount: string) => void,
    removeItem: (budget: Budget, item: Item) => void,
    swap: (budget: Budget, item1: Item, item2: Item) => void,
    operationsFilterPredicate: (item: Operation) => boolean,
    availableElementsFilterPredicate: (element: OperationDetail | FinancialElement) => boolean,
}

export default function PlanSection(props: PlanSectionProps): JSX.Element {
    const { operationDetails, financialElements } = useContext(Context);
    const [hidden, setHidden] = useState(true);
    const [newIncomeItem, setNewIncomeItem] = useState<Item>({ ...defaultItem });
    const [openForm, setOpenForm] = useState(false);
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();

    const currency = getSign(props.budget.currency ?? '') ?? '';
    const key = props.isMovement ? 'operation_detail_id' : 'financial_element_id';
    const filteredOperations = props.operations.filter(props.operationsFilterPredicate);
    const totalCurrentAmount = filteredOperations
        .map(operation => parseFloat(operation.amount ?? ''))
        .reduce((p, c) => p + c, 0).toString();
    const budgetItems = props.budget.items?.filter(props.operationsFilterPredicate) ?? [];
    const totalPlannedAmount = budgetItems
        .map(operation => parseFloat(operation.amount ?? ''))
        .reduce((p, c) => p + c, 0).toString();
    const budgetItemsIds = budgetItems.map(item => item[key]);
    const unbugetedItems = groupBy(filteredOperations.filter(operation => !budgetItemsIds.includes(operation[key])), key);
    const elements = (props.isMovement ? operationDetails : financialElements) as (OperationDetail | FinancialElement)[];
    const availableElements = elements.filter(element =>
        !budgetItemsIds.includes(element.id) &&
        element.status === 'ENABLED' &&
        props.availableElementsFilterPredicate(element)
    ).map(element => ({ value: element.id, alias: element.title }));

    function getTitle(id: string) {
        return elements.find(e => e.id === id)?.title ?? '';
    }

    function handleNewIncomeItemChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setNewIncomeItem(oldItem => ({ ...oldItem, [target.name]: target.value }));
    }

    function handleNewIncomeItemSave() {
        if (newIncomeItem.amount !== '' && (newIncomeItem.operation_detail_id !== '' || newIncomeItem.financial_element_id !== '')) {
            props.addItem(props.budget, { ...newIncomeItem, amount: props.isIncome ? newIncomeItem.amount : `-${newIncomeItem.amount}` });
            setNewIncomeItem({ ...defaultItem });
            setOpenForm(false);
        }
    }

    return (
        <>
            <div className='flex w-full justify-between items-center rounded-lg cursor-pointer hover:bg-sf-gray-extra-light' onClick={() => setHidden(old => !old)}>
                <p className='pl-1.5 w-[40%]'>{props.title}</p>
                <div className='w-[27%] flex'>
                    <p>Actual:</p>
                    <p className="ml-1 font-mono">{props.isIncome ? '+' : '-'}</p>
                    <p className='mx-1'>{currency}</p>
                    <p>{getInteger(totalCurrentAmount)}</p>
                    <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(totalCurrentAmount)}</p>
                </div>
                <div className='w-[27%] flex'>
                    <p>Plan:</p>
                    <p className="ml-1 font-mono">{props.isIncome ? '+' : '-'}</p>
                    <p className='mx-1'>{currency}</p>
                    <p>{getInteger(totalPlannedAmount)}</p>
                    <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(totalPlannedAmount)}</p>
                </div>
                <div className='w-[6%] flex justify-end'>
                    <div className="h-8 w-8 p-2 flex items-center justify-center">
                        {hidden ?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8"><path fill="currentColor" d="M0.308162 0.305919C0.505537 0.110039 0.773199 0 1.05229 0C1.33138 0 1.59904 0.110039 1.79641 0.305919L7.00635 5.47795L12.2163 0.305919C12.4148 0.115591 12.6807 0.0102748 12.9566 0.0126554C13.2326 0.015036 13.4966 0.124922 13.6917 0.318647C13.8869 0.512371 13.9976 0.774433 14 1.04839C14.0024 1.32235 13.8963 1.58628 13.7045 1.78334L7.75048 7.69408C7.55311 7.88996 7.28544 8 7.00635 8C6.72727 8 6.4596 7.88996 6.26223 7.69408L0.308162 1.78334C0.110846 1.5874 0 1.32169 0 1.04463C0 0.767573 0.110846 0.501858 0.308162 0.305919Z" /></svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="183.868 136.755 14 8"><path fill="currentColor" d="M 184.176 144.449 C 184.374 144.645 184.641 144.755 184.92 144.755 C 185.2 144.755 185.467 144.645 185.665 144.449 L 190.875 139.277 L 196.084 144.449 C 196.283 144.64 196.549 144.745 196.825 144.742 C 197.101 144.74 197.365 144.63 197.56 144.436 C 197.755 144.243 197.866 143.981 197.868 143.707 C 197.871 143.433 197.764 143.169 197.573 142.972 L 191.619 137.061 C 191.421 136.865 191.154 136.755 190.875 136.755 C 190.595 136.755 190.328 136.865 190.13 137.061 L 184.176 142.972 C 183.979 143.168 183.868 143.433 183.868 143.711 C 183.868 143.988 183.979 144.253 184.176 144.449 Z" transform="matrix(1, 0, 0, 1, 0, 1.4210854715202004e-14)" /></svg>
                        }
                    </div>
                </div>
            </div>
            <ul className={`divide-y divide-sf-black px-1.5 rounded-xl bg-sf-violet-light ${hidden && 'hidden'}`}>
                {budgetItems.length + unbugetedItems.length > 0 &&
                    <div className='flex w-full font-bold py-1.5'>
                        <div className='w-full flex'>
                            <p className='w-1/4'>Categoría</p>
                            <p className='w-1/4'>Actual</p>
                            <p className='w-1/4'>Plan</p>
                            <p className='w-1/4 text-center'>Progreso</p>
                        </div>
                        <div className='w-16'></div>
                    </div>
                }
                {budgetItems.map((item, index) => <BudgetItemMovementRow key={index}
                    currency={currency}
                    currentAmount={filteredOperations
                        .filter(operation => operation[key] === item[key])
                        .map(operation => parseFloat(operation.amount ?? '0'))
                        .reduce((p, c) => p + c, 0).toString()}
                    item={item}
                    title={getTitle(item[key] ?? '')}
                    goTo={() => navigate(`/${props.isMovement ? 'operations' : 'transactions'}?${createSearchParams({
                        [(props.isMovement ? '' : 'tr_') + key]: item[key] ?? '',
                        timestamp_from: props.budget.timestamp_from ?? '',
                        timestamp_to: props.budget.timestamp_to ?? '',
                        budget_timestamp: props.budget.timestamp_to ?? '',
                        from: 'plan'
                    }).toString()}`)}
                    removeItem={(item: Item) => props.removeItem(props.budget, item)}
                    updateItem={(item: Item, amount: string) => props.updateItem(props.budget, item, amount)}
                    moveUp={() => index > 0 ? props.swap(props.budget, item, budgetItems[index - 1]) : null}
                    moveDown={() => index < (budgetItems.length - 1) ? props.swap(props.budget, item, budgetItems[index + 1]) : null}
                />)}
                {unbugetedItems.map((group, index) => <UnbudgetedItemMovementRow key={index}
                    currency={currency}
                    currentAmount={group.sum.toString()}
                    item={{ ...defaultItem, [key]: group.name, amount: props.isIncome ? '0' : '-0' } as Item}
                    title={getTitle(group.name)}
                    goTo={() => navigate(`/${props.isMovement ? 'operations' : 'transactions'}?${createSearchParams({
                        [(props.isMovement ? '' : 'tr_') + key]: group.name,
                        timestamp_from: props.budget.timestamp_from ?? '',
                        timestamp_to: props.budget.timestamp_to ?? '',
                        budget_timestamp: props.budget.timestamp_to ?? '',
                        from: 'plan'
                    }).toString()}`)}
                    addItem={(item: Item) => props.addItem(props.budget, item)}
                />)}
                {budgetItems.length + unbugetedItems.length > 0 &&
                    <div className="py-1.5 w-full flex font-bold">
                        <div className='w-full flex'>
                            <p className="w-1/4">Total</p>
                            <div className='w-1/4 flex justify-start'>
                                <p className='mr-1'>{currency}</p>
                                <p>{getInteger(totalCurrentAmount)}</p>
                                <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(totalCurrentAmount)}</p>
                            </div>
                            <div className='w-1/4 flex justify-start'>
                                <p className='mr-1'>{currency}</p>
                                <p>{getInteger(totalPlannedAmount)}</p>
                                <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(totalPlannedAmount)}</p>
                            </div>
                            <div className="w-1/4">
                                <ProgressBarViolet current={totalCurrentAmount} planned={totalPlannedAmount} />
                            </div>
                        </div>
                        <div className='w-16'></div>
                    </div>
                }
                {availableElements.length > 0 &&
                    (openForm ? (
                        <div className="flex w-full py-1.5">
                            <div className='flex w-full space-x-1.5 bg-sf-violet-light rounded-lg'>
                                <div className='flex w-[45%]'>
                                    <Select placeholder='Selecciona una opción *' name={key} value={newIncomeItem[key]} onChange={handleNewIncomeItemChange} options={availableElements} />
                                </div>
                                <div className='flex w-[40%]'>
                                    <div className={`field px-3 bg-sf-white text-center w-fit mr-1.5 ${currency === '' && 'ring-2 ring-inset ring-sf-red-dark'}`}>{currency}</div>
                                    <input type='text' inputMode='decimal' name='amount' placeholder='Monto *' value={newIncomeItem.amount} onChange={e => processDecimalInput(e) && handleNewIncomeItemChange(e)} className='field' />
                                </div>
                                <div className='flex w-[15%] justify-evenly'>
                                    <button className="cursor-pointer" onClick={handleNewIncomeItemSave}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 text-sf-violet-dark" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10" /><path strokeLinecap="round" strokeLinejoin="round" d="m8.5 12.5l2 2l5-5" /></g></svg>
                                    </button>
                                    <button className="cursor-pointer" onClick={() => { setNewIncomeItem({ ...defaultItem }); setOpenForm(false); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 text-sf-red-dark" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10" /><path strokeLinecap="round" d="m14.5 9.5l-5 5m0-5l5 5" /></g></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='w-full px-1.5 py-3'>
                            <div className='w-fit px-3 py-1.5 hover:bg-sf-violet-medium cursor-pointer flex space-x-0.5 rounded-lg items-center underline hover:no-underline' onClick={() => setOpenForm(true)}>
                                <p>Nuevo item</p>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4' viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                            </div>
                        </div>
                    ))
                }
            </ul>
        </>
    );
}
