import { FallbackProps } from "react-error-boundary";
import { handleSignOut } from "../utils/handle-sign-out";

export default function Fallback({ error }: FallbackProps) {
    function onReset() {
        localStorage.removeItem('client_id');
        window.location.replace(new URL(window.location.href).origin);
    }

    return (
        <main className='fallback-page'>
            <h1>Error en Somosfin App</h1>
            <article className='w-fit'>
                <h2 className='mx-4'>Intenta de nuevo, o contáctate con nosotros</h2>
                <div className='border-t border-black w-full h-min pb-1'></div>
                <div className='self-center flex flex-col justify-center items-center w-fit py-2 px-4 rounded-lg bg-sf-violet-light'>
                    <button className='button-primary flex items-center space-x-1' onClick={onReset}>
                        <p>Recargar</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 24 24"><path fill="currentColor" d="M12 20q-3.35 0-5.675-2.325T4 12q0-3.35 2.325-5.675T12 4q1.725 0 3.3.712T18 6.75V5q0-.425.288-.713T19 4q.425 0 .713.288T20 5v5q0 .425-.288.713T19 11h-5q-.425 0-.713-.288T13 10q0-.425.288-.713T14 9h3.2q-.8-1.4-2.188-2.2T12 6Q9.5 6 7.75 7.75T6 12q0 2.5 1.75 4.25T12 18q1.7 0 3.113-.863t2.187-2.312q.2-.35.563-.487t.737-.013q.4.125.575.525t-.025.75q-1.025 2-2.925 3.2T12 20Z" /></svg>
                    </button>
                    <button className='button-primary flex items-center space-x-1' onClick={async () => await navigator.clipboard.writeText(JSON.stringify(error))}>
                        <p>Copiar error</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 24 24"><path fill="currentColor" d="M9 18q-.825 0-1.413-.588T7 16V4q0-.825.588-1.413T9 2h9q.825 0 1.413.588T20 4v12q0 .825-.588 1.413T18 18H9Zm0-2h9V4H9v12Zm-4 6q-.825 0-1.413-.588T3 20V7q0-.425.288-.713T4 6q.425 0 .713.288T5 7v13h10q.425 0 .713.288T16 21q0 .425-.288.713T15 22H5Zm4-6V4v12Z" /></svg>
                    </button>
                    <button className='button-primary flex items-center space-x-1' onClick={handleSignOut}>
                        <p>Salir</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 24 24"><path fill="currentColor" d="M5 21q-.825 0-1.413-.588T3 19V5q0-.825.588-1.413T5 3h6q.425 0 .713.288T12 4q0 .425-.288.713T11 5H5v14h6q.425 0 .713.288T12 20q0 .425-.288.713T11 21H5Zm12.175-8H10q-.425 0-.713-.288T9 12q0-.425.288-.713T10 11h7.175L15.3 9.125q-.275-.275-.275-.675t.275-.7q.275-.3.7-.313t.725.288L20.3 11.3q.3.3.3.7t-.3.7l-3.575 3.575q-.3.3-.713.288t-.712-.313q-.275-.3-.263-.713t.288-.687l1.85-1.85Z" /></svg>
                    </button>
                </div>
            </article>
        </main>
    );
}