import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Context } from "../utils/context";
import { useVerticalScrollWithShadow } from "../hooks/VerticalScrollWithShadow";
import Loading from "./Loading";
import { sortElements } from "../utils/sort-elements";
import { Operation } from "../model/Operation";
import EmptyList from "./EmptyList";
import { getDecimal, getInteger } from "../utils/number-utils";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import { addDaysToTimestamp, addMonthsToTimestamp, ifDateIsValidThen } from "../utils/date-utils";
import { writeSpreadsheet } from "../utils/spreadsheets-utils";
import { OperationTypes } from "../consts/OperationTypes";
import OperationsService from "../utils/OperationsService";

export default function DownloadOperations(): JSX.Element {
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const { client, operationDetails, financialElements, addFeedback } = useContext(Context);
    const [operations, setOperations] = useState<Operation[]>([]);
    const [timestampFrom, setTimestampFrom] = useState<string>('');
    const [timestampTo, setTimestampTo] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const { getSign } = useCurrencyByCode();

    useEffect(() => {
        try {
            setLoading(true);
            const today = new Date();
            const UTCtoday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()).toString();
            setTimestampFrom(addMonthsToTimestamp(UTCtoday, -1));
            setTimestampTo(addDaysToTimestamp(UTCtoday, 1));
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const fetchOperations = () => {
            if (!client.id || !timestampFrom || !timestampTo) return;

            OperationsService.listOperationsByRange(client.id, timestampFrom, timestampTo).then(setOperations);
        };

        fetchOperations();

        return OperationsService.subscribe(fetchOperations);
    }, [client.id, timestampFrom, timestampTo]);

    if (loading) return <Loading />

    function handleDateFromChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        const newTimestamp = new Date(target.value ?? '').getTime().toString();
        setTimestampFrom(newTimestamp);
    }

    function handleDateToChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        const newTimestamp = new Date(target.value ?? '').getTime().toString();
        setTimestampTo(addDaysToTimestamp(newTimestamp, 1));
    }

    const dateFrom = timestampFrom === null ? '' : new Date(parseInt(timestampFrom)).toISOString().substring(0, 10);

    const dateTo = timestampTo === null ? '' : new Date(parseInt(addDaysToTimestamp(timestampTo, -1))).toISOString().substring(0, 10);

    const filteredOperations = operations?.filter(operation => {
        if (timestampFrom === undefined || timestampTo === undefined || operation.timestamp === undefined) return 0;

        return operation.timestamp >= timestampFrom && operation.timestamp < timestampTo;
    });

    sortElements(filteredOperations, [
        { field: 'timestamp', asc: false },
        { field: 'hour', asc: false },
        { field: 'grouping_id', asc: false },
        { field: 'update_timestamp', asc: false },
    ]);

    const operationGroups: Operation[][] = [];
    const operationMap = new Map<string, Operation[]>();
    filteredOperations.forEach(operation => {
        const groupValue = operation.grouping_id ?? '';
        if (groupValue === '') {
            operationGroups.push([operation]);
        } else {
            operationMap.set(groupValue, operationMap.get(groupValue)?.concat(operation) ?? [operation]);
        }
    });
    operationGroups.push(...Array.from(operationMap.values()));
    operationGroups.sort((a, b) => Number(b[0].timestamp) - Number(a[0].timestamp));

    const downloadOperations = (operationsToDownload: Operation[] = []) => {
        try {
            setLoading(true);
            sortElements(operationsToDownload, [
                { field: 'timestamp', asc: false },
                { field: 'grouping_id', asc: false },
                { field: 'update_timestamp', asc: false },
            ]);
            const operationsData = [
                ['Fecha', 'Tipo', 'Moneda', 'Monto', 'Categoría de movimiento', 'Cuenta', 'Descripción', 'Referencia de transacción'],
                ...operationsToDownload?.map(operation => [
                    new Date(parseInt(operation.timestamp ?? '')).toLocaleDateString('es', { timeZone: 'UTC' }),
                    operation.amount?.includes('-') ? OperationTypes.EXPENDITURE.label : OperationTypes.INCOME.label,
                    operation.currency_code ?? '',
                    operation.amount?.replace('-', '') ?? '',
                    operationDetails.find(operationDetail => operationDetail.id === operation.operation_detail_id)?.title ?? '',
                    financialElements.find(financialElement => financialElement.id === operation.financial_element_id)?.title ?? '',
                    operation.description ?? '',
                    operation.grouping_id ?? ''
                ])
            ];
            const availableTypes = [OperationTypes.INCOME, OperationTypes.EXPENDITURE]
                .map(type => ['', type.label]);
            const availableCurrencies = client.currencies?.map(currency => ['', currency.code ?? '']) ?? [];
            const availableFinancialElements = financialElements
                .filter(financialElement => financialElement.status === 'ENABLED')
                .map(financialElement => ['', financialElement.title ?? '']);
            const availableExpenditureOperationDetails = operationDetails
                .filter(operationDetail => operationDetail.type === OperationTypes.EXPENDITURE.key && operationDetail.status === 'ENABLED')
                .map(operationDetail => ['', operationDetail.title ?? '']);
            const availableIncomeOperationDetails = operationDetails
                .filter(operationDetail => operationDetail.type === OperationTypes.INCOME.key && operationDetail.status === 'ENABLED')
                .map(operationDetail => ['', operationDetail.title ?? '']);
            const dataFormats = [
                'Para los campos "Tipo" "Moneda", "Cuenta" y "Categoría de movimiento", usa solo los valores indicados abajo',
                'El valor de "Fecha" debe estar en formato "dd/mm/aaaa"',
                'El valor de "Monto" debe estar en formato "1234.56"',
                'La "Descripción" es opcional, y pueden ser cualquier texto',
                'Si hay un valor en "Descripción", el campo "Categoría de movimiento" es opcional',
                'Usa "Referencia de transacción" para agrupar operaciones individuales (asigna el mismo valor de "Referencia de transacción" a todas las operaciones que quieras agrupar)',
                'La "Referencia de transacción" es opcional',
                'El valor de "Referencia de transacción" pueden ser cualquier número o texto. Se recomienda utilizar números (1, 2, 3, etc.)',
            ].map(phrase => ['', phrase]);
            const clientProfileData = [
                [], [], ['FORMATO DE LOS DATOS'], ...dataFormats, [], [],
                [], [], ['TIPOS DE OPERACIONES'], ...availableTypes, [], [],
                [], [], ['MONEDAS'], ...availableCurrencies, [], [],
                [], [], ['CUENTAS'], ...availableFinancialElements, [], [],
                [], [], ['CATEGORÍAS DE MOVIMIENTO DE EGRESO'], ...availableExpenditureOperationDetails, [], [],
                [], [], ['CATEGORÍAS DE MOVIMIENTO DE INGRESO'], ...availableIncomeOperationDetails, [], [],
            ];
            writeSpreadsheet([
                { data: operationsData, name: 'Operaciones' },
                { data: clientProfileData, name: 'Datos del cliente' }
            ], 'Operaciones.xlsx');
        } catch (error) {
            addFeedback({ message: 'Error desconocido', level: 'error' });
        } finally {
            setLoading(false);
        }
    }

    const disableDownload = filteredOperations.length > 500;

    return (
        <article className="p-0 space-y-0 h-fit">
            <h2 className="p-3">Operaciones actuales</h2>
            <div className="mx-3">
                <div className="w-full flex border-t border-sf-black space-x-3 pt-2">
                    <div className="w-1/2">
                        <p className="pb-1">Desde</p>
                        <input onKeyDown={e => e.preventDefault()} name='timestamp_from' type='date' max={dateTo} value={dateFrom} className='field ring-1 ring-sf-violet-dark' onChange={ifDateIsValidThen(handleDateFromChange)} />
                    </div>
                    <div className="w-1/2">
                        <p className="pb-1">Hasta</p>
                        <input onKeyDown={e => e.preventDefault()} name='timestamp_to' type='date' min={dateFrom} value={dateTo} className='field ring-1 ring-sf-violet-dark' onChange={ifDateIsValidThen(handleDateToChange)} />
                    </div>
                </div>
            </div>
            <div className="p-3 flex flex-col w-full justify-center items-center">
                <button disabled={disableDownload} className="button-primary flex items-center" onClick={() => downloadOperations(filteredOperations)}>
                    <p className="font-bold text-sf-white">Descargar operaciones</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-5" viewBox="0 0 24 24"><path fill="currentColor" d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11l-5 5Zm-6 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                </button>
                {disableDownload &&
                    <p className="text-sf-red-dark font-bold">No se pueden descargar más de 500 operaciones a la vez.</p>
                }
            </div>
            <div onScroll={onScrollHandler} ref={ref} className={`px-4 max-h-96 rounded-b-2xl overflow-auto ${boxShadow}`}>
                <ul className="divide-y divide-sf-black border-t border-sf-black">
                    {operationGroups.length > 0
                        ? operationGroups.map((operationGroup: Operation[], index1: number) =>
                            <div className="py-2 space-y-2" key={index1}>
                                {operationGroup.map((operation: Operation, index2: number) =>
                                    <div key={index2} className='flex justify-between'>
                                        <div className='max-w-[62%]'>
                                            {operation.operation_detail_id === '' ?
                                                <p>{operation.description}</p>
                                                :
                                                <div className='flex space-x-1'>
                                                    <h3 className='whitespace-nowrap'>{operationDetails.find(operationDetail => operationDetail.id === operation.operation_detail_id)?.title}</h3>
                                                    {operation.description && <p className="secondary font-bold">({operation.description})</p>}
                                                </div>
                                            }
                                            <p className='text-xs truncate'>{financialElements.find(financialElement => financialElement.id === operation.financial_element_id)?.title}</p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <div className={`flex self-end ${operation.amount?.includes('-') ? 'expenditure' : 'income'}`}>
                                                <p>{operation.amount?.includes('-') ? '-' : '+'}</p>
                                                <p className="mx-1">{getSign(operation.currency_code)}</p>
                                                <p>{getInteger(operation.amount ?? '')}</p>
                                                <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(operation.amount ?? '')}</p>
                                            </div>
                                            <small className="self-end">{new Date(parseInt(operation.timestamp ?? '')).toLocaleDateString('es', { timeZone: 'UTC' })}</small>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : <EmptyList message="No hay operaciones." />
                    }
                </ul>
            </div>
        </article>
    );
}