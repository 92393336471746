import { useContext, useEffect, useState } from 'react';
import { listBudgets, listExchangeRates, updateBudget } from '../utils/api';
import { Context } from '../utils/context';
import { Budget, defaultItem, Item } from '../model/Budget';
import { Operation } from '../model/Operation';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { ExchangeRate, defaultExchangeRate } from '../model/ExchangeRate';
import { FinancialElement } from '../model/FinancialElement';
import { FinancialElementTypes } from '../consts/FinancialElementTypes';
import { useHorizontalScrollWithShadow } from '../hooks/HorizontalScrollWithShadow';
import { useHorizontalOverscrollingPrevention } from '../hooks/UseHorizontalOverscrollingPrevention';
import { WidthWarning } from '../components/WidthWarning';
import FirstColumn from '../components/YearPlansComponents/FirstColumn';
import { Visibility } from '../consts/Visibility';
import Column from '../components/YearPlansComponents/Column';
import TotalColumn from '../components/YearPlansComponents/TotalColumn';
import { areItemsEqual } from '../utils/budget-utils';
import { addDaysToTimestamp } from '../utils/date-utils';
import OperationsService from '../utils/OperationsService';

export default function YearPlans(): JSX.Element {
    const { client, operationDetails, financialElements } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [budgets, setBudgets] = useState<Budget[]>([]);
    const [exchangeRates, setExchangeRates] = useState<ExchangeRate[]>([]);
    const [visibility, setVisibility] = useState<Visibility>(Visibility.Default);
    const [displayIncome, setDisplayIncome] = useState(true);
    const [displaySpending, setDisplaySpending] = useState(true);
    const [displayGoal, setDisplayGoal] = useState(true);
    const [displayBorrowing, setDisplayBorrowing] = useState(true);
    const [displayDebtPayment, setDisplayDebtPayment] = useState(true);
    const [displayProfit, setDisplayProfit] = useState(true);
    const [displayInvestment, setDisplayInvestment] = useState(true);
    const [operations, setOperations] = useState<Operation[]>([]);
    const navigate = useNavigate();
    const { ref: refToAddHorizontalShadow, boxShadow, onScrollHandler } = useHorizontalScrollWithShadow();
    const refToPreventOverscrolling1 = useHorizontalOverscrollingPrevention();
    const refToPreventOverscrolling2 = useHorizontalOverscrollingPrevention();
    const refToPreventOverscrolling3 = useHorizontalOverscrollingPrevention();

    useEffect(() => {
        async function setUp() {
            try {
                setLoading(true);
                const result = await listBudgets(client.id ?? '');
                const initialBudgetTimestamp = Date.UTC(new Date().getUTCFullYear(), 0, 1).toString();
                const finalBudgetTimestamp = Date.UTC(new Date().getUTCFullYear() + 1, 0, 1).toString();
                const selectedBudgets = result.filter(budget => initialBudgetTimestamp < (budget.timestamp_to ?? '') && (budget.timestamp_to ?? '') <= finalBudgetTimestamp);
                setBudgets(selectedBudgets);
            } finally {
                setLoading(false);
            }
        }
        setUp();
    }, [client.id]);

    useEffect(() => {
        const fetchOperations = () => {
            if (!client.id || budgets.length === 0) return;

            const initialBudget = budgets[0];
            const finalBudget = budgets[budgets.length - 1];

            if (!initialBudget.timestamp_from || !finalBudget.timestamp_to) return;

            const from = addDaysToTimestamp(initialBudget.timestamp_from, -7);
            const to = addDaysToTimestamp(finalBudget.timestamp_to, 7);

            OperationsService.listOperationsByRange(client.id, from, to).then(setOperations);
        };

        fetchOperations();

        return OperationsService.subscribe(fetchOperations);
    }, [client.id, budgets]);

    useEffect(() => {
        if (budgets.length === 0) {
            return;
        }

        const initialBudget = budgets[0];
        const finalBudget = budgets[budgets.length - 1];

        async function getExchangeRates() {
            const usedCurrencies = operations?.filter(operation => {
                if (initialBudget?.timestamp_from === undefined || finalBudget?.timestamp_to === undefined || operation.timestamp === undefined) return 0;

                return operation.timestamp >= initialBudget.timestamp_from && operation.timestamp < finalBudget.timestamp_to;
            }).map(operation => operation.currency_code ?? '');
            usedCurrencies.push(initialBudget.currency ?? '');
            const codes = Array.from(new Set(usedCurrencies));
            if (codes.length === 0) return;
            const exchangeRates = await listExchangeRates(codes, {
                from: initialBudget.timestamp_from,
                to: finalBudget.timestamp_to,
            });
            setExchangeRates(oldExchangeRates => [...oldExchangeRates, ...exchangeRates]);
        }

        getExchangeRates();
    }, [budgets, operations]);

    if (loading) return <Loading />

    if (budgets.length === 0) return (
        <main>
            <article>
                <div className="items-center text-center min-w-full text-sf-gray-dark p-4">No hay ningún plan para mostrar</div>
                <div className='min-w-full flex justify-center'>
                    <button className='button-primary' onClick={() => navigate('/plan/new')}>Crear plan</button>
                </div>
            </article>
        </main>
    );

    const missingExchangeRates: ExchangeRate[] = [];

    function getExchangeRate(code: string, timestamp: string): number {
        let selectedExchangeRate = { ...defaultExchangeRate };
        exchangeRates.filter(exchangeRate => (exchangeRate.code === code) && ((exchangeRate.timestamp ?? '') <= timestamp))
            .forEach(exchangeRate => {
                if ((exchangeRate.timestamp ?? '') > (selectedExchangeRate.timestamp ?? '')) {
                    selectedExchangeRate = exchangeRate;
                }
            });

        let exchangeRate = selectedExchangeRate.rate;
        if (exchangeRate === undefined || exchangeRate === '') {
            exchangeRate = '1';
            if (code !== 'USD') {
                missingExchangeRates.push({ code, timestamp });
            }
        }

        return parseFloat(exchangeRate);
    }

    function addItem(budget: Budget, item: Item): void {
        setBudgets(oldBudgets => {
            const oldBudget = oldBudgets.find(oldBudget => oldBudget.timestamp_to === budget.timestamp_to) ?? {};
            const newItems = [...oldBudget.items ?? [], item];
            const newBudget = { ...oldBudget, items: newItems };
            updateBudget(client.id ?? '', newBudget.timestamp_to ?? '', newBudget);

            return oldBudgets.map(oldBudget => oldBudget.timestamp_to === budget.timestamp_to ? newBudget : oldBudget);
        });
    }

    function removeItem(budget: Budget, item: Item): void {
        setBudgets(oldBudgets => {
            const oldBudget = oldBudgets.find(oldBudget => oldBudget.timestamp_to === budget.timestamp_to) ?? {};
            const newItems = (oldBudget.items ?? []).filter(oldItem => !areItemsEqual(item, { ...defaultItem, ...oldItem }));
            const newBudget = { ...oldBudget, items: newItems };
            updateBudget(client.id ?? '', newBudget.timestamp_to ?? '', newBudget);

            return oldBudgets.map(oldBudget => oldBudget.timestamp_to === budget.timestamp_to ? newBudget : oldBudget);
        });
    }

    function updateItem(budget: Budget, item: Item, newAmount: string) {
        setBudgets(oldBudgets => {
            const oldBudget = oldBudgets.find(oldBudget => oldBudget.timestamp_to === budget.timestamp_to) ?? {};
            const newItems = (oldBudget.items ?? []).map(oldItem => areItemsEqual(item, { ...defaultItem, ...oldItem }) ? { ...defaultItem, ...item, amount: newAmount } : { ...defaultItem, ...oldItem });
            const newBudget = { ...oldBudget, items: newItems };
            updateBudget(client.id ?? '', newBudget.timestamp_to ?? '', newBudget);

            return oldBudgets.map(oldBudget => oldBudget.timestamp_to === budget.timestamp_to ? newBudget : oldBudget);
        });
    }

    const getOperationDetail = (operation?: Operation) => operationDetails.find(operationDetail => operationDetail.id === operation?.operation_detail_id);

    const getFinancialElement = (operation?: Operation) => financialElements.find(financialElement => financialElement.id === operation?.financial_element_id);

    const financialElementIsNonTransactionalAndOfType = (finacialElementType: FinancialElementTypes, financialElement?: FinancialElement) =>
        financialElement?.type === finacialElementType.key &&
        !financialElement?.transactional;

    const initialBudget = budgets[0];
    const finalBudget = budgets[budgets.length - 1];

    const filteredOperations = operations?.filter(operation => {
        if (initialBudget?.timestamp_from === undefined || finalBudget?.timestamp_to === undefined || operation.timestamp === undefined) return 0;

        return operation.timestamp >= initialBudget.timestamp_from && operation.timestamp < finalBudget.timestamp_to && getFinancialElement(operation)?.transactional;
    }).map(operation => {
        const parsedAmount = parseFloat(operation.amount ?? '');
        const budgetExchangeRate = getExchangeRate(initialBudget.currency ?? '', operation.timestamp ?? '');
        const operationExchangeRate = getExchangeRate(operation.currency_code ?? '', operation.timestamp ?? '');

        return {
            ...operation,
            currency: initialBudget.currency,
            amount: (parsedAmount * budgetExchangeRate / operationExchangeRate).toString()
        };
    });

    const movementsOperations = filteredOperations.filter(operation => operation.operation_detail_id !== '');

    const nonMovementsOperations = filteredOperations.filter(operation => operation.operation_detail_id === '')
        .map(operation => ({
            ...operation,
            financial_element_id: operations.find(o =>
                o.grouping_id === operation.grouping_id &&
                o.financial_element_id !== operation.financial_element_id &&
                getFinancialElement(o)?.transactional === false
            )?.financial_element_id
        }));

    const allOperations: Operation[] = [...movementsOperations, ...nonMovementsOperations];

    const getIds = (isMovement: boolean, operationsFilterPredicate: (operation: Operation) => boolean) => {
        const selectedOperations = allOperations.filter(operationsFilterPredicate);
        const allBudgetsItems = budgets.map(budget => budget.items ?? []).flat(1).filter(operationsFilterPredicate);
        const allOperationsAndItems = [...selectedOperations, ...allBudgetsItems];
        const key = isMovement ? 'operation_detail_id' : 'financial_element_id';
        const allOperationsAndItemsIds = allOperationsAndItems.map(item => item[key] ?? '');

        return (isMovement ? operationDetails : financialElements)
            .map(element => element.id ?? '')
            .filter(id => allOperationsAndItemsIds.includes(id));
    };

    const incomeIds = getIds(true, (operation: Operation) =>
        !operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') !== ''
    );

    const spendingIds = getIds(true, (operation: Operation) =>
        !!operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') !== '' &&
        (getOperationDetail(operation)?.goal_id ?? '') === ''
    );

    const goalIds = getIds(true, (operation: Operation) =>
        !!operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') !== '' &&
        (getOperationDetail(operation)?.goal_id ?? '') !== ''
    );

    const borrowingIds = getIds(false, (operation: Operation) =>
        !operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') === '' &&
        (operation.financial_element_id ?? '') !== '' &&
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.DEBT, getFinancialElement(operation))
    );

    const debtPaymentIds = getIds(false, (operation: Operation) =>
        !!operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') === '' &&
        (operation.financial_element_id ?? '') !== '' &&
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.DEBT, getFinancialElement(operation)));

    const profitIds = getIds(false, (operation: Operation) =>
        !operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') === '' &&
        (operation.financial_element_id ?? '') !== '' &&
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.ASSET, getFinancialElement(operation))
    );

    const investmentIds = getIds(false, (operation: Operation) =>
        !!operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') === '' &&
        (operation.financial_element_id ?? '') !== '' &&
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.ASSET, getFinancialElement(operation))
    );

    return (
        <main className='grid-cols-1 min-w-[703px]'>
            <div className='w-full'>
                <h1 className='text-2xl'>Plan anual</h1>
                <WidthWarning minWidth={672} />
            </div>
            <div className="self-center p-1.5 h-fit w-fit rounded-lg flex items-center bg-sf-white">
                <small className='mx-1.5'>Modo:</small>
                <div className='rounded-md flex text-sf-white font-bold ring-1 ring-sf-violet-dark divide-x divide-sf-violet-dark'>
                    <div className={`px-2 py-0.5 rounded-l-md flex items-center w-fit ${visibility === Visibility.Default ? 'bg-sf-violet-dark' : 'bg-sf-violet-light text-sf-violet-dark cursor-pointer'}`} onClick={() => setVisibility(Visibility.Default)}>
                        <small>Por defecto</small>
                    </div>
                    <div className={`px-2 py-0.5 flex items-center w-fit ${visibility === Visibility.AllCurrent ? 'bg-sf-violet-dark' : 'bg-sf-violet-light text-sf-violet-dark cursor-pointer'}`} onClick={() => setVisibility(Visibility.AllCurrent)}>
                        <small>Solo operaciones</small>
                    </div>
                    <div className={`px-2 py-0.5 rounded-r-md flex items-center w-fit ${visibility === Visibility.AllPlan ? 'bg-sf-violet-dark' : 'bg-sf-violet-light text-sf-violet-dark cursor-pointer'}`} onClick={() => setVisibility(Visibility.AllPlan)}>
                        <small>Solo planeado</small>
                    </div>
                </div>
            </div>
            <article className='p-0 w-fit max-w-full'>
                <div className='flex divide-x divide-sf-black'>
                    <div ref={refToPreventOverscrolling1}>
                        <FirstColumn
                            incomeIds={incomeIds}
                            spendingIds={spendingIds}
                            goalIds={goalIds}
                            borrowingIds={borrowingIds}
                            debtPaymentIds={debtPaymentIds}
                            profitIds={profitIds}
                            investmentIds={investmentIds}
                            displayIncome={displayIncome}
                            displaySpending={displaySpending}
                            displayGoal={displayGoal}
                            displayBorrowing={displayBorrowing}
                            displayDebtPayment={displayDebtPayment}
                            displayProfit={displayProfit}
                            displayInvestment={displayInvestment}
                            setDisplayIncome={() => setDisplayIncome(old => !old)}
                            setDisplaySpending={() => setDisplaySpending(old => !old)}
                            setDisplayGoal={() => setDisplayGoal(old => !old)}
                            setDisplayBorrowing={() => setDisplayBorrowing(old => !old)}
                            setDisplayDebtPayment={() => setDisplayDebtPayment(old => !old)}
                            setDisplayProfit={() => setDisplayProfit(old => !old)}
                            setDisplayInvestment={() => setDisplayInvestment(old => !old)}
                        />
                    </div>
                    <div ref={node => { refToPreventOverscrolling2(node); refToAddHorizontalShadow(node); }} onScroll={onScrollHandler} className={`flex overflow-auto ${boxShadow}`}>
                        {budgets.map((budget, index) =>
                            <Column
                                key={index}
                                budget={budget}
                                visibility={visibility}
                                operations={allOperations.filter(operation => (operation.timestamp ?? '') >= (budget.timestamp_from ?? '') && (operation.timestamp ?? '') < (budget.timestamp_to ?? ''))}
                                incomeIds={incomeIds}
                                spendingIds={spendingIds}
                                goalIds={goalIds}
                                borrowingIds={borrowingIds}
                                debtPaymentIds={debtPaymentIds}
                                profitIds={profitIds}
                                investmentIds={investmentIds}
                                displayIncome={displayIncome}
                                displaySpending={displaySpending}
                                displayGoal={displayGoal}
                                displayBorrowing={displayBorrowing}
                                displayDebtPayment={displayDebtPayment}
                                displayProfit={displayProfit}
                                displayInvestment={displayInvestment}
                                addItem={(item: Item) => addItem(budget, item)}
                                removeItem={(item: Item) => removeItem(budget, item)}
                                updateItem={(item: Item, newAmount: string) => updateItem(budget, item, newAmount)}
                            />
                        )}
                    </div>
                    <div ref={refToPreventOverscrolling3} className='flex'>
                        <TotalColumn
                            title='Total operaciones'
                            budgets={budgets}
                            visibility={Visibility.AllCurrent}
                            operations={allOperations}
                            incomeIds={incomeIds}
                            spendingIds={spendingIds}
                            goalIds={goalIds}
                            borrowingIds={borrowingIds}
                            debtPaymentIds={debtPaymentIds}
                            profitIds={profitIds}
                            investmentIds={investmentIds}
                            displayIncome={displayIncome}
                            displaySpending={displaySpending}
                            displayGoal={displayGoal}
                            displayBorrowing={displayBorrowing}
                            displayDebtPayment={displayDebtPayment}
                            displayProfit={displayProfit}
                            displayInvestment={displayInvestment}
                        />
                        <TotalColumn
                            title='Total planeado'
                            budgets={budgets}
                            visibility={Visibility.AllPlan}
                            operations={allOperations}
                            incomeIds={incomeIds}
                            spendingIds={spendingIds}
                            goalIds={goalIds}
                            borrowingIds={borrowingIds}
                            debtPaymentIds={debtPaymentIds}
                            profitIds={profitIds}
                            investmentIds={investmentIds}
                            displayIncome={displayIncome}
                            displaySpending={displaySpending}
                            displayGoal={displayGoal}
                            displayBorrowing={displayBorrowing}
                            displayDebtPayment={displayDebtPayment}
                            displayProfit={displayProfit}
                            displayInvestment={displayInvestment}
                        />
                    </div>
                </div>
            </article>
        </main>
    );
}
