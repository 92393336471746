import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import OperationItem from "../components/OperationItem";
import { Operation } from "../model/Operation";
import { Context } from "../utils/context";
import { useNavigate } from "react-router-dom";
import EmptyList from "../components/EmptyList";
import OperationsFilter from "../components/OperationsFilter";
import { useVerticalScrollWithShadow } from "../hooks/VerticalScrollWithShadow";
import { OperationTypes } from "../consts/OperationTypes";
import { sortElements } from "../utils/sort-elements";
import Menu from "../components/Menu";
import OperationsService from "../utils/OperationsService";

export default function Operations(): JSX.Element {
    const [searchParams] = useSearchParams();
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const { client } = useContext(Context);
    const [operations, setOperations] = useState<Operation[]>([]);
    const navigate = useNavigate();

    const timestampFrom = searchParams.get('timestamp_from');
    const timestampTo = searchParams.get('timestamp_to');

    useEffect(() => {
        const fetchOperations = () => {
            if (!client.id || !timestampFrom || !timestampTo) return;

            OperationsService.listOperationsByRange(client.id, timestampFrom, timestampTo).then(setOperations);
        };

        fetchOperations();

        return OperationsService.subscribe(fetchOperations);
    }, [client.id, timestampFrom, timestampTo]);

    const movementsOperations = operations.filter(operation => (operation?.operation_detail_id ?? '') !== '')
    const operationsToDisplay = movementsOperations
        .filter(operation => searchParams.get('type') === null || (searchParams.get('type') === OperationTypes.EXPENDITURE.key && operation.amount?.includes('-')) || (searchParams.get('type') === OperationTypes.INCOME.key && !operation.amount?.includes('-')))
        .filter(operation => searchParams.get('operation_detail_id') === null || operation.operation_detail_id === searchParams.get('operation_detail_id'))
        .filter(operation => searchParams.get('currency_code') === null || operation.currency_code === searchParams.get('currency_code'))
        .filter(operation => searchParams.get('financial_element_id') === null || operation.financial_element_id === searchParams.get('financial_element_id'))
        .filter(operation => searchParams.get('timestamp_from') === null || (operation.timestamp ?? '') >= (searchParams.get('timestamp_from') ?? ''))
        .filter(operation => searchParams.get('timestamp_to') === null || (operation.timestamp ?? '') < (searchParams.get('timestamp_to') ?? ''));
    sortElements(operationsToDisplay, [
        { field: 'timestamp', asc: false },
        { field: 'update_timestamp', asc: false },
    ]);

    async function goBack(): Promise<void> {
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.delete('from');
        navigate(`/${searchParams.get('from') === 'plan' ? 'plan' : 'budgets'}?${newSearchParams}`);
    }

    return (
        <main>
            {searchParams.get('budget_timestamp') &&
                <button className='button-aux w-fit p-1 col-span-full' onClick={goBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-2.5 w-2.5" viewBox="0 0 24 24"><path fill="currentColor" d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875q-.375.375-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12q0-.375.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388q.375.375.375.875t-.375.875L3.55 12Z" /></svg>
                    <div>Volver</div>
                </button>
            }
            <article className="p-0 space-y-0 h-fit">
                <div className="flex w-full justify-between items-center p-3">
                    <h2>Ingresos y egresos</h2>
                    <Menu header={
                        <div className="p-1 h-fit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5" viewBox="0 0 24 24"><path fill="currentColor" d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0-4 0m0-6a2 2 0 1 0 4 0a2 2 0 0 0-4 0m0 12a2 2 0 1 0 4 0a2 2 0 0 0-4 0" /></svg>
                        </div>
                    }>
                        <div className="p-1 h-fit w-full flex justify-between items-center space-x-6" onClick={() => navigate(`/operations/new?${searchParams}`)}>
                            <p>Nuevo movimiento</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                        </div>
                        <div className="p-1 h-fit w-full flex justify-between items-center space-x-6" onClick={() => navigate(`/operations/files?${searchParams}`)}>
                            <p>Subir o bajar archivos</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3" viewBox="0 0 24 24"><path fill="currentColor" d="M19 19H8q-.825 0-1.413-.588T6 17V3q0-.825.588-1.413T8 1h6.175q.4 0 .763.15t.637.425l4.85 4.85q.275.275.425.638t.15.762V17q0 .825-.588 1.413T19 19Zm0-11h-3.5q-.625 0-1.063-.438T14 6.5V3H8v14h11V8ZM4 23q-.825 0-1.413-.588T2 21V8q0-.425.288-.713T3 7q.425 0 .713.288T4 8v13h10q.425 0 .713.288T15 22q0 .425-.288.713T14 23H4ZM8 3v5v-5v14V3Z" /></svg>
                        </div>
                        <div className="p-1 h-fit w-full flex justify-between items-center space-x-6" onClick={() => navigate(`/transactions?${searchParams}`)}>
                            <p>Transacciones internas</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3" viewBox="0 0 24 24"><path fill="currentColor" d="m14.475 12l-7.35-7.35q-.375-.375-.363-.888t.388-.887q.375-.375.888-.375t.887.375l7.675 7.7q.3.3.45.675t.15.75q0 .375-.15.75t-.45.675l-7.7 7.7q-.375.375-.875.363T7.15 21.1q-.375-.375-.375-.888t.375-.887z" /></svg>
                        </div>
                    </Menu>
                </div>
                <OperationsFilter operations={movementsOperations} />
                <div onScroll={onScrollHandler} ref={ref} className={`px-4 max-h-96 rounded-b-2xl overflow-auto ${boxShadow}`}>
                    <ul className="divide-y divide-sf-black border-t border-sf-black">
                        {operationsToDisplay.length > 0
                            ? operationsToDisplay.map((operation: Operation) => <OperationItem key={operation.id} operation={operation} />)
                            : <EmptyList message="No hay movimientos." />
                        }
                    </ul>
                </div>
            </article>
        </main>
    );
}
