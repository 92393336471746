import { getDecimal, getInteger } from "../../utils/number-utils";

interface CashflowHeaderProps {
    title: string,
    currency: string,
    amount: string
    errorsAndOmissions?: string
}

export default function CashflowHeader(props: CashflowHeaderProps): JSX.Element {
    return (
        <div>
            {props.errorsAndOmissions !== undefined && parseFloat(getInteger(props.errorsAndOmissions)) !== 0 &&
                <div className='flex w-full justify-end'>
                    <div className='flex w-fit'>
                        <small className='mr-1'>Errores y omisiones:</small>
                        <small className="font-mono">{props.errorsAndOmissions.includes('-') ? '-' : '+'}</small>
                        <small className='mx-1'>{props.currency}</small>
                        <small>{getInteger(props.errorsAndOmissions)}</small>
                        <small className="text-[9px]/[12px] ml-[1px]">{getDecimal(props.errorsAndOmissions)}</small>
                    </div>
                </div>
            }
            <div className='flex w-full justify-between font-bold'>
                <p className='max-w-[62%] text-base'>{props.title}</p>
                <div className="flex w-fit">
                    <p className="font-mono text-base">{props.amount.includes('-') ? '-' : '+'}</p>
                    <p className='mx-1 text-base'>{props.currency}</p>
                    <p className="text-base">{getInteger(props.amount)}</p>
                    <p className="text-[12px]/[18px] ml-[1px]">{getDecimal(props.amount)}</p>
                </div>
            </div>
        </div>
    );
}
