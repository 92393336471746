export interface Valuation {
    readonly 'financial_element_id'?: string;
    readonly 'timestamp'?: string;
    readonly 'hour'?: string;
    readonly 'currency_code'?: string;
    readonly 'amount'?: string;
    readonly 'id'?: string;
    readonly 'creation_timestamp'?: string;
    readonly 'update_timestamp'?: string;
};

export const defaultValuation: Valuation = {
    'financial_element_id': '',
    'timestamp': '',
    'hour': '',
    'currency_code': '',
    'amount': '',
    'id': '',
    'update_timestamp': '',
};
