import { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../utils/context";
import { listOperationDetails } from "../utils/api";
import { OperationDetail } from "../model/OperationDetail";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import EmptyList from "./EmptyList";
import { useVerticalScrollWithShadow } from "../hooks/VerticalScrollWithShadow";
import { sortElements } from "../utils/sort-elements";

interface OperationDetailItemProps {
    operationDetail: OperationDetail
}

function OperationDetailItem(props: OperationDetailItemProps): JSX.Element {
    const operationDetail = props.operationDetail;
    const navigate = useNavigate();

    const isGoal = (operationDetail.goal_id ?? '') !== '';

    return (
        <div className="py-2 cursor-pointer hover:bg-sf-gray-extra-light" onClick={() => navigate(isGoal ? `/goals/${operationDetail.goal_id}` : `/operation_details/${operationDetail.id}`)}>
            {operationDetail.status !== 'ENABLED' &&
                <p className="bg-sf-gray-light h-min mb-1.5 rounded-full flex justify-center items-center whitespace-nowrap">
                    Deshabilitada
                </p>
            }
            <div className="flex items-center w-full justify-between">
                <h3 className='text-base'>{operationDetail.title}</h3>
                {isGoal &&
                    <small className="ring-1 ring-inset ring-sf-violet-dark  h-min px-2.5 py-0.5 rounded-full flex justify-center items-center text-sf-violet-dark font-bold whitespace-nowrap">
                        Objetivo
                    </small>
                }
                {!isGoal && operationDetail.type === 'INCOME' &&
                    <small className="ring-1 ring-inset ring-sf-green-dark  h-min px-2.5 py-0.5 rounded-full flex justify-center items-center text-sf-green-dark font-bold whitespace-nowrap">
                        Ingreso
                    </small>
                }
                {!isGoal && operationDetail.type === 'EXPENDITURE' &&
                    <small className="ring-1 ring-inset ring-sf-red-dark  h-min px-2.5 py-0.5 rounded-full flex justify-center items-center text-sf-red-dark font-bold whitespace-nowrap">
                        Egreso
                    </small>
                }
            </div>
            <p className="secondary">{operationDetail.description}</p>
        </div>
    )
}

export default function OperationDetails(): JSX.Element {
    const { client, operationDetails, setOperationDetails } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const navigate = useNavigate();

    const setUp = useCallback(async () => {
        setLoading(true);
        try {
            const result = await listOperationDetails(client.id ?? '');
            sortElements(result, [
                { field: 'status', asc: false },
                { field: 'type', asc: false },
                { field: 'update_timestamp', asc: false },
            ]);
            setOperationDetails(result);
        } finally {
            setLoading(false);
        }
    }, [client.id, setOperationDetails]);

    useEffect(() => { setUp() }, [setUp]);

    if (loading) return <Loading />

    return (
        <article className="p-0 space-y-0 h-fit w-full">
            <div className="flex w-full flex-row justify-between items-center p-3">
                <h2>Categorías de movimientos</h2>
                <button className="button-aux flex space-x-1 items-center" onClick={() => navigate('/operation_details/new')}>
                    <p>Nuevo</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-3.5" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                </button>
            </div>
            <div onScroll={onScrollHandler} ref={ref} className={`px-4 max-h-96 rounded-b-2xl overflow-auto ${boxShadow}`}>
                <ul className="divide-y divide-sf-black border-t border-sf-black">
                    {operationDetails.length > 0 ? operationDetails.map(operationDetail => <OperationDetailItem key={operationDetail.id} operationDetail={operationDetail} />)
                        : <EmptyList message="No hay categorías de movimientos." />
                    }
                </ul>
            </div>
        </article>
    )
}
