import { defaultOperationDetail } from '../model/OperationDetail';
import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../utils/context';
import { useNavigate } from 'react-router-dom';
import { createOperationDetail } from '../utils/api';
import Loading from '../components/Loading';
import { OperationTypes } from '../consts/OperationTypes';

export default function NewOperationDetail(): JSX.Element {
    const { client } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [operationDetail, setOperationDetail] = useState({ ...defaultOperationDetail });
    const navigate = useNavigate();

    const setUp = useCallback(async () => {
        try {
            setLoading(true);
            setOperationDetail({
                ...defaultOperationDetail,
                type: OperationTypes.EXPENDITURE.key,
                status: 'ENABLED',
            });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => { setUp() }, [setUp]);

    function handleInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setOperationDetail(oldOperationDetail => ({
            ...oldOperationDetail,
            [target.name]: target.value
        }));
    }

    function saveChanges() {
        setLoading(true);
        createOperationDetail(client.id ?? '', operationDetail).then(() => {
            navigate('/profile');
        }).finally(() => {
            setLoading(false);
        });
    }

    if (loading) return <Loading />

    return (
        <main>
            <article className='bg-sf-violet-light'>
                <h2>Nueva categoría de movimiento</h2>
                <div className='border-t border-black w-full h-min pb-1'></div>
                <div className='field py-2 justify-evenly'>
                    {[OperationTypes.EXPENDITURE, OperationTypes.INCOME].map(type => (
                        <div key={type.key} className='flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1' onClick={() => setOperationDetail(oldOperationDetail => ({ ...oldOperationDetail, type: type.key }))}>
                            <div className={`rounded-full h-3.5 w-3.5 ${type.key === operationDetail.type ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></div>
                            <div>{type.label}</div>
                        </div>
                    ))}
                </div>
                <input name='title' type='text' placeholder='Nombre *' value={operationDetail.title} onChange={handleInputChange} className='field' />
                <input name='description' type='text' placeholder='Descripción (opcional)' value={operationDetail.description} onChange={handleInputChange} className='field' />
                <div className='self-end flex space-x-2 w-full justify-end'>
                    <button className="button-secondary" onClick={() => { navigate('/profile') }}>
                        Cancelar
                    </button>
                    <button className="button-primary" disabled={!operationDetail.title || !operationDetail.status || !operationDetail.type || !operationDetail.status} onClick={saveChanges}>
                        Guardar
                    </button>
                </div>
            </article>
        </main>
    )
}