import Router from './Router';
import { I18n } from 'aws-amplify/utils';
import { Authenticator, translations } from '@aws-amplify/ui-react';
import { SignOut } from '@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator';
import { AuthUser } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import { ContextProvider } from './utils/context';
import './amplify-authenticator.css'
import { ErrorBoundary } from "react-error-boundary";
import Fallback from './components/Fallback';

I18n.setLanguage('es');
I18n.putVocabularies(translations);
I18n.putVocabularies({
  es: {
    'Password must have at least 8 characters': 'La contraseña debe tener por lo menos 8 caracteres',
    'User does not exist.': 'El usuario ingresado no existe.',
    'Enter your Email': 'Ingresa tu Email',
    'Enter your email': 'Ingresa tu email',
    'Enter your Password': 'Ingresa tu Contraseña',
    'Please confirm your Password': 'Por favor confirma la Contraseña',
    'Reset Password': 'Restablecer la Contraseña',
    'Code *': 'Código de verificación',
    'Invalid verification code provided, please try again.': 'El código de verificación provisto no es válido, por favor intente de nuevo.',
    'Name': 'Nombre completo',
    'Enter your Name': 'Ingresa tu nombre completo',
    'Birthdate': 'Fecha de nacimiento',
    'Username cannot be empty': 'Debes ingresar un Email',
    'Password cannot be empty': 'Debes ingresar una contraseña',
    'Username/client id combination not found.': 'Usuario no encontrado',
    'Please fill out this field.': 'Usuario no encontrado',
  },
});

export interface authenticationProps {
  signOut?: SignOut;
  user?: AuthUser;
}

export default function App(): JSX.Element {
  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <Authenticator loginMechanisms={['email']} variation="modal">{(props: authenticationProps) =>
        <ContextProvider userId={props.user?.userId ?? ''}>
          <Router />
        </ContextProvider>
      }</Authenticator>
    </ErrorBoundary>
  );
}
