import { useRef } from "react";
import { useContainerDimensions } from "../hooks/UseContainerDimensions";

interface ProgressBarVioletProps {
    current: string
    planned: string
}

export default function ProgressBarViolet(props: ProgressBarVioletProps): JSX.Element {
    const progresBarRef = useRef<HTMLDivElement>(null);
    const plannedsRef = useRef<HTMLDivElement>(null);
    const { width: progresBarRefWidth } = useContainerDimensions(progresBarRef);
    const { width: plannedRefWidth } = useContainerDimensions(plannedsRef);

    const absoluteCurrent = Math.abs(parseFloat(props.current));
    const absolutePlanned = Math.abs(parseFloat(props.planned));
    const currentProgress = (0 <= absoluteCurrent) ? (absoluteCurrent < absolutePlanned) ? (absoluteCurrent / absolutePlanned) : 1 : 0;
    const plannedProgress = (0 <= absolutePlanned) ? (absolutePlanned < absoluteCurrent) ? (absolutePlanned / absoluteCurrent) : 1 : 0;
    const proportion = (absolutePlanned ? Math.floor(100 * absoluteCurrent / absolutePlanned).toString() : '-').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

    return (
        <div className="w-full">
            <div className='w-full flex'>
                <div style={{ width: `${progresBarRefWidth * plannedProgress - plannedRefWidth / 2}px` }}></div>
                <div ref={plannedsRef} hidden={absolutePlanned >= absoluteCurrent} className='w-fit translate-y-0 h-0'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 scale-x-[1.4] py-0.5 -z-1" viewBox="0 0 15 15"><path fill="currentColor" fillRule="evenodd" d="M7.5 2a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-1 0v-10a.5.5 0 0 1 .5-.5" clipRule="evenodd" /></svg>
                </div>
            </div>
            <div ref={progresBarRef} className='h-5 w-full rounded-full flex justify-start bg-sf-violet-medium'>
                <div style={{ width: `${progresBarRefWidth * currentProgress}px` }} className={`flex items-center font-bold text-sf-white rounded-full min-w-[20px] bg-sf-violet-dark ${['10', '11', '12'].includes(proportion) ? 'pl-0.5 justify-start' : 'justify-end'}`}>
                    <small className={`z-0 ${currentProgress >= 1 ? 'bg-sf-viole-dark' : ''}`}>{proportion}</small>
                    <small className={`z-0 mr-[0.7px] rounded-r-lg ${currentProgress >= 1 ? 'bg-sf-violet-dark' : ''}`}>%</small>
                </div>
            </div>
        </div>
    );
}