export const ISO_4217_CURRENCY_CODES: string[] = [
  "AED", // United Arab Emirates Dirham
  "ARS", // Argentine Peso
  "AUD", // Australian Dollar
  "BHD", // Bahraini Dinar
  "BDT", // Bangladeshi Taka
  "BGN", // Bulgarian Lev
  "BOB", // Bolivian Boliviano
  "BRL", // Brazilian Real
  "BYN", // Belarusian Ruble
  "CAD", // Canadian Dollar
  "CHF", // Swiss Franc
  "CLP", // Chilean Peso
  "CNY", // Chinese Yuan
  "COP", // Colombian Peso
  "CRC", // Costa Rican Colón
  "CZK", // Czech Koruna
  "DZD", // Algerian Dinar
  "DKK", // Danish Krone
  "EGP", // Egyptian Pound
  "EUR", // Euro
  "FJD", // Fijian Dollar
  "GBP", // British Pound Sterling
  "GEL", // Georgian Lari
  "GHS", // Ghanaian Cedi
  "GNF", // Guinean Franc
  "GTQ", // Guatemalan Quetzal
  "HKD", // Hong Kong Dollar
  "HRK", // Croatian Kuna
  "HUF", // Hungarian Forint
  "IDR", // Indonesian Rupiah
  "ILS", // Israeli New Shekel
  "INR", // Indian Rupee
  "IQD", // Iraqi Dinar
  "IRR", // Iranian Rial
  "JOD", // Jordanian Dinar
  "JPY", // Japanese Yen
  "KES", // Kenyan Shilling
  "KGS", // Kyrgystani Som
  "KRW", // South Korean Won
  "KWD", // Kuwaiti Dinar
  "LBP", // Lebanese Pound
  "LKR", // Sri Lankan Rupee
  "MAD", // Moroccan Dirham
  "MDL", // Moldovan Leu
  "MEX", // Mexican Peso
  "MYR", // Malaysian Ringgit
  "NGN", // Nigerian Naira
  "NIO", // Nicaraguan Córdoba
  "NOK", // Norwegian Krone
  "NZD", // New Zealand Dollar
  "OMR", // Omani Rial
  "PEN", // Peruvian Nuevo Sol
  "PHP", // Philippine Peso
  "PLN", // Polish Złoty
  "QAR", // Qatari Riyal
  "RON", // Romanian Leu
  "RUB", // Russian Ruble
  "SAR", // Saudi Riyal
  "SEK", // Swedish Krona
  "SGD", // Singapore Dollar
  "THB", // Thai Baht
  "TRY", // Turkish Lira
  "TWD", // New Taiwan Dollar
  "UAH", // Ukrainian Hryvnia
  "USD", // United States Dollar
  "UYU", // Uruguayan Peso
  "VES", // Venezuelan Bolívar
  "VND", // Vietnamese Dong
  "XAF", // Central African CFA Franc
  "XAG", // Silver Ounce
  "XAU", // Gold Ounce
  "XPD", // Palladium Ounce
  "XPT", // Platinum Ounce
  "XOF", // West African CFA Franc
  "YER", // Yemeni Rial
  "ZAR", // South African Rand
  "ZMW", // Zambian Kwacha
];
